<template>
    <div class="mainWrapper">



        <MainSideBarVue />



        <!-- {{ props.random_string }} -->


        <div class="rightContentWrapper">


            <div class="topNav">
                <TopBarVue />
            </div>


            <div class="innerContainer">


                <div class="theHeaderWrapper">

                    <div v-if="campaignStore.currentCampaign">

                        {{ campaignStore.limitTextForCampaignTitle() }} | {{ campaignStore.currentTeam.name }}


                        <button @click="campaignStore.openExportCampaignOptionsModal" class="button-13"
                            v-if="campaignStore.scheduledMessages.length > 0">Export as
                            Excel</button>

                        <!-- <button v-if="campaignStore.scheduledMessages.length > 0"
                         style="margin-left: 10px;" @click="campaignStore.exportAllTalksWithEmployeesUnderACampaign(props.random_string)"
                                class="button-13">Export with employess</button> -->

                        <!-- <button v-if="campaignStore.scheduledMessages.length > 0"
                         style="margin-left: 10px;" @click="campaignStore.exportAllTalksOnlyUnderACampaign(props.random_string)"
                                class="button-13">Export talks only</button> -->


                    </div>




                    <router-link class="button-6"
                        :to="{ name: 'CreateAMessage', params: { random_string: props.random_string } }">
                        New Message +
                    </router-link>


                </div>


                <div class="scheduleTalksHeader" v-if="campaignStore.scheduledMessages.length > 0">

                    Scheduled Talks

                </div>




                <div v-if="campaignStore.scheduledMessages.length > 0" class="messageTimelineWrapper">



                    <div class="mainitem" v-for="(scheduledMessage, index) in campaignStore.scheduledMessages"
                        :key="index">

                        <div class="dotWrapper">

                            <div class="timelineDotBox">

                                <i class="bi bi-arrow-right-circle"></i>


                                <i v-for="index in 8" :key="index" class="bi bi-three-dots-vertical dotStyle"></i>

                            </div>

                        </div>


                        <div class="timelineItem">


                            <div class="timelineRightSide">



                                <div
                                    :class="[scheduledMessage.status === 1 ? 'itemDetailsBox' : 'itemDetailsBoxDraft']">


                                    <div class="col1">

                                        <div class="colHeader">

                                            <!-- {{ scheduledMessage.name }}  -->

                                            {{ campaignStore.limitText(scheduledMessage.name) }}

                                        </div>

                                        <div class="colBody">

                                            {{ campaignStore.limitText(scheduledMessage.message) }}

                                        </div>


                                        <div class="createdDateBox">
                                            
                                            Created on {{ campaignStore.formatDate(scheduledMessage.created_at) }}
                                            
                                        </div>


                                    </div>

                                    <div class="colType">


                                        <span v-if="scheduledMessage.post.message_goal_id == '1'"
                                            class="badge rounded-pill bg-dark badgy">Text</span>
                                        <span v-if="scheduledMessage.post.message_goal_id == '2'"
                                            class="badge rounded-pill bg-dark badgy">PDF</span>
                                        <span v-if="scheduledMessage.post.message_goal_id == '3'"
                                            class="badge rounded-pill bg-dark badgy">Video</span>



                                    </div>


                                    <!-- {{ campaignStore.formatDate(scheduledMessage.created_at) }} -->

                                    <div class="col2">

                                        <div class="colHeader">

                                            Send Date


                                        </div>

                                        <div class="colBody">

                                            {{ campaignStore.formatDate(scheduledMessage.send_date) }}


                                        </div>

                                    </div>

                                    <div class="col3">

                                        <div class="colHeader">

                                            Send Time

                                        </div>

                                        <div class="colBody">

                                            {{ campaignStore.formatTime(scheduledMessage.send_time) }}


                                        </div>

                                    </div>

                                    <div class="col4">

                                        <div class="colHeader">

                                            Status

                                        </div>

                                        <div class="colBody">

                                            <div @click="campaignStore.updateStatus(scheduledMessage)">

                                                <div v-if="scheduledMessage.status" class="form-check form-switch">
                                                    <input class="form-check-input" type="checkbox"
                                                        id="flexSwitchCheckDefault" checked>
                                                </div>

                                                <div v-else class="form-check form-switch">
                                                    <input class="form-check-input" type="checkbox"
                                                        id="flexSwitchCheckDefault">
                                                </div>

                                            </div>

                                        </div>

                                    </div>

                                    <div class="col5">

                                        <div class="colHeader">

                                            Signed

                                        </div>

                                        <div class="colBody">


                                            <router-link v-if="scheduledMessage.status" class="button-3"
                                                :to="{ name: 'ViewEmployeesAckStatus', params: { schedule_message_id: scheduledMessage.id } }">
                                                View
                                            </router-link>

                                            <p v-else class="text-muted">
                                                Inactive
                                            </p>

                                        </div>

                                    </div>

                                    <div class="col6">

                                        <div class="colHeader">

                                            Actions

                                        </div>

                                        <div class="actionsColBody">

                                            <i @click="() => { campaignStore.setCurrentMessage(scheduledMessage); campaignStore.openShowEditMessageModal(); }"
                                                class="bi bi-chat-left-text iconStyle"></i>


                                            <router-link
                                                :to="{ name: 'EditPostContent', params: { random_string: scheduledMessage.post.random_string } }">

                                                <i class="bi bi-pencil iconStyle"></i>


                                            </router-link>


                                            <router-link v-if="scheduledMessage.post.quiz"
                                                :to="{ name: 'EditQuiz', params: { random_string: scheduledMessage.post.quiz.random_string } }">

                                                <i class="bi bi-card-checklist iconStyle"></i>

                                            </router-link>



                                            <i @click="() => { campaignStore.setCurrentMessage(scheduledMessage); campaignStore.openDeleteScheduledMessageModal(); }"
                                                class="bi bi-trash iconStyle"></i>



                                        </div>

                                    </div>



                                </div>


                            </div>


                        </div>

                    </div>



                </div>

                <div v-else class="noTaksBox">

                    No safety safety talks scheduled yet.

                </div>











            </div>





        </div>




        <div v-if="campaignStore.showEditMessageModal" class="modal-overlay">

            <div class="modal-box" style="width: 550px">

                <div class="model-box-header">
                    <div style="font-size: 17px">
                        <b>Edit Message</b>
                    </div>

                    <div>
                        <i @click="campaignStore.closeShowEditMessageModal" class="bi bi-x-lg"
                            style="font-size: 20px; cursor: pointer"></i>
                    </div>
                </div>

                <div class="modal-content">


                    <form @submit.prevent="campaignStore.saveMessageUpdate">

                        <div class="mb-3">
                            <label class="form-label theLabel">Name</label>
                            <input v-model="campaignStore.updateMessage.name" type="text" class="form-control" />
                        </div>

                        <div class="mb-3">
                            <label class="form-label theLabel">Message</label>

                            <textarea v-model="campaignStore.updateMessage.message" class="form-control"
                                rows="3"></textarea>
                        </div>

                        <div class="mb-3">
                            <label class="form-label theLabel">Send Date</label>
                            <input v-model="campaignStore.updateMessage.send_date" type="date" class="form-control" />
                        </div>

                        <div class="mb-3">
                            <label class="form-label theLabel">Send Time</label>
                            <input v-model="campaignStore.updateMessage.send_time" type="time" class="form-control" />
                        </div>

                        <button type="submit" class="btn btn-dark" style="width: 100%;">
                            <b>Save</b>
                        </button>

                    </form>


                </div>


            </div>


        </div>


        <div v-if="campaignStore.showDeleteScheduledMessageModal" class="modal-overlay">

            <div class="modal-box" style="width: 500px">

                <div class="model-box-header" style="text-align: left;">
                    <div style="font-size: 17px">
                        <b>Are you sure you want to delete this safety message?</b>
                    </div>

                    <div>
                        <i @click="campaignStore.closeDeleteScheduledMessageModal" class="bi bi-x-lg"
                            style="font-size: 20px; cursor: pointer"></i>
                    </div>
                </div>

                <div class="modal-content" style="text-align: left;">


                    <p> <b style="color: red;">NOTE:</b>
                        Once <b>{{ campaignStore.currentMessage.name }}</b> is deleted the data is unrecoverable. This
                        will
                        also delete any content (text, pdf and video) and quiz related to this talk.


                    </p>

                </div>

                <button @click="campaignStore.yesDeleteScheduledMessage" type="button" class="btn btn-danger"
                    style="width: 100%;">
                    <b>Delete</b>
                </button>


            </div>


        </div>


        <div v-if="campaignStore.showCampaignExportOptionsModal" class="modal-overlay">

            <div class="modal-box" style="width: 500px">

                <div class="model-box-header" style="text-align: left;">
                    <div style="font-size: 17px">
                        <b>Select Export Option</b>
                    </div>

                    <div>
                        <i @click="campaignStore.closeExportCampaignOptionsModal" class="bi bi-x-lg"
                            style="font-size: 20px; cursor: pointer"></i>
                    </div>
                </div>

                <!-- <div class="modal-content" style="text-align: left;">


                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Expedita magni hic odit quaerat incidunt. Vitae, eius, rerum eos autem fugiat cupiditate accusantium inventore aliquid eum, quaerat quidem impedit! Corrupti, quo.

                    <p> <b style="color: red;">NOTE:</b>
                        Once <b>{{ campaignStore.currentMessage.name }}</b> is deleted the data is unrecoverable. This
                        will
                        also delete any content (text, pdf and video) and quiz related to this talk.


                    </p>

                </div> -->


                <div v-if="campaignStore.isExporting">

                    Exporting...

                </div>

                <div v-else>

                    <button type="button" class="btn btn-dark"
                        @click="campaignStore.exportAllTalksOnlyUnderACampaign(props.random_string)"
                        style="width: 100%; margin-bottom: 15px; font-size: 14px;">

                        <b>Export Talks Only</b>

                    </button>

                    <button type="button" class="btn btn-dark"
                        @click="campaignStore.exportAllTalksWithEmployeesUnderACampaign(props.random_string)"
                        style="width: 100%; font-size: 14px;">
                        <b>Export Talks With Employees</b>
                    </button>

                </div>




            </div>


        </div>


    </div>
</template>

<script setup>

import { onMounted, defineProps } from 'vue';
import MainSideBarVue from "@/components/MainSideBar";
import TopBarVue from "@/components/TopBar.vue";
import { useCampaignStore } from "@/stores/CampaignStore";

const campaignStore = useCampaignStore();


const props = defineProps({
    random_string: String
})



onMounted(() => {

    campaignStore.fetchCampaignDetails(props.random_string)

})


</script>

<style scoped>
.mainWrapper {
    /* background-color: aliceblue; */
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}


.rightContentWrapper {

    /* overflow-y: auto; */
    width: 83%;
    display: flex;
    flex-direction: column;




}



.contentContainer {
    /* overflow-y: auto; */
    background-color: rgb(208, 28, 55);
    /* margin: 30px; */
    margin-top: 100px;

}

.rightColumns {
    /* display: flex;
  flex-direction: column; */
}


.topNav {
    background-color: blue;
}


.innerContainer {
    /* margin-top: 200px; */
    /* background-color: blueviolet; */
    overflow-y: auto;
    padding-top: 30px;
    padding-left: 25px;
    padding-right: 25px;
}


.theHeaderWrapper {
    display: flex;
    justify-content: space-between;
    text-align: left;
    font-size: 20px;
    font-weight: bold;
    /* background-color: aqua; */
}


.searchBarWrapper {
    margin-top: 15px;
    width: 100%;
    padding-bottom: 20px;
    /* background-color: rgb(22, 166, 37); */
}

.theBar {
    width: 350px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 10px;
}


.button-6 {
    align-items: center;
    /* background-color: #FFFFFF; */
    background: linear-gradient(90deg, #e3ffe7 0%, #d9e7ff 100%);
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: .25rem;
    box-shadow: rgba(0, 0, 0, 0.02) 0 1px 3px 0;
    box-sizing: border-box;
    color: rgba(0, 0, 0, 0.85);
    cursor: pointer;
    display: inline-flex;
    font-family: system-ui, -apple-system, system-ui, "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 16px;
    font-weight: 600;
    justify-content: center;
    line-height: 1.25;
    margin: 0;
    min-height: 3rem;
    padding: calc(.875rem - 1px) calc(1.5rem - 1px);
    position: relative;
    text-decoration: none;
    transition: all 250ms;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: baseline;
    width: auto;
}

.button-6:hover,
.button-6:focus {
    border-color: rgba(0, 0, 0, 0.15);
    box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
    color: rgba(0, 0, 0, 0.65);
}

.button-6:hover {
    transform: translateY(-1px);
}

.button-6:active {
    background-color: #F0F0F1;
    border-color: rgba(0, 0, 0, 0.15);
    box-shadow: rgba(0, 0, 0, 0.06) 0 2px 4px;
    color: rgba(0, 0, 0, 0.65);
    transform: translateY(0);
}

.tableWrapper {
    margin-top: 20px;
    /* background-color: aqua; */
    width: 100%;
}



.my-custom-table tbody tr td {
    padding-top: 20px;
    /* Adjust the value as needed */
    padding-bottom: 20px;
    /* Adjust the value as needed */
}


.my-custom-table th {
    padding-bottom: 20px;
}

.my-custom-table th,
.my-custom-table td {
    text-align: left;
    /* Aligns text to the left */
    padding-left: 20px;
    padding-right: 20px;
}


.campName {
    text-decoration: none;
    color: inherit;
}

.campName:hover {
    color: rgb(10, 159, 92);
    cursor: pointer;
    text-decoration: none;
}

.iconsRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.theActionIcon {
    font-weight: bolder;
    font-size: 18px;
    color: rgb(5, 2, 2);
    cursor: pointer;
}

.theActionIcon:hover {
    cursor: pointer;
    color: rgb(30, 158, 30);
    scale: 1.3;
    transition: 1s;
}


.messageTimelineWrapper {
    width: 100%;
    /* background-color: rgb(38, 98, 151); */
    margin-top: 50px;
    margin-bottom: 100px;
}



.timelineItem {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: aquamarine;
    width: 100%;
    height: 100%;
}

.timelineDotBox {
    display: flex;
    flex-direction: column;
    width: 5%;
    /* background-color: bisque; */
    /* height: 100px; */
}

.timelineRightSide {
    /* padding: 10px; */
    width: 100%;
    /* background-color: rgb(220, 133, 26); */
}


.itemDetailsBox {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: #eefff5;
    width: 100%;
    /* height: 100%; */
    padding-top: 10px;
    padding-right: 20px;
    padding-left: 10px;
    padding-bottom: 10px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 5px;
}

.itemDetailsBoxDraft {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: #eeeeee;
    width: 100%;
    /* height: 100%; */
    padding-top: 10px;
    padding-right: 20px;
    padding-left: 10px;
    padding-bottom: 10px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 5px;
}

.col1 {
    display: flex;
    flex-direction: column;
    width: 100%;
    /* border: 1px black solid; */
}

.col2,
.col3,
.col4,
.col5 {

    width: 50%;
    /* border: 1px black solid; */

}

.colType {

    display: flex;
    justify-content: center;
    align-items: center;
    width: 40%;
    /* background-color: #94d3a2; */


}

.col6 {

    width: 100%;
    /* border: 1px black solid; */

}


.colHeader {
    font-weight: bold;
    text-align: left;
    padding: 5px;
}

.colBody {
    text-align: left;
    padding: 5px;
    font-size: 14px;
}

.actionsColBody {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    text-align: left;
    padding: 5px;
}


.dotStyle {
    margin-bottom: -9px;
    color: #e5e3e3;

}


.mainitem {
    display: flex;
    flex-direction: row;
    margin-bottom: 25px;
}


.dotWrapper {
    padding-right: 20px;
}


.theCircle {
    color: black;

}

.form-check-input:checked {
    background-color: #198754;
    /* Bootstrap's green color */
    border-color: #198754;
    /* Bootstrap's green color */
}

.form-check-input:focus {
    border-color: #198754;
    /* Bootstrap's green color */
    box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25);
    /* A lighter green shadow */
}



.button-3 {
    appearance: none;
    background-color: #2ea44f;
    border: 1px solid rgba(27, 31, 35, .15);
    border-radius: 6px;
    box-shadow: rgba(27, 31, 35, .1) 0 1px 0;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-family: -apple-system, system-ui, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    padding: 6px 16px;
    position: relative;
    text-align: center;
    text-decoration: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: middle;
    white-space: nowrap;
}

.button-3:focus:not(:focus-visible):not(.focus-visible) {
    box-shadow: none;
    outline: none;
}

.button-3:hover {
    background-color: #2c974b;
}

.button-3:focus {
    box-shadow: rgba(46, 164, 79, .4) 0 0 0 3px;
    outline: none;
}

.button-3:disabled {
    background-color: #94d3a2;
    border-color: rgba(27, 31, 35, .1);
    color: rgba(255, 255, 255, .8);
    cursor: default;
}

.button-3:active {
    background-color: #298e46;
    box-shadow: rgba(20, 70, 32, .2) 0 1px 0 inset;
}


.iconStyle {
    color: black;
    font-weight: bold;
    cursor: pointer;
}

.iconStyle:hover {
    cursor: pointer;
    scale: 1.2;
    transition: 0.75s;
    color: #198754;
}




.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    /* Semi-transparent black background */
    display: flex;
    justify-content: center;
    /* Center horizontally */
    align-items: center;
    /* Center vertically */
    z-index: 999;
    /* Ensure the modal is on top */
}

/* Styles for the modal box */
.modal-box {
    background-color: white;
    width: 1100px;
    padding: 20px;
    /* border-radius: 5px; */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}


.model-box-header {
    display: flex;
    justify-content: space-between;
    /* background-color: antiquewhite; */
    height: 40px;
    margin-bottom: 15px;
}

.modal-content {
    text-align: center;
}


.scheduleTalksHeader {
    text-align: left;
    /* background-color: #94d3a2; */
    width: 100%;
    padding-top: 20px;
    /* font-weight: bold; */
}


.badgy {
    font-size: 11px;
}

.theLabel {
    float: left;
    font-weight: bold;
}


.noTaksBox {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 500px;
    /* background-color: #94d3a2; */
    font-size: 15px;
}


.button-13 {
    background-color: #fff;
    border: 1px solid #d5d9d9;
    border-radius: 8px;
    box-shadow: rgba(213, 217, 217, 0.5) 0 2px 5px 0;
    box-sizing: border-box;
    color: #0f1111;
    cursor: pointer;
    display: inline-block;
    font-family: "Amazon Ember", sans-serif;
    font-size: 11px;
    line-height: 29px;
    padding: 0px;
    position: relative;
    text-align: center;
    text-decoration: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: middle;
    min-width: 100px;
}

.button-13:hover {
    background: linear-gradient(90deg, #e3ffe7 0%, #d9e7ff 100%);
}


.createdDateBox{
    width: 100%;
    font-size: 11px;
    text-align: left;
    padding: 5px;
}

</style>