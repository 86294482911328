<template>
    <div>
        <div class="mainWrapper">
            <MainSideBar />

            <div class="rightContentWrapper">
                <div class="topNav">
                    <TopBarVue />
                </div>

                <div class="innerContainer">

                    <div class="theHeaderWrapper">

                        <div style="display: flex; align-items: center;">
                            <i @click="$router.go(-1)"
                                style="font-size: 25px; font-weight: bold; cursor: pointer; margin-right: 10px;"
                                class="bi bi-arrow-left"></i>

                            {{ templateStore.inspectionGroup.name }}
                        </div>


                        <div class="theRightBox">




                            <button @click="templateStore.getAllUsersAndOpenAddUsersToAGroupModal" class="btn btn-dark"
                                type="button">
                                + Add Users to Group
                            </button>





                        </div>


                    </div>



                    <div v-if="templateStore.inspectionGroupUsers.length > 0" class="theTableWrapper">

                        <div class="table-responsive">

                            <table class="table table-hover">

                                <thead>
                                    <tr>
                                        <th scope="col" class="py-3 text-start">Name</th>
                                        <th scope="col" class="py-3 text-start">Status</th>
                                        <th scope="col" class="py-3 text-start"></th>
                                    </tr>
                                </thead>

                                <tbody>

                                    <tr v-for="(groupUser, index) in templateStore.inspectionGroupUsers" :key="index">

                                        <td class="py-3 text-start">
                                            <div v-if="groupUser.user">

                                                {{ groupUser.user.name }}

                                            </div>

                                        </td>

                                        <td class="py-3 text-start">

                                            <div v-if="groupUser.template_inspection_normal_user">



                                                <span
                                                    v-if="groupUser.template_inspection_normal_user.is_activated === 'yes'"
                                                    class="badge bg-success">Active</span>

                                                <span
                                                    v-if="groupUser.template_inspection_normal_user.is_activated === 'no'"
                                                    class="badge bg-light text-dark">Deactivated</span>

                                            </div>

                                        </td>

                                        <td style="width: 200px;">
                                            

                                            <div class="iconsRow"
                                                v-if="groupUser.template_inspection_normal_user.is_activated === 'yes'">




                                                <i @click="templateStore.setCurrentUserAndOpenRemoveUserGroupModal(groupUser.user)"
                                                    class="bi bi-trash theActionIcon"></i>



                                            </div>


                                            <div class="iconsRow"
                                                v-if="groupUser.template_inspection_normal_user.is_activate === 'no'">




                                                <i class="bi bi-check-lg theActionIcon"></i>



                                            </div>




                                        </td>


                                    </tr>

                                </tbody>

                            </table>
                        </div>


                    </div>

                    <div v-else style="margin-top: 100px;">
                        No users added yet.
                    </div>



                </div>


            </div>
        </div>



        <div v-if="templateStore.showRemoveUserFromGroupModal" class="modal-overlay">

            <div class="modal-box" style="width: 500px">

                <div class="model-box-header">
                    <div style="font-size: 17px">
                        <b>Removing {{ templateStore.currentUser.name }}?</b>
                    </div>

                    <div>
                        <i @click="templateStore.closeRemoveUserFromGroupModal" class="bi bi-x-lg"
                            style="font-size: 20px; cursor: pointer"></i>
                    </div>
                </div>



                <div class="modal-content">


                    <div style="font-size: 16px; text-align: left;">
                        Are you sure you want to remove {{ templateStore.currentUser.name }} from this group?
                    </div>


                    <div class="groupActionBox" style="margin-top: 30px;">


                        <button @click="templateStore.closeRemoveUserFromGroupModal"
                            class="btn btn-light">Cancel</button>

                        <!-- <button v-if="templateStore.isLoading" class="btn btn-dark"><b>Updating</b></button> -->
                        <button @click="templateStore.removeThisUserFromGroup(props.uuid)"
                            class="btn btn-dark"><b>Save</b></button>


                    </div>





                </div>
            </div>
        </div>


        <div v-if="templateStore.showAddUsersToAGroupModal" class="modal-overlay">

            <div class="modal-box" style="width: 700px">

                <div class="model-box-header">
                    <div style="font-size: 17px">
                        <b>Select Users</b>
                    </div>

                    <div>
                        <i @click="templateStore.closeAddUsersToAGroupModal" class="bi bi-x-lg"
                            style="font-size: 20px; cursor: pointer"></i>
                    </div>
                </div>

                <div class="modal-search-box" style="padding: 12px;">

                    <input v-model="searchQuery" type="text" class="form-control titleInput" placeholder="Search..." />

                </div>

                <div class="modal-content">



                    <!-- <b>{{ templateStore.groupIdsToExclude }}</b> -->

                    <!-- <b>{{ templateStore.filteredGroups }}</b> -->

                    <!-- <p style="font-size: 10px;">{{ templateStore.currentUser }}</p> -->

                    <!-- {{ templateStore.usersNotInThisGroup }} -->

                    <!-- {{ templateStore.usersToAGroup }} -->


                    <div class="innerBox">

                        <div v-if="templateStore.usersNotInThisGroup.length > 0"> 

                            <div class="talkItem" v-for="(groupUser, index) in filteredUsers" :key="index"
                            @click="templateStore.addThisUserToAGroup(groupUser)"
                            >



                                <div class="talkLeft">

                                    <div class="talkTitle">
                                        <div v-if="groupUser.user">
                                            {{ groupUser.user.name }}
                                        </div>
                                        
                                    </div>




                                </div>

                                <div class="talkRight">





                                    <div v-if="groupUser.is_checked" class="form-check form-switch">
                                        <input class="form-check-input theInputSwitch" type="checkbox"
                                            id="flexSwitchCheckDefault" checked>
                                    </div>

                                    <div v-else class="form-check form-switch">
                                        <input class="form-check-input theInputSwitch" type="checkbox"
                                            id="flexSwitchCheckDefault">
                                    </div>



                                </div>




                            </div>

                        </div>

                        <div v-else>

                            All users already added to this group.

                        </div>








                    </div>

                    <div class="groupActionBox" style="margin-top: 30px;">


                        <button @click="templateStore.closeAddUsersToAGroupModal" class="btn btn-light">Cancel</button>

                        <button v-if="templateStore.isLoading" class="btn btn-dark"><b>Updating</b></button>
                        <button @click="templateStore.addTheseUsersToAGroup" class="btn btn-dark"><b>Add Users</b></button>


                    </div>





                </div>
            </div>
        </div>




    </div>
</template>

<script setup>
import MainSideBar from "@/components/Inspections/MainSideBar";
import TopBarVue from "@/components/TopBar.vue";
import { onMounted, defineProps, ref, computed } from 'vue';
import { useTemplateStore } from "@/stores/TemplateStore";


const templateStore = useTemplateStore();


const props = defineProps({
    uuid: String
})


const searchQuery = ref('');

const filteredUsers = computed(() => {
  return templateStore.usersNotInThisGroup.filter(groupUser =>
    groupUser?.user && 
    groupUser.user.name.toLowerCase().includes(searchQuery.value.toLowerCase())
  ) || [];
});

onMounted(() => {

    templateStore.fetchAGroup(props.uuid)

})



</script>

<style scoped>
.mainWrapper {
    /* background-color: aliceblue; */
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.rightContentWrapper {
    /* overflow-y: auto; */
    width: 83%;
    display: flex;
    flex-direction: column;
}

.contentContainer {
    /* overflow-y: auto; */
    background-color: rgb(208, 28, 55);
    /* margin: 30px; */
    margin-top: 100px;
}

.rightColumns {
    /* display: flex;
  flex-direction: column; */
}

.topNav {
    background-color: blue;
}

.innerContainer {
    /* margin-top: 200px; */
    /* background-color: blueviolet; */
    overflow-y: auto;
    padding-top: 30px;
    padding-left: 25px;
    padding-right: 25px;
    padding-bottom: 100px;
}

.theHeaderWrapper {
    display: flex;
    justify-content: space-between;
    text-align: left;
    font-size: 20px;
    font-weight: bold;
    /* background-color: aqua; */
}

.searchBarWrapper {
    margin-top: 15px;
    width: 100%;
    padding-bottom: 20px;
    /* background-color: rgb(22, 166, 37); */
}

.theBar {
    width: 350px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 10px;
}

.button-6 {
    align-items: center;
    /* background-color: #FFFFFF; */
    background: linear-gradient(90deg, #e3ffe7 0%, #d9e7ff 100%);
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 0.25rem;
    box-shadow: rgba(0, 0, 0, 0.02) 0 1px 3px 0;
    box-sizing: border-box;
    color: rgba(0, 0, 0, 0.85);
    cursor: pointer;
    display: inline-flex;
    font-family: system-ui, -apple-system, system-ui, "Helvetica Neue", Helvetica,
        Arial, sans-serif;
    font-size: 16px;
    font-weight: 600;
    justify-content: center;
    line-height: 1.25;
    margin: 0;
    min-height: 3rem;
    padding: calc(0.875rem - 1px) calc(1.5rem - 1px);
    position: relative;
    text-decoration: none;
    transition: all 250ms;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: baseline;
    width: auto;
}

.button-6:hover,
.button-6:focus {
    border-color: rgba(0, 0, 0, 0.15);
    box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
    color: rgba(0, 0, 0, 0.65);
}

.button-6:hover {
    transform: translateY(-1px);
}

.button-6:active {
    background-color: #f0f0f1;
    border-color: rgba(0, 0, 0, 0.15);
    box-shadow: rgba(0, 0, 0, 0.06) 0 2px 4px;
    color: rgba(0, 0, 0, 0.65);
    transform: translateY(0);
}

.tableWrapper {
    margin-top: 20px;
    /* background-color: aqua; */
    width: 100%;
}

.my-custom-table tbody tr td {
    padding-top: 20px;
    /* Adjust the value as needed */
    padding-bottom: 20px;
    /* Adjust the value as needed */
}

.my-custom-table th {
    padding-bottom: 20px;
}

.my-custom-table th,
.my-custom-table td {
    text-align: left;
    /* Aligns text to the left */
    padding-left: 20px;
    padding-right: 20px;
}

.campName {
    text-decoration: none;
    color: inherit;
}

.campName:hover {
    color: rgb(10, 159, 92);
    cursor: pointer;
    text-decoration: none;
}

.iconsRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.theActionIcon {
    font-weight: bolder;
    font-size: 18px;
    color: rgb(5, 2, 2);
    cursor: pointer;
}

.theActionIcon:hover {
    cursor: pointer;
    color: rgb(30, 158, 30);
    scale: 1.3;
    transition: 1s;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    /* Semi-transparent black background */
    display: flex;
    justify-content: center;
    /* Center horizontally */
    align-items: center;
    /* Center vertically */
    z-index: 999;
    /* Ensure the modal is on top */
}

/* Styles for the modal box */
.modal-box {
    background-color: white;
    width: 1100px;
    padding: 20px;
    border-radius: 2px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.modal-content {
    text-align: center;
}

.model-box-header {
    display: flex;
    justify-content: space-between;
    /* background-color: antiquewhite; */
    height: 40px;
    margin-bottom: 15px;
}

.form-control {
    background-color: rgb(243, 246, 246);
}

.createBTN {
    width: 100%;
    margin-top: 20px;
}

.noTeamBox {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 500px;
    width: 100%;
}

.noMatchBox {
    background-color: red;
    width: 100%;
}

.loadMoreBtn {
    background-color: black;
    border-radius: 10px;
}

.inviteInput {
    width: 500px;
    font-size: 13px;
    cursor: pointer;
}

.tabsWrapper {
    display: flex;
    width: 100%;
    /* background-color: #a9baba; */
}


.tabItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    background-color: #fdfdfd;
    padding: 30px;
    border: 1.5px solid #c3d4cb;
    cursor: pointer;
    margin: 10px;
    border-radius: 10px;
}

.tabItem:hover {

    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    background-color: #fdfdfd;
    padding: 30px;
    border: 1.5px solid #c3d4cb;
    cursor: pointer;
    margin: 10px;
    border-radius: 10px;
    background: linear-gradient(90deg, #e3ffe7 0%, #d9e7ff 100%);

}

.tabItemSelected {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    background: linear-gradient(90deg, #e3ffe7 0%, #d9e7ff 100%);
    padding: 30px;
    border: 1.5px solid #c3d4cb;
    cursor: pointer;
    margin: 10px;
    border-radius: 10px;
}



.iconDrop {
    cursor: pointer;
}

.button-13 {
    background-color: #fff;
    border: 1px solid #d5d9d9;
    border-radius: 8px;
    box-shadow: rgba(213, 217, 217, 0.5) 0 2px 5px 0;
    box-sizing: border-box;
    color: #0f1111;
    cursor: pointer;
    display: inline-block;
    font-family: "Amazon Ember", sans-serif;
    font-size: 13px;
    line-height: 29px;
    padding: 0 10px 0 11px;
    position: relative;
    text-align: center;
    text-decoration: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: middle;
    min-width: 100px;
}

.button-13:hover {
    background: linear-gradient(90deg, #e3ffe7 0%, #d9e7ff 100%);
}

.emptyTableBox {
    margin-top: 100px;
}


.groupBtn {
    border: none;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    border-radius: 15px;
    font-size: 15px;
    margin-right: 25px;
    padding: 5px;
    padding-left: 20px;
    padding-right: 20px;
}

.groupBtn:hover {
    background: linear-gradient(90deg, #e3ffe7 0%, #d9e7ff 100%);
}

.theRightBox {
    display: flex;
    /* background-color: gray; */
}

.dropdown-item:hover {
    background: linear-gradient(90deg, #e3ffe7 0%, #d9e7ff 100%);
    color: black;
}


.theSearchBox {
    width: 100%;
    /* background-color: rgb(255, 136, 0); */
    padding-bottom: 20px
}

.theTableBox {
    width: 100%;
    overflow-y: auto;
    height: 500px;

}



.addUsersBox {
    /* background-color: green; */
    width: 100%;
    padding-top: 20px;

}


.theTableWrapper {
    width: 100%;
    /* background-color: yellow; */
    margin-top: 40px;
}

.selectSupBox {
    display: flex;
    width: 100%;
    border-radius: 7px;
    padding: 10px;
    max-height: 100px;
    overflow-y: auto;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    cursor: pointer;
    background-color: #f2f2f2;
}

.theSupPill {
    margin: 7px
}



.innerBox {
    /* background-color: red; */
    overflow-y: auto;
    width: 100%;
    height: 500px;
    padding: 12px;
}


.talkItem {
    display: flex;
    flex-direction: row;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 10px;
    margin-bottom: 25px;
    padding-top: 10px;
    padding-bottom: 10px;
    cursor: pointer;
}

.talkItem:hover {
    background-color: rgb(249, 253, 252);
}

.talkLeft {
    display: flex;
    flex-direction: column;
    padding: 5px;
    width: 80%;
    /* background-color: #aadada; */
}

.talkRight {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20%;
    /* padding: 10px; */
    /* background-color: #3ddbdb; */
}

.talkTitle {
    /* background-color: #d9e7ff; */
    text-align: left;
    font-weight: bold;
    padding: 6px;
}


.groupActionBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    /* background-color: gray; */
}
</style>