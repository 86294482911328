/* eslint-disable */
import TemplateService from "@/services/TemplateService";
import moment from "moment";
import { defineStore } from "pinia";
import { computed } from "vue";
import { cond, debounce } from "lodash";
import { toast } from "vue3-toastify";



export const useTemplateStore = defineStore("TemplateStore", {
  state: () => ({
    templates: [],

    showNewTemplateModal: false,

    templateCreationOptions: [
      {
        id: 1,
        name: "Create from scratch",
        desc: "Build your own template from scratch",
        type: "from_scratch",
        icon_name: "bi bi-file-earmark-text",
      },
      {
        id: 2,
        name: "Create with AI",
        desc: "Let AI build a template based on your instructions.",
        type: "create_with_ai",
        icon_name: "bi bi-magic",
      },
      {
        id: 3,
        name: "Use a Public Template",
        desc: "Pick a template from our library.",
        type: "use_template",
        icon_name: "bi bi-file-earmark-check",
      },
    ],

    editorTabOptions: [
      { id: 1, name: "Builder", type: "builder" },
      { id: 2, name: "Report", type: "report" },
      { id: 3, name: "Access", type: "access" },
    ],

    currentEditorTab: "builder",

    currentTab: "from_scratch",

    template: {},

    showSectionElement: true,
    showPageElement: false,

    responseTypes: [
      { id: 1, name: "Text Answer", type: "text_answer", img_name: "text" },
      {
        id: 2,
        name: "Multiple Choice",
        type: "multiple_choice",
        img_name: "test",
      },
      { id: 3, name: "Checkbox", type: "checkbox", img_name: "checkmark" },
      { id: 4, name: "Number", type: "number", img_name: "number" },
      { id: 5, name: "Date & Time", type: "datetime", img_name: "calendar" },
      { id: 6, name: "Date Only", type: "date", img_name: "calendar" },
      { id: 7, name: "Time Only", type: "time", img_name: "clock" },
      { id: 8, name: "Media", type: "media", img_name: "camera" },
      // { id: 6, name: 'Photo', type: 'photo', img_name: 'camera' },
      // { id: 7, name: 'Video', type: 'video', img_name: 'play' },
      { id: 9, name: "Slider", type: "slider", img_name: "slider" },
      { id: 10, name: "Geo Stamp", type: "geostamp", img_name: "map" },
      {
        id: 11,
        name: "Instruction",
        type: "instruction",
        img_name: "guidebook",
      },
      // { id: 12, name: 'Pass/Fail', type: 'pass_fail', img_name: 'pass_fail' },
      { id: 13, name: "Dropdown", type: "dropdown", img_name: "drop1" },
      { id: 14, name: "Signature", type: "signature", img_name: "sign2" },
    ],

    templatePages: [],

    currentPage: {},
    currentPageIndex: null,

    currentElement: {},
    currentElementIndex: null,

    currentSectionElement: {},
    currentSectionElementIndex: null,

    showReorderPagesModal: false,

    showPageBox: false,

    currentMobilePreviewPageIndex: 0,
    currentMobilePage: {},

    isLoading: false,

    showImageAndVideoGalleryModal: false,
    currentFileIndexInGallery: null,

    currentElementImageVideFilesArray: [],

    currentFile: {},
    galleryFiles: [],

    showRangeSelectorModal: false,

    colorArray: [
      { id: 1, hex_code: "#228626" },
      { id: 2, hex_code: "#e9a041" },
      { id: 3, hex_code: "#ff8f00" },
      { id: 4, hex_code: "#8d6e63" },
      { id: 5, hex_code: "#dc112f" },
      { id: 6, hex_code: "#29b6f6" },
      { id: 7, hex_code: "#5c6bc0" },
      { id: 8, hex_code: "#7e57c2" },
      { id: 9, hex_code: "#2f3fe4" },
      { id: 10, hex_code: "#ff73a6" },
      { id: 11, hex_code: "#9a9a9a" },
      { id: 12, hex_code: "#d6ca23" },
    ],

    showSavedOptionsModal: false,
    showOptionEditMode: false,
    allOptions: [],

    currentOption: {},

    showSignatureModal: false,
    currentSignatureType: "text_sign",

    signOptions: [
      { id: 1, name: "Text Signature", type: "text_sign" },
      { id: 2, name: "Digital Signature", type: "digital_sign" },
    ],

    currentGeoPosition: {
      latitude: null,
      longitude: null,
    },

    showAutomationsModal: false,
    showEditAutomationModal: false,

    logicConditions: [
      { id: 1, name: "is equal to", type: "is_equal_to" },
      { id: 2, name: "is not equal to", type: "is_not_equal_to" },
    ],

    checkboxLogicConditions: [
      { id: 1, name: "is equal to", type: "is_equal_to" },
    ],

    singleChoiceLogicConditions: [
      { id: 1, name: "is selected", type: "is_selected" },
      { id: 2, name: "is not selected", type: "is_not_selected" },
    ],

    multipleChoiceLogicConditions: [
      { id: 3, name: "is one of selected", type: "is_one_of_selected" },
      { id: 4, name: "is not one of selected", type: "is_not_one_of_selected" },
    ],

    numberLogicConditions: [
      { id: 1, name: "is equal to", type: "is_equal_to" },
      { id: 2, name: "is not equal to", type: "is_not_equal_to" },
      { id: 3, name: "is less than", type: "is_less_than" },
      { id: 4, name: "is greater than", type: "is_greater_than" },
    ],

    triggerOptions: [
      {
        id: 1,
        name: "Require Action",
        type: "require_action",
        icon_name: "bi bi-check2-square",
      },
      {
        id: 2,
        name: "Require Media",
        type: "require_media",
        icon_name: "bi bi-image",
      },
      { id: 3, name: "Notify", type: "notify", icon_name: "bi bi-bell" },
      {
        id: 4,
        name: "Ask Follow Up Question",
        type: "ask_follow_up_question",
        icon_name: "bi bi-chat-left-dots",
      },
    ],

    checkboxOptions: [
      { id: 1, name: "Checked", type: "checked" },
      { id: 2, name: "Not Checked", type: "not_checked" },
    ],

    showTriggerBox: false,

    newAutomation: {
      trigger_type: null,
      string_value: null,
      number_value: null,
      selected_choice_id: null,
      template_dropdown_option_id: null,
      logic_condition: "is_equal_to",
    },

    editAutomationObject: {},

    showColorBoxModal: false,
    currentChoice: null,

    newSite: {
      id: null,
      name: null,
      desc: null,
    },

    showNewSiteModal: false,
    showEditSiteModal: false,

    sites: [],
    assets: [],

    showNewAssetModal: false,

    newAsset: {
      name: null,
      desc: null,
      site_id: null,
      model: null,
      serial_number: null,
      purchased_date: null,
      files: [],
    },

    isAssetLoading: false,

    asset: {},
    imgsArray: [],
    videosArray: [],
    pdfsArray: [],

    showImagePreviewModal: false,
    currentAssetMediaFile: {},

    showEditAssetForm: false,

    showAddNewLabelForm: false,
    showAddEditLabelForm: false,

    labels: [],

    theLabel: {
      name: null,
    },

    showNewActionModal: false,

    priorityLevels: [
      { id: 1, name: "Low Priority", type: "low_priority" },
      { id: 2, name: "Medium Priority", type: "medium_priority" },
      { id: 3, name: "High Priority", type: "high_priority" },
    ],

    newAction: {
      unique_template_id: null,
      title: null,
      desc: null,
      priority: null,
      due_date: null,
      label_id: null,
      site_id: null,
    },

    actions: [],

    showInspectionAddUsersModal: false,

    allEmployees: [],
    employeesUsingInspectionApp: [],
    team_size_limit: null,
    used_seats_amt: null,
    seats_available_amt: null,

    usersToAddToInspectionAppArray: [],

    newInspectionUsersArray: [],

    inspectionUserLimit: null,

    currentAddNewUserStep: "add_user_details",

    showNewGroupModal: false,
    showEditGroupModal: false,

    newGroupFormData: {
      name: "",
      desc: "",
    },

    allGroups: [],
    currentGroup: {},

    inspectionAppUserRoles: [
      // {
      //   id: 1,
      //   name: "Sub Adminstrator",
      //   role_type: "sub_admin",
      //   desc: "This provides full ystem management capabilities, including full system access to template configuration, complete reporting and analytics visibility, and overall company settings administration.",
      // },
      {
        id: 2,
        name: "Inspector",
        role_type: "inspector",
        desc: "Inspectors can conduct safety inspections, submit their own reports, review their personal inspection history, and enhance documentation by adding photos and notes to their inspections.",
      },
    ],

    showEditNewUserToGroupModal: false,
    showEditNewUserSiteModal: false,

    currentNewUser: {},

    isAddingNewUsers: false,

    showAddUserToGroupModal: false,
    showAddUserToSiteModal: false,
    showDeactivateUserModal: false,
    showReActivateUserModal: false,

    
    currentUser: {},

    filteredGroups: [],
    groupIdsToExclude: [],
    groupsToAddUserTo: [],

    filteredSites: [],
    siteIdsToExclude: [],
    sitesToAddUserTo: [],


    inspectionGroup: {},
    inspectionGroupUsers: [],

    showRemoveUserFromGroupModal: false,

    showAddUsersToAGroupModal: false,
    usersNotInThisGroup: [],
    usersToAGroup: [],


    siteTabOptions: [
      
      { id: 1, name: "Members", type: "members" },
      { id: 2, name: "Inspections", type: "inspections" },
      { id: 3, name: "Actions", type: "actions" },
      { id: 4, name: "Issues", type: "issues" },
      { id: 5, name: "Assets", type: "assets" },
      { id: 6, name: "Details", type: "details" },

    ],

    currentSiteTabType: 'members',

    currentSite: {},
    template_site_users: [],

    showAddUsersToSiteModalInSiteDetails: false,
    showRemoveUserInSiteDetails: false,
    
    usersNotInThisSite: [],
    usersToAddToASite: [],

    userDetailsTabOptions: [
      { id: 1, name: "Groups", type: "groups" },
      { id: 2, name: "Sites", type: "sites" },
    ],

    userDetailCurrentTabType: 'groups',

    the_user: {},
    the_template_inspection_normal_user: {},
    the_sites: [],
    the_groups: [],
    

    recurringOptions: [

      { id: 1, name: 'Does not repeat', type: 'does_not_repeat', is_checked: 1},
      { id: 2, name: 'Daily', type: 'daily', is_checked: 0},
      { id: 3, name: 'Weekly on', type: 'weekly_on_day_name', is_checked: 0},
      { id: 4, name: 'Monthly on the first', type: 'monthly_on_first_day_name', is_checked: 0},
      { id: 5, name: 'Annually on', type: 'annually_this_date', is_checked: 0},
      { id: 6, name: 'Every weekday (Monday - Friday)', type: 'every_weekday_mon_to_fri', is_checked: 0},
      { id: 7, name: 'Custom', type: 'custom', is_checked: 0},

    ],

    selectedRepeatOption: {
      type: 'does_not_repeat',
      readable_format: '',
      day_name: '',
      the_date: '',
 
    },

    newActionFormData: {
      title: '',
      desc: '',
      priority_type: 'low_priority',
      due_date: '',
      due_time: '',
      assignees: {
        users: [],
        groups: [],
      },
      repeat_type: 'does_not_repeat',
      repeat_type_data: null,
      site_id: null,
      asset_id: null,
      labels: [],
      other_data: null,
    },


    showSelectGroupsModal: false,
    groupsAssignedAction: [],

    showSelectUsersModal: false,
    usersAssignedAction: [],

    showSelectLabelsModal: false,
    labelsAssignedToAction: [],

    showSelectRepeatOptionsModal: false,

    showCustomRepeatModal: false,

    customRepeatOptions: [

      { id: 1, name: 'Day', type: 'day'},
      { id: 2, name: 'Week', type: 'week'},
      { id: 3, name: 'Month', type: 'month'},
      { id: 4, name: 'Year', type: 'year'},

    ],

    customRepeatFormData: {
      repeat_amt: 2,
      repeat_frequency: 'day',
      custom_option_selected_mode: 'day_interval_with_never_end',
      never_ends: 'no',
      ends_date: '',

      
    
      week_days: [
        {id: 1, short_name: 'Mon', full_name: 'Monday', type: 'monday', is_checked: 0},
        {id: 2, short_name: 'Tue', full_name: 'Tuesday', type: 'tuesday', is_checked: 0},
        {id: 3, short_name: 'Wed', full_name: 'Wednesday', type: 'wednesday', is_checked: 0},
        {id: 4, short_name: 'Thur', full_name: 'Thursday', type: 'thursday', is_checked: 0},
        {id: 5, short_name: 'Fri', full_name: 'Friday', type: 'friday', is_checked: 0},
        {id: 6, short_name: 'Sat', full_name: 'Saturday', type: 'saturday', is_checked: 0},
        {id: 7, short_name: 'Sun', full_name: 'Sunday', type: 'sunday', is_checked: 0},
      ],

      monthly_occurrence_options: [
        {id: 1, name: 'First', type: 'first'},
        {id: 2, name: 'Second', type: 'second'},
        {id: 3, name: 'Third', type: 'third'},
        {id: 4, name: 'Fourth', type: 'fourth'},
        {id: 5, name: 'Last', type: 'last'},
      ],


      selected_days_of_week: [],

      selected_day_type_in_month: 'monday',
      selected_occurence_in_month: 'first',

      selected_day_number: 1,
      selected_month_option: 'day_number_in_month_option', //month options: 'day_number_in_month_option' or 'day_occurence_in_month_option'

      readable_format: ''

    },


    showEditDetailsModal: false,

    actionTabOptions: [

      { id: 1, name: 'Details', type: 'details'},
      { id: 2, name: 'Activity', type: 'activity'},
      
    ],


    selectedCurrentActionTabType: 'details',


    showActionUploadMediaModal: false,

    currentAction: null,


   
    
  

    
  }),

  getters: {
    getImageAndVideoFilesOnly: () => {
      return (element) => {
        if (!element.media_files || element.media_files.length === 0) {
          return []; // Return an empty array if media_files does not exist or is empty
        }
        return element.media_files.filter(
          (file) => file.type === "image" || file.type === "video"
        );
      };
    },

    getPdfFiles: (state) => {
      return (element) => {
        // Check if media_files exist and is not empty before filtering
        if (!element.media_files || element.media_files.length === 0) {
          return []; // Return an empty array if media_files does not exist or is empty
        }
        return element.media_files.filter((file) => file.type === "pdf");
      };
    },
  },

  actions: {
    fetchAllTemplates() {
      TemplateService.getAllTemplates()
        .then((response) => {
          this.templates = response.data.templates;
          console.log(response.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    fetchaATemplatePagesForEditor(random_string) {
      TemplateService.getTemplateForEditor(random_string)
        .then((response) => {
          this.template = response.data.template;
          this.templatePages = response.data.templatePages;

          //set first page as current page
          this.currentPage = this.templatePages[0];
          this.currentPageIndex = 0;

          //set mobile preview page too
          this.setCurrentMobilePreviewPageIndex(0);

          console.log(response.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    newTemplateRouter(type) {
      if (type === "from_scratch") {
        TemplateService.createNewTemplateFromScratch()
          .then((response) => {
            this.template = response.data.template;

            if (this.template) {
              this.router.push({
                name: "TemplateEditor",
                params: { random_string: this.template.random_string },
              });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }

      if (type === "create_with_ai") {
        alert("create_with_ai");
      }

      if (type === "use_template") {
        alert("use_template");
      }
    },

    openNewTemplateModal() {
      this.showNewTemplateModal = true;
    },

    closeNewTemplateModal() {
      this.showNewTemplateModal = false;
    },

    formatDate(date) {
      return moment(date).format("MMMM Do YYYY");
    },

    formatTime(time) {
      var formatedTime = moment(time, "HH:mm").format("h:mma");

      return formatedTime;
    },

    funckingTest() {
      alert("skdjbfjknsdf");
    },

    togglePage(page) {
      if (page.show_page === 1) {
        page.show_page = 0;
      } else {
        page.show_page = 1;
      }
    },

    toggleSection(section) {
      if (section.show_section === 1) {
        section.show_section = 0;
      } else {
        section.show_section = 1;
      }
    },

    toggleEditPageTitle(page) {
      if (page.is_editing_title === 1) {
        page.is_editing_title = 0;
      } else {
        page.is_editing_title = 1;
      }
    },

    toggleEditSectionName(section) {
      if (section.is_editing_name === 1) {
        section.is_editing_name = 0;
      } else {
        section.is_editing_name = 1;
      }
    },

    addANewQuestion(pageId) {
      const formData = {
        template_page_id: pageId,
      };

      console.log(formData);

      TemplateService.addNewQuestion(formData)
        .then((response) => {
          let newElement = response.data.newElement;

          const pageIndex = this.templatePages.findIndex(
            (page) => page.id === formData.template_page_id
          );

          this.templatePages[pageIndex].elements.push(newElement);

          console.log(response.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    addANewSection(page) {
      const formData = {
        template_page_id: page.id,
      };

      // console.log(page)

      TemplateService.addNewSection(formData)
        .then((response) => {
          let newSectionElement = response.data.newSectionElement;

          page.elements.push(newSectionElement);

          console.log(response.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    setCurrentPage(page) {
      this.currentPage = page;
    },

    setCurrentElement(element, elementIndex) {
      this.currentElement = {}; //reset

      this.currentElement = element;
      this.currentElementIndex = elementIndex;

      this.galleryFiles = this.getImageAndVideoFilesOnly(this.currentElement);
    },

    addQuestionToASection(element) {
      const formData = {
        template_page_layout_id: element.id,
        template_section_id: element.template_section_id,
      };

      // // console.log(formData)
      // console.log(formData)
      // console.log(pageIndex)

      TemplateService.addNewQuestionToASection(formData)
        .then((response) => {
          let newPageSectionElement = response.data.newPageSectionElement;

          element.section_layouts.push(newPageSectionElement);

          // this.insertNewSectionQuestionInLastPosition(pageIndex, formData.template_section_id, newSectionQuestionElement)

          // const pageIndex = this.templatePages.findIndex(
          //   (page) => page.id === this.templatePages[pageIndex].id
          // );

          // this.templatePages[pageIndex].elements.push(newQuestionElement)

          // let newQuestion = response.data.newQuestion

          // element.questions.push(newQuestion)

          // this.templatePages[pageIndex].elements.forEach(element => {

          //   if(element.id === formData.template_section_id){

          //     // this.templatePages[pageIndex].elements.push(newElement)

          //   }

          // });

          // const pageIndex = this.templatePages.findIndex(
          //   (page) => page.id === formData.template_page_id
          // );

          // this.templatePages[pageIndex].elements.push(newElement)

          console.log(response.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    insertNewSectionQuestionInLastPosition(pageIndex, sectionId, newObject) {
      let currentPage = this.templatePages[pageIndex];

      const filteredSectionQuestions = computed(() => {
        return currentPage.elements.filter(
          (ele) => ele.section_id === sectionId
        );
      });

      const lastSectionQuestiontIndex = filteredSectionQuestions.length - 1;

      if (lastSectionQuestiontIndex !== -1) {
        // Insert newObject after the target object
        // The first parameter of splice is the index at which to start changing the array.
        // Since we want to insert after the target, we add 1 to the targetIndex.
        // The second parameter specifies the number of elements to remove, which is 0 in this case.
        // The third parameter is the new item to be added to the array.
        this.templatePages[pageIndex].elements.splice(
          lastSectionQuestiontIndex + 1,
          0,
          newObject
        );
      }
    },

    deleteThisQuestion(templatePageLayoutId, pageIndex) {
      const formData = {
        template_page_layout_id: templatePageLayoutId,
      };

      TemplateService.deleteAQuestion(formData)
        .then((response) => {
          const pageElementToDelete = this.templatePages[
            pageIndex
          ].elements.findIndex((ele) => ele.id === templatePageLayoutId);

          if (pageElementToDelete !== -1) {
            this.templatePages[pageIndex].elements.splice(
              pageElementToDelete,
              1
            );
          }

          console.log(response.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    deleteASectionQuestion(element, sectionElement) {
      const formData = {
        template_page_layout_id: element.id,
        template_page_section_layout_id: sectionElement.id,
      };

      TemplateService.deleteAQuestionFromASection(formData)
        .then((response) => {
          const sectionQuestionElementToDelete =
            element.section_layouts.findIndex(
              (ele) => ele.id === formData.template_page_section_layout_id
            );

          if (sectionQuestionElementToDelete !== -1) {
            element.section_layouts.splice(sectionQuestionElementToDelete, 1);
          }

          console.log(response.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    deleteASection(element, pageIndex) {
      if (
        confirm(
          "Deleting this section also delete all the questions under it. Are you sure you want to delete this Section?"
        )
      ) {
        const formData = {
          template_page_layout_id: element.id,
        };

        TemplateService.deleteASection(formData)
          .then((response) => {
            const elementToDelete = this.templatePages[
              pageIndex
            ].elements.findIndex((ele) => ele.id === element.id);

            if (elementToDelete !== -1) {
              this.templatePages[pageIndex].elements.splice(elementToDelete, 1);
            }

            console.log(response.data);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },

    addNewPage() {
      const formData = {
        template_id: this.template.id,
      };

      TemplateService.addANewPage(formData)
        .then((response) => {
          let newPage = response.data.newPage;

          this.templatePages.push(newPage);

          console.log(response.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    deleteAPage(page) {
      const formData = {
        template_page_id: page.id,
      };

      if (
        confirm(
          "Deleting this page also delete all the questions and sections under it. Are you sure you want to delete this page?"
        )
      ) {
        TemplateService.deleteThisPage(formData)
          .then((response) => {
            const pageToDelete = this.templatePages.findIndex(
              (pg) => pg.id === page.id
            );

            if (pageToDelete !== -1) {
              this.templatePages.splice(pageToDelete, 1);
            }

            console.log(response.data);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },

    openShowReorderPagesModal() {
      this.showReorderPagesModal = true;
    },

    closeShowReorderPagesModal() {
      this.showReorderPagesModal = false;
    },

    setSelectedResponseType(type) {
      this.currentElement.response_type = type;

      //delete an associated automations
      if (this.currentElement.automations.length > 0) {
        const formData = {
          the_element: this.currentElement,
        };

        TemplateService.deleteWhenElementChangesDeleteAutomations(formData)
          .then((response) => {
            this.currentElement.automations = [];
            console.log(response.data);
          })
          .catch((err) => {});
      }
    },

    checkMove(event) {
      // Check if the item being dragged is a 'section_header'
      if (event.draggedContext.element.element_type === "section_header") {
        // Check if the destination list has the class 'section-layouts-list'
        if (event.to.classList.contains("section-layouts-list")) {
          // Prevent the move
          return false;
        }
      }
      // Allow the move for other items or to other lists
      return true;
    },

    checkDropdownItemMove(event) {
      // Check if the element being dragged is theItemWrapper div
      if (event.dragged.classList.contains("theItemWrapper")) {
        if (event.to.classList.contains("dropdown_list")) {
          // Prevent the move from going into elements-list and section-layouts-list
          return true;
        }
      }

      return false;
    },

    // onAddToPageElementsArray(event){

    //   let element = event.item._underlying_vm_
    //   element.element_type = 'question'
    //   element.template_section_id = null

    //   //update on the backend

    //     // // Log the ID of the item
    //     // console.log(event.item._underlying_vm_);

    // },

    // onAddToSection(event){

    //   console.log(event);

    //   // console.log(event.item._underlying_vm_);

    // },

    togglePageBox() {
      this.showPageBox = !this.showPageBox;
    },

    setCurrentMobilePreviewPageIndex(index) {
      this.currentMobilePreviewPageIndex = index;
      this.currentMobilePage = this.templatePages[index];
    },

    triggerInput() {
      let input = document.createElement("input");
      input.type = "file";
      input.multiple = true;
      input.accept = "image/*,video/*,application/pdf";

      input.onchange = () => {
        let files = Array.from(input.files);
        this.uploadPreviewFiles(files);
        // console.log(files);
      };

      input.click();
    },

    uploadPreviewFiles(files) {
      //show loading state
      this.currentElement.is_file_uploading = "true";

      const formData = new FormData();

      files.forEach((file, index) => {
        formData.append(`files[${index}]`, file);
      });

      TemplateService.uploadPreviewFiles(formData)
        .then((response) => {
          let filesArray = response.data.mediaFiles;

          filesArray.forEach((file) => {
            // Create a deep copy of the file object
            let fileCopy = JSON.parse(JSON.stringify(file));

            // Modify fileCopy as needed

            // Push the copy into the array
            this.currentElement.media_files.push(fileCopy);
          });

          this.currentElement.is_file_uploading = "false";

          console.log(response.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    openShowImageAndVideoGalleryModal() {
      // //set the current gallery array
      // if(this.galleryFiles.length > 0){

      //   this.currentElement.media_files

      // }

      this.showImageAndVideoGalleryModal = true;
    },

    closeShowImageAndVideoGalleryModal() {
      this.showImageAndVideoGalleryModal = false;

      //reset gallery array
      this.galleryFiles = [];
    },

    setCurrentFileIndex(theFile) {
      //set the array
      this.galleryFiles = [];
      this.galleryFiles = this.getImageAndVideoFilesOnly(this.currentElement);

      //find the index of the matching file id
      const fileIndex = this.galleryFiles.findIndex(
        (file) => file.name === theFile.name
      );

      this.currentFileIndexInGallery = fileIndex;

      this.openShowImageAndVideoGalleryModal();

      console.log(this.currentFileIndexInGallery);
      console.log(theFile);
    },

    nextFile() {
      if (this.currentFileIndexInGallery < this.galleryFiles.length - 1) {
        this.currentFileIndexInGallery++;
      }
    },

    prevFile() {
      if (this.currentFileIndexInGallery > 0) {
        this.currentFileIndexInGallery--;
      }
    },

    deleteFileFromGallery() {
      if (this.currentFileIndexInGallery !== -1) {
        let theFile = this.galleryFiles[this.currentFileIndexInGallery];

        //delete file from currentElement media_file by name
        const theFileInMediaFilesToDeleteIndex =
          this.currentElement.media_files.findIndex(
            (file) => file.name === theFile.name
          );

        if (theFileInMediaFilesToDeleteIndex !== -1) {
          this.currentElement.media_files.splice(
            theFileInMediaFilesToDeleteIndex,
            1
          );
        }

        //delete from gallery array too
        this.galleryFiles.splice(this.currentFileIndexInGallery, 1);

        //set to last file in array
        let lastIndex = this.galleryFiles.length - 1;
        this.currentFileIndexInGallery = lastIndex;

        //rerun the function that filters the img and video
        this.getImageAndVideoFilesOnly(this.currentElement);

        // if no more files left in array close the modal
        if (this.galleryFiles.length === 0) {
          this.closeShowImageAndVideoGalleryModal();
        }
      }
    },

    deletePdfFile(pdfFile) {
      //find pdf file by name in media_files and delete
      const pdfToDelete = this.currentElement.media_files.findIndex(
        (pdf) => pdf.name === pdfFile.name
      );

      if (pdfToDelete !== -1) {
        this.currentElement.media_files.splice(pdfToDelete, 1);
      }

      // //then rerun pdf files filter getter func
      // this.getPDfFiles(this.currentElement)
    },

    downloadFileFromGallery() {
      let theFile = this.galleryFiles[this.currentFileIndexInGallery];

      if (theFile.type === "image") {
        let downloadURL = theFile.url;

        fetch(downloadURL)
          .then((response) => response.blob())
          .then((blob) => {
            const link = document.createElement("a");
            link.href = URL.createObjectURL(blob);
            link.download = theFile.name; // Assuming theFile has a name property
            link.click();
          })
          .catch(console.error);
      }

      if (theFile.type === "video") {
        let downloadURL = theFile.url;

        fetch(downloadURL)
          .then((response) => response.blob())
          .then((blob) => {
            const link = document.createElement("a");
            link.href = URL.createObjectURL(blob);
            link.download = theFile.name; // Assuming theFile has a name property
            link.click();
          })
          .catch(console.error);
      }
    },

    downloadPDFfile(pdfFile) {
      console.log(pdfFile);

      // let downloadURL = pdfFile.url;

      // fetch(downloadURL)
      //   .then(response => {
      //     if (!response.ok) {
      //       throw new Error("Network response was not ok");
      //     }
      //     return response.blob();
      //   })
      //   .then(blob => {
      //     const url = window.URL.createObjectURL(blob);
      //     const a = document.createElement("a");
      //     a.href = url;
      //     a.download = pdfFile.name || "download.pdf"; // Use the file name if available, else default to "download.pdf"
      //     document.body.appendChild(a);
      //     a.click();
      //     window.URL.revokeObjectURL(url);
      //     document.body.removeChild(a);
      //   })
      //   .catch(error => {
      //     console.error("There has been a problem with your fetch operation:", error);
      //   });
    },

    attachmentDownloadPDFfile(pdfFile) {
      let downloadURL = pdfFile.file_url;

      fetch(downloadURL)
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.blob();
        })
        .then((blob) => {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = pdfFile.name || "download.pdf"; // Use the file name if available, else default to "download.pdf"
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
          document.body.removeChild(a);
        })
        .catch((error) => {
          console.error(
            "There has been a problem with your fetch operation:",
            error
          );
        });
    },

    toggleOnEditMote(element) {
      element.show_note = "yes";
    },

    toggleOffEditMote(element) {
      element.show_note = "no";
    },

    openShowRangeSelectorModal() {
      this.showRangeSelectorModal = true;
    },

    closeShowRangeSelectorModal() {
      this.showRangeSelectorModal = false;
    },

    triggerInstructionAttachmentInput() {
      let input = document.createElement("input");
      input.type = "file";
      input.accept = "image/*,application/pdf";

      input.onchange = (e) => {
        let file = e.target.files[0];

        if (file) {
          this.uploadAttachmentFiles(file);

          // Log the file name for debugging purposes
          console.log(file.name);
        }
      };

      // Trigger the file input dialog
      input.click();
    },

    uploadAttachmentFiles(file) {
      if (this.currentElement.is_a_section_element === "no") {
        //add template_page_layout_id in reqpest

        const formData = new FormData();
        const theTemplatePageLayoutId = this.currentElement.id;

        formData.append("is_a_section_element", "no");
        formData.append("the_file", file);
        formData.append("template_page_layout_id", theTemplatePageLayoutId);

        TemplateService.uploadInstructionAttachmentFile(formData)
          .then((response) => {
            let theMediaFile = response.data.the_media_file;

            this.currentElement.instruction_files.push(theMediaFile);

            console.log(response.data);
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        //add template_page_section_layout_id in reqpest

        const formData = new FormData();
        const theTemplatePageSectionLayoutId = this.currentElement.id;

        formData.append("is_a_section_element", "yes");
        formData.append("the_file", file);
        formData.append(
          "template_page_section_layout_id",
          theTemplatePageSectionLayoutId
        );

        TemplateService.uploadInstructionAttachmentFile(formData)
          .then((response) => {
            let theMediaFile = response.data.the_media_file;

            this.currentElement.instruction_files.push(theMediaFile);

            console.log(response.data);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },

    triggerInstructionAttachmentInputReplacement() {
      let input = document.createElement("input");
      input.type = "file";
      input.accept = "image/*,application/pdf";

      input.onchange = (e) => {
        let file = e.target.files[0];

        if (file) {
          this.uploadReplacementAttachmentFiles(file);
        }
      };

      // Trigger the file input dialog
      input.click();
    },

    uploadReplacementAttachmentFiles(file) {
      const formData = new FormData();
      const theMediaFileId = this.currentElement.instruction_files[0].id;

      formData.append("the_file", file);
      formData.append("the_media_file_id", theMediaFileId);

      TemplateService.uploadReplacementInstructionAttachmentFile(formData)
        .then((response) => {
          let theMediaFile = response.data.the_new_media_file;

          //reset before new one pushed in
          this.currentElement.instruction_files = [];

          this.currentElement.instruction_files.push(theMediaFile);

          console.log(response.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    deleteAttachmentFile() {
      if (confirm("Are you sure you want to delete this file?")) {
        const formData = {
          the_media_file_id: this.currentElement.instruction_files[0].id,
        };

        TemplateService.deleteAttachmentFile(formData)
          .then((response) => {
            this.currentElement.instruction_files = []; //reset

            console.log(response.data);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },

    addNewDropownOption() {
      const formData = {
        template_page_layout_id: this.currentElement.id,
      };

      TemplateService.addANewOptionToDropdownToElement(formData)
        .then((response) => {
          let newOption = response.data.newOption;

          this.currentElement.dropdown_options.push(newOption);

          console.log(response.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    deleteThisDropdownOption(option) {
      const formData = {
        dropdown_option_id: option.id,
      };

      TemplateService.deleteADropdownOption(formData)
        .then((response) => {
          const optionToDelete = this.currentElement.dropdown_options.findIndex(
            (ele) => ele.id === option.id
          );

          this.currentElement.dropdown_options.splice(optionToDelete, 1);

          console.log(response.data);
        })
        .catch((err) => {
          console.log(err);
        });

      console.log(option);
    },

    toggleIsFlagged(dropdownOption) {
      if (dropdownOption.is_flagged === 1) {
        dropdownOption.is_flagged = 0;
      } else {
        dropdownOption.is_flagged = 1;
      }
    },

    toggleDropdown(element) {
      if (element.show_dropdown === 1) {
        element.show_dropdown = 0;
      } else {
        element.show_dropdown = 1;
      }
    },

    setSelectedDropdownOption(element, dropItem) {
      element.dropdown_option_id = dropItem.id;

      // console.log(element)
    },

    showSelectedOption(element) {
      let theOption = {
        option_text: null,
        color_code: null,
      };

      element.dropdown_options.forEach((drop) => {
        if (drop.id === element.dropdown_option_id) {
          theOption.option_text = drop.option_text;
          theOption.color_code = drop.color_code;

          // Check if the string exceeds 30 characters
          if (theOption.option_text.length > 20) {
            theOption.option_text = theOption.option_text.slice(0, 20) + "...";
          }
        }
      });

      return theOption;
    },

    addNewChoiceToElement() {
      const formData = {
        template_page_layout_id: this.currentElement.id,
      };

      console.log(formData, "addNewChoiceToElement");

      TemplateService.addANewChoice(formData)
        .then((response) => {
          let newChoice = response.data.newChoice;

          this.currentElement.multiple_choice_options.push(newChoice);

          console.log(response.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    choiceToggleIsFlagged(choice) {
      if (choice.is_flagged === 1) {
        choice.is_flagged = 0;
      } else {
        choice.is_flagged = 1;
      }
    },

    toggleIsMultipleSelect(element) {
      if (element.is_multiple_answer_selection === "yes") {
        element.is_multiple_answer_selection = "no";
      } else {
        element.is_multiple_answer_selection = "yes";
      }
    },

    deleteChoice(choice) {
      const formData = {
        choice_id: choice.id,
      };

      TemplateService.deleteAChoice(formData)
        .then((response) => {
          const choiceToDelete = this.currentElement.dropdown_options.findIndex(
            (ele) => ele.id === option.id
          );

          this.currentElement.multiple_choice_options.splice(choiceToDelete, 1);

          console.log(response.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    toggleDropdownColorBox(dropdownOption) {
      this.currentChoice = dropdownOption;

      this.openColorBoxModal();

      // console.log(dropdownOption)

      // if (dropdownOption.show_color_popup === 1) {
      //   dropdownOption.show_color_popup = 0
      // } else {
      //   dropdownOption.show_color_popup = 1
      // }
    },

    setDropdowColor(dropdownOption, color) {
      dropdownOption.color_code = color.hex_code;

      dropdownOption.show_color_popup = 0;
    },

    toggleChoiceColorPopup(choice) {
      this.currentChoice = choice;

      this.openColorBoxModal();

      // console.log(dropdownOption)

      // if (choice.show_color_popup === 1) {
      //   choice.show_color_popup = 0
      // } else {
      //   choice.show_color_popup = 1
      // }
    },

    setChoiceColor(color) {
      this.currentChoice.color_code = color.hex_code;

      // choice.color_code = color.hex_code

      // choice.show_color_popup = 0
      this.closeColorBoxModal();
    },

    openShowSavedOptionsModal() {
      this.showSavedOptionsModal = true;

      this.fetchAllSavedOptions();
    },

    closeShowSavedOptionsModal() {
      this.showSavedOptionsModal = false;
    },

    showEditOptionArea() {
      this.showOptionEditMode = true;
    },

    hideEditOptionArea() {
      this.showOptionEditMode = false;
    },

    setCurrentOptionElement(option) {
      this.currentOption = option;

      console.log(option);
    },

    bookmarkThisDropdownOptions() {
      const formData = {
        the_options: this.currentElement.dropdown_options,
      };

      TemplateService.saveChoiceOrDropdownOption(formData)
        .then((response) => {
          console.log(response.data);

          toast("Options saved!", {
            position: "bottom-right",
            autoClose: 1000,
          });
        })
        .catch((err) => {
          console.log(err);
        });

      // console.log(this.currentElement.dropdown_options)
    },

    fetchAllSavedOptions() {
      TemplateService.getAllSavedOptions()
        .then((response) => {
          this.allOptions = response.data.allOptions;

          console.log(response.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    saveTheSavedOptionsChanges() {
      const formData = {
        template_saved_option_id: this.currentOption.id,
        the_options: this.currentOption.template_saved_option_elements,
      };

      TemplateService.updateSavedOptions(formData)
        .then((response) => {
          toast("Saved!", {
            position: "bottom-right",
            autoClose: 1000,
          });

          console.log(response.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    addNewSaveOption() {
      const formData = {
        template_saved_option_id: this.currentOption.id,
      };

      TemplateService.addNewSavedOptionElement(formData)
        .then((response) => {
          let newOption = response.data.newOption;

          this.currentOption.template_saved_option_elements.push(newOption);

          console.log(response.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    deleteASavedOptionElement(index) {
      const formData = {
        template_saved_option_element_id:
          this.currentOption.template_saved_option_elements[index].id,
      };

      TemplateService.deleteThisSavedOptionElement(formData)
        .then((response) => {
          this.currentOption.template_saved_option_elements.splice(index, 1);

          console.log(response.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    deleteThisSavedOption(option, optionIndex) {
      if (confirm("Are you sure you want to delete these options?")) {
        const formData = {
          template_saved_option_id: option.id,
        };

        TemplateService.deleteSavedOption(formData)
          .then((response) => {
            this.allOptions.splice(optionIndex, 1);

            console.log(response.data);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },

    replaceSavedOption(option) {
      if (
        confirm(
          "Using this saved option will replace the current ones in this dropdown. Are you sure?"
        )
      ) {
        const formData = {
          template_page_layout_id: this.currentElement.id,
          template_saved_option_id: option.id,
        };

        TemplateService.replaceWithSavedOptionElement(formData)
          .then((response) => {
            let newDropdownOptions = response.data.newDropdownOptions;

            //replace with newDropdownOptions
            this.replaceWithNewDropdownOptions(newDropdownOptions);

            console.log(response.data);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },

    bookmarkTheseChoices() {
      const formData = {
        the_options: this.currentElement.multiple_choice_options,
      };

      TemplateService.saveChoiceOrDropdownOption(formData)
        .then((response) => {
          console.log(response.data);

          toast("Options saved!", {
            position: "bottom-right",
            autoClose: 1000,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },

    replaceWithSavedChoices(option) {
      if (
        confirm(
          "Using these saved choices will replace the current ones. Are you sure?"
        )
      ) {
        const formData = {
          template_page_layout_id: this.currentElement.id,
          template_saved_option_id: option.id,
        };

        TemplateService.replaceWithSavedChoices(formData)
          .then((response) => {
            let newChoices = response.data.newChoices;

            //replace with newDropdownOptions
            this.currentElement.multiple_choice_options = []; //reset

            //then replace with new
            newChoices.forEach((choice) => {
              this.currentElement.multiple_choice_options.push(choice);
            });

            this.closeShowSavedOptionsModal();

            console.log(response.data);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },

    sectionReplaceWithSavedChoices(option) {
      if (
        confirm(
          "Using these saved choices will replace the current ones. Are you sure?"
        )
      ) {
        const formData = {
          template_page_section_layout_id: this.currentElement.id,
          template_saved_option_id: option.id,
        };

        TemplateService.sectionReplaceWithSavedChoices(formData)
          .then((response) => {
            let newChoices = response.data.newChoices;

            //replace with newDropdownOptions
            this.currentElement.multiple_choice_options = []; //reset

            //then replace with new
            newChoices.forEach((choice) => {
              this.currentElement.multiple_choice_options.push(choice);
            });

            this.closeShowSavedOptionsModal();

            console.log(response.data);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },

    replaceWithNewDropdownOptions(newDropdownOptions) {
      this.currentElement.dropdown_options = []; //reset

      //then replace with new
      newDropdownOptions.forEach((option) => {
        this.currentElement.dropdown_options.push(option);
      });

      this.closeShowSavedOptionsModal();
    },

    toggleScoringEnabled() {
      if (this.template.is_scoring_enabled === "yes") {
        this.template.is_scoring_enabled = "no";
      } else {
        this.template.is_scoring_enabled = "yes";
      }
    },

    openShowSignatureModal() {
      this.showSignatureModal = true;
    },

    closeShowSignatureModal() {
      this.showSignatureModal = false;
    },

    setCurentSignOption(signOption) {
      this.currentSignatureType = signOption.type;
    },

    getSignatureCurrentTime() {
      const formattedTime = moment().format("h:mm A");
      return formattedTime;
    },

    getSignatureCurrentDate() {
      const formattedDate = moment().format("Do MMM YYYY");
      return formattedDate;
    },

    setSignatureImg(base64Image) {
      this.currentElement.signature_img_url = base64Image;
      this.currentElement.signature_time = this.getSignatureCurrentTime();
      this.currentElement.signature_date = this.getSignatureCurrentDate();

      this.closeShowSignatureModal();

      console.log(this.currentElement.signature_img_url);
    },

    deleteSignatureImmage() {
      this.currentElement.signature_img_url = null;
      this.currentElement.signature_time = null;
      this.currentElement.signature_date = null;
    },

    getGeoCoordinates() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            (this.currentGeoPosition.latitude = position.coords.latitude),
              (this.currentGeoPosition.longitude = position.coords.longitude);

            console.log(this.currentGeoPosition);
          },
          (err) => {
            alert(err.message);
          }
        );
      } else {
        console.log("Geolocation is not supported by your browser");
      }
    },

    toggleShowMap(element) {
      if (element.show_map === 1) {
        element.show_map = 0;
      } else {
        element.show_map = 1;
      }
    },

    sectionAddNewDropownOption() {
      const formData = {
        template_page_section_layout_id: this.currentElement.id,
      };

      TemplateService.sectionElementAddNewOptionToDropdown(formData)
        .then((response) => {
          let newOption = response.data.newOption;

          this.currentElement.dropdown_options.push(newOption);

          console.log(response.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    sectionReplaceSavedOption(option) {
      if (
        confirm(
          "Using this saved option will replace the current ones in this dropdown. Are you sure?"
        )
      ) {
        const formData = {
          template_page_section_layout_id: this.currentElement.id,
          template_saved_option_id: option.id,
        };

        TemplateService.sectionReplaceWithSavedOptionElement(formData)
          .then((response) => {
            let newDropdownOptions = response.data.newDropdownOptions;

            //replace with newDropdownOptions
            this.replaceWithNewDropdownOptions(newDropdownOptions);

            console.log(response.data);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },

    sectionAddNewChoiceToElement() {
      const formData = {
        template_page_section_layout_id: this.currentElement.id,
      };

      console.log(formData, "sectionAddNewChoiceToElement");

      TemplateService.sectionAddNewChoice(formData)
        .then((response) => {
          let newChoice = response.data.newChoice;

          this.currentElement.multiple_choice_options.push(newChoice);

          console.log(response.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    toggleShowSection(element) {
      if (element.show_section === 1) {
        element.show_section = 0;
      } else {
        element.show_section = 1;
      }
    },

    openAutomationsModal() {
      //reset
      this.newAutomation.string_value = null;
      this.newAutomation.number_value = null;
      this.newAutomation.selected_choice_id = null;

      this.showAutomationsModal = true;
    },

    closeAutomationsModal() {
      this.showAutomationsModal = false;
    },

    openTriggerBox() {
      this.showTriggerBox = true;
    },

    closeTriggerBox() {
      this.showTriggerBox = false;
    },

    saveAutomationOption() {
      // const formData = {
      //   template_page_layout_id: ,

      // }

      console.log("sdf");

      this.closeTriggerBox();
    },

    startAUniqueTemplateForThisUser(template_id) {
      const formData = {
        original_template_id: template_id,
      };

      TemplateService.startANewInspection(formData)
        .then((response) => {
          let uniqueTemplate = response.data.uniqueTemplate;

          this.router.push({
            name: "ViewLiveTemplate",
            params: { random_string: uniqueTemplate.random_string },
          });

          // console.log(response.data)
        })
        .catch((err) => {
          console.log(err);
        });
    },

    selectChoice(choice) {
      if (choice.is_selected === 0) {
        choice.is_selected = 1;
      } else {
        choice.is_selected = 0;
      }
    },

    setTriggerType(triggerItem) {
      this.newAutomation.trigger_type = triggerItem.type;
      this.closeTriggerBox();
    },

    saveNewAutomation() {
      let errorObject = this.validateTheFields();

      if (errorObject.hasError) {
        alert(errorObject.message);

        return;
      }

      const formData = {
        template_page_layout_id: this.currentElement.id,
        trigger_type: this.newAutomation.trigger_type,
        logic_condition: this.newAutomation.logic_condition,
        string_value: this.newAutomation.string_value,
        number_value: this.newAutomation.number_value,
        selected_choice_id: this.newAutomation.selected_choice_id,
        template_dropdown_option_id:
          this.newAutomation.template_dropdown_option_id,
      };

      // console.log(formData)

      TemplateService.saveAutomation(formData)
        .then((response) => {
          //populate the element.automations array
          let newAuto = response.data.automation;
          this.currentElement.automations.push(newAuto);

          this.closeAutomationsModal();
          this.newAutomation.trigger_type = null;
          this.newAutomation.string_value = null;
          this.newAutomation.number_value = null;

          console.log(response.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    pageSectionSaveNewAutomation() {
      let errorObject = this.validateTheFields();

      if (errorObject.hasError) {
        alert(errorObject.message);

        return;
      }

      const formData = {
        template_page_section_layout_id: this.currentElement.id,
        trigger_type: this.newAutomation.trigger_type,
        logic_condition: this.newAutomation.logic_condition,
        string_value: this.newAutomation.string_value,
        number_value: this.newAutomation.number_value,
        selected_choice_id: this.newAutomation.selected_choice_id,
        template_dropdown_option_id:
          this.newAutomation.template_dropdown_option_id,
      };

      // console.log(formData)

      TemplateService.saveAutomationForPageSection(formData)
        .then((response) => {
          //populate the element.automations array
          let newAuto = response.data.automation;
          this.currentElement.automations.push(newAuto);

          this.closeAutomationsModal();
          this.newAutomation.trigger_type = null;
          this.newAutomation.string_value = null;
          this.newAutomation.number_value = null;

          console.log(response.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    validateTheFields() {
      let hasError = false;
      let message = null;

      if (this.currentElement.response_type === "text_answer") {
        if (
          this.newAutomation.string_value === null ||
          this.newAutomation.trigger_type === null
        ) {
          message =
            "Please make sure you entered a value and selected a trigger type";
          hasError = true;
        }
      }

      if (this.currentElement.response_type === "number") {
        if (
          this.newAutomation.number_value === null ||
          this.newAutomation.trigger_type === null
        ) {
          message =
            "Please make sure you entered a number and selected a trigger type";
          hasError = true;
        }
      }

      if (this.currentElement.response_type === "checkbox") {
        if (
          this.newAutomation.string_value === null ||
          this.newAutomation.trigger_type === null
        ) {
          message =
            "Please make sure you entered a number and selected a trigger type";
          hasError = true;
        }
      }

      if (this.currentElement.response_type === "multiple_choice") {
        if (
          this.newAutomation.selected_choice_id === null ||
          this.newAutomation.logic_condition === null ||
          this.newAutomation.trigger_type === null
        ) {
          message =
            "Please make sure you selected a choice, condition and a trigger type";
          hasError = true;
        }
      }

      if (this.currentElement.response_type === "dropdown") {
        if (
          this.newAutomation.template_dropdown_option_id === null ||
          this.newAutomation.logic_condition === null ||
          this.newAutomation.trigger_type === null
        ) {
          message =
            "Please make sure you selected a dropdown option, condition and a trigger type";
          hasError = true;
        }
      }

      if (this.currentElement.response_type === "slider") {
        if (
          this.newAutomation.number_value === null ||
          this.newAutomation.trigger_type === null
        ) {
          message =
            "Please make sure you entered a number and selected a trigger type";
          hasError = true;
        }
      }

      let errorObject = {
        hasError: hasError,
        message: message,
      };

      return errorObject;
    },

    openEditAutomationModal() {
      this.showEditAutomationModal = true;
    },

    closeEditAutomationModal() {
      this.showEditAutomationModal = false;
    },

    setCurrentAutomation(automation) {
      this.editAutomationObject = automation;
    },

    onChangeUpdateChoice() {
      //find the new choice in the choices array and update the current automation.choice
      const choiceIndex = this.currentElement.multiple_choice_options.findIndex(
        (cho) => cho.id === this.editAutomationObject.selected_choice_id
      );

      let newChoice = this.currentElement.multiple_choice_options[choiceIndex];

      this.editAutomationObject.choice = newChoice;
    },

    saveUpdatedAutomation() {
      const formData = {
        the_automation: this.editAutomationObject,
      };

      // console.log(formData)

      TemplateService.updateAutomation(formData)
        .then((response) => {
          //update the automation update from backend
          let updatedAutomation = response.data.automation;
          this.hydrateTheAutomation(updatedAutomation);

          this.closeEditAutomationModal();
          this.editAutomationObject = {};

          console.log(response.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    hydrateTheAutomation(updatedAutomation) {
      const automationIndex = this.currentElement.automations.findIndex(
        (auto) => auto.id === this.editAutomationObject.id
      );

      this.currentElement.automations[automationIndex] = updatedAutomation;
    },

    deleteAutomation(automation) {
      if (confirm("Are you sure you want to delete this automation?")) {
        const formData = {
          automation_id: automation.id,
        };

        TemplateService.deleteAutomation(formData)
          .then((response) => {
            const automationToDelete =
              this.currentElement.automations.findIndex(
                (auto) => auto.id === automation.id
              );

            if (automationToDelete !== -1) {
              this.currentElement.automations.splice(automationToDelete, 1);
            }

            this.closeEditAutomationModal();

            console.log(response.data);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },

    checkType(responseType) {
      //compare and make that element.response_type doesn match any in theList

      const theList = [
        "instruction",
        "datetime",
        "time",
        "date",
        "geostamp",
        "media",
        "signature",
      ];

      return theList.includes(responseType);
    },

    deleteAutomationsOnChange() {
      console.log(this.currentElement);
    },

    openColorBoxModal() {
      this.showColorBoxModal = true;
    },

    closeColorBoxModal() {
      this.showColorBoxModal = false;
    },

    openNewSiteModal() {
      this.showNewSiteModal = true;
    },

    closeNewSiteModal() {
      this.showNewSiteModal = false;
    },

    openEditSiteModal() {
      this.showEditSiteModal = true;
    },

    closeEditSiteModal() {
      this.showEditSiteModal = false;
    },

    resetSite(){
      this.newSite.id = null
      this.newSite.name = null
      this.newSite.desc = null
    },

    openNewAssetModal() {
      this.showNewAssetModal = true;
    },

    closeNewAssetModal() {
      this.showNewAssetModal = false;
    },

    setCurrentSite(site) {
      this.newSite = site;
    },

    trimLongString(string) {
      // Check if the string is longer than 10 characters
      if (string.length > 20) {
        // Trim the string to the first 10 characters
        return string.substring(0, 20) + "...";
      }

      return string;
    },

    trimFileNameString(string) {
      // Check if the string is longer than 10 characters
      if (string.length > 15) {
        // Trim the string to the first 10 characters
        return string.substring(0, 15) + "...";
      }

      return string;
    },

    fetchAllSites() {
      TemplateService.getAllSites()
        .then((response) => {

          this.sites = response.data.sites;
          

          console.log(response.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    createSite() {
      TemplateService.saveNewSite(this.newSite)
        .then((response) => {
          this.closeNewSiteModal();
          this.newSite.name = null;
          this.newSite.desc = null;

          this.fetchAllSites();

          console.log(response.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    updateSite() {
      const formData = {
        site: this.newSite,
      };

      TemplateService.updateSite(formData)
        .then((response) => {
          this.fetchAllSites();
          this.closeEditSiteModal();

          console.log(response.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    deleteThisSite(id) {
      if (
        confirm(
          "Are you sure you want to delete this site? Deleting a site can’t be undone."
        )
      ) {
        const formData = {
          site_id: id,
        };

        TemplateService.deleteSite(formData)
          .then((response) => {
            console.log(response.data);

            this.fetchAllSites();
          })
          .catch((err) => {
            console.log(err);
          });

        console.log(formData);
      }
    },

    triggerAssetFileInput() {
      let input = document.createElement("input");
      input.type = "file";
      input.multiple = true;
      input.accept = "image/*,video/*,application/pdf";

      input.onchange = () => {
        let files = Array.from(input.files);

        // Filter out files that are larger than 2MB
        let validFiles = files.filter((file) => file.size <= 2 * 1024 * 1024);

        if (validFiles.length !== files.length) {
          alert(
            "Some files were not added because they exceed the 2MB size limit."
          );
        }

        this.newAsset.files.push(...validFiles);

        // this.newAsset.files.push(...files);
      };

      input.click();
    },

    deleteAssetFromArray(indexToDelete) {
      // delete indexToDelete from this.newAsset.files
      this.newAsset.files.splice(indexToDelete, 1);
    },

    saveNewAsset() {
      this.isAssetLoading = true;

      if (this.newAsset.site_id === null) {
        alert("Please select a location");

        return;
      }

      let formData = new FormData();
      formData.append("name", this.newAsset.name);
      formData.append("desc", this.newAsset.desc);
      formData.append("site_id", this.newAsset.site_id);
      formData.append("model", this.newAsset.model);
      formData.append("serial_number", this.newAsset.serial_number);
      formData.append("purchased_date", this.newAsset.purchased_date);

      this.newAsset.files.forEach((file, index) => {
        formData.append(`files[${index}]`, file);
      });

      TemplateService.addNewAsset(formData)
        .then((response) => {
          console.log(response.data);

          this.isAssetLoading = false;

          this.closeNewAssetModal();
          this.newAsset.name = null;
          this.newAsset.desc = null;
          this.newAsset.site_id = null;
          this.newAsset.model = null;
          this.newAsset.serial_number = null;
          this.newAsset.purchased_date = null;
          this.newAsset.files = [];

          this.fetchAllAssets();
        })
        .catch((err) => {
          console.log(err);

          this.isAssetLoading = false;
        });
    },

    fetchAllAssets() {
      TemplateService.getAllAssets()
        .then((response) => {
          this.assets = response.data.assets;
          this.sites = response.data.sites;

          console.log(response.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    fetchAAsset(uuid) {
      TemplateService.getAsset(uuid)
        .then((response) => {
          this.asset = response.data.asset;
          this.imgsArray = response.data.imgsArray;
          this.videosArray = response.data.videosArray;
          this.pdfsArray = response.data.pdfsArray;

          this.sites = response.data.sites;

          console.log(response.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    goToAssetDetails(asset) {
      this.router.push({
        name: "ViewAnAsset",
        params: { uuid: asset.uuid },
      });
    },

    goBackToViewAllAssets() {
      this.router.push({
        name: "ViewAllAssets",
      });
    },

    goToActionSettings() {
      this.router.push({
        name: "ViewActionSettings",
      });
    },

    goToViewAllActions() {
      this.router.push({
        name: "ViewAllActions",
      });
    },

    openImagePreviewModal() {
      this.showImagePreviewModal = true;
    },

    closeImagePreviewModal() {
      this.showImagePreviewModal = false;
    },

    setCurrentAssetMedialFile(file) {
      this.currentAssetMediaFile = file;
    },

    deleteThisAsset(asset) {
      if (
        confirm(
          "Are you sure you want to delete this? Once deleted it cannot be recovered."
        )
      ) {
        const formData = {
          asset_id: asset.id,
        };

        TemplateService.deleteAsset(formData)
          .then((response) => {
            this.fetchAllAssets();

            console.log(response.data);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },

    attachmentDownloadPDFfile(pdfFile) {
      let downloadURL = pdfFile.file_url;

      fetch(downloadURL)
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.blob();
        })
        .then((blob) => {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = pdfFile.name || "download.pdf"; // Use the file name if available, else default to "download.pdf"
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
          document.body.removeChild(a);
        })
        .catch((error) => {
          console.error(
            "There has been a problem with your fetch operation:",
            error
          );
        });
    },

    downloadImageFile(theFile) {
      let downloadURL = theFile.file_url;

      fetch(downloadURL)
        .then((response) => response.blob())
        .then((blob) => {
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = theFile.file_name; // Assuming theFile has a name property
          link.click();
        })
        .catch(console.error);
    },

    downloadVideoFile(theFile) {
      let downloadURL = theFile.file_url;

      fetch(downloadURL)
        .then((response) => response.blob())
        .then((blob) => {
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = theFile.file_name; // Assuming theFile has a name property
          link.click();
        })
        .catch(console.error);
    },

    openEditAssetForm() {
      this.showEditAssetForm = true;
    },

    closeEditAssetForm() {
      this.showEditAssetForm = false;
    },

    editFormtriggerInput() {
      let input = document.createElement("input");
      input.type = "file";
      input.multiple = true;
      input.accept = "image/*,video/*,application/pdf";

      input.onchange = () => {
        let files = Array.from(input.files);

        // Filter out files that are larger than 2MB
        let validFiles = files.filter((file) => file.size <= 2 * 1024 * 1024);

        if (validFiles.length !== files.length) {
          alert(
            "Some files were not added because they exceed the 2MB size limit."
          );

          return;
        }

        this.uploadTheseAssetFiles(validFiles);
      };

      input.click();
    },

    uploadTheseAssetFiles(files) {
      this.isAssetLoading = true;

      const formData = new FormData();

      files.forEach((file, index) => {
        formData.append(`files[${index}]`, file);
      });

      formData.append("asset_id", this.asset.id);

      TemplateService.uploadAssetFilesToDO(formData)
        .then((response) => {
          let filesArray = response.data.newAssets;

          filesArray.forEach((file) => {
            // Create a deep copy of the file object
            let fileCopy = JSON.parse(JSON.stringify(file));

            // Push the copy into the array
            this.asset.asset_files.push(fileCopy);
          });

          this.isAssetLoading = false;

          console.log(response.data);
        })
        .catch((err) => {
          console.log(err);

          this.isAssetLoading = false;
        });
    },

    editFormDeleteAssetFromArray(indexToDelete) {
      if (
        confirm(
          "Are you sure you want to delete this file? Once deleted it cannot be recovered."
        )
      ) {
        let fileToDelete = this.asset.asset_files[indexToDelete];

        const formData = {
          file_id: fileToDelete.id,
        };

        TemplateService.deleteAssetMediaFile(formData)
          .then((response) => {
            this.asset.asset_files.splice(indexToDelete, 1);

            console.log(response.data);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },

    saveAssetFormChanges() {
      const formData = {
        asset: this.asset,
      };

      // console.log(formData)

      TemplateService.updateAsset(formData)
        .then((response) => {
          this.closeEditAssetForm();

          //refresh data
          this.fetchAAsset(this.asset.uuid);

          toast("Saved!", {
            position: "bottom-right",
            autoClose: 1000,
          });

          console.log(response.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    showAddLabelForm() {
      this.theLabel.name = null;
      this.showAddNewLabelForm = true;
    },

    hideAddLabelForm() {
      this.showAddNewLabelForm = false;
    },

    showEditLabelForm() {
      this.showAddEditLabelForm = true;
    },

    hideEditLabelForm() {
      this.showAddEditLabelForm = false;
    },

    fetchAllLabels() {
      TemplateService.getAllLabels()
        .then((response) => {
          this.labels = response.data.labels;

          console.log(response.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    addNewLabel() {
      const formData = {
        name: this.theLabel.name,
      };

      TemplateService.addNewLabel(formData)
        .then((response) => {
          let newLabel = response.data.newLabel;

          this.labels.push(newLabel);

          this.hideAddLabelForm();
          this.theLabel.name = null;
        })
        .catch((err) => {});
    },

    setCurrentLabel(label) {
      this.theLabel = label;
      this.showEditLabelForm();
    },

    updateLabel() {
      const formData = {
        label: this.theLabel,
      };

      // console.log(formData)

      TemplateService.updateLabel(formData)
        .then((response) => {
          this.hideEditLabelForm();
          this.fetchAllLabels();
          console.log(response.data);
        })
        .catch((err) => {});
    },

    deleteLabel(label) {
      if (
        confirm(
          "This label will be removed from actions that have selected this particular label. The actions themselves won't be deleted. You cannot undo this deletion."
        )
      ) {
        const formData = {
          label_id: label.id,
        };

        TemplateService.deleteLabel(formData)
          .then((response) => {
            this.fetchAllLabels();

            console.log(response.data);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },

    openNewActionModal() {
      this.populateDueDateAndTimeCurrentTime()
      this.showNewActionModal = true;
    },

    closeNewActionModal() {
      this.showNewActionModal = false;
    },

    fetchAllActions() {
      TemplateService.getAllActions()
        .then((response) => {

          this.actions = response.data.actions;
          this.sites = response.data.sites;
          this.assets = response.data.assets;
          this.labels = response.data.labels;
          this.allGroups = response.data.groups
          this.allInspectionUsers = response.data.allInspectionUsers

          console.log(response.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    openInspectionAddUsersModal() {
      this.fetchAllEmployeesForInspectionImport();

      this.showInspectionAddUsersModal = true;
    },

    closeInspectionAddUsersModal() {
      this.showInspectionAddUsersModal = false;
      this.usersToAddToInspectionAppArray = [];
    },

    fetchAllEmployeesForInspectionImport() {
      TemplateService.getAllEmployeesForImport()
        .then((response) => {
          this.allEmployees = response.data.employees;

          console.log(response.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    addThisUser(theUser) {
      // Find the index of the user in the array
      const userIndex = this.usersToAddToInspectionAppArray.findIndex(
        (user) => user.id === theUser.id
      );

      if (userIndex !== -1) {
        // User exists, remove them from the array
        this.usersToAddToInspectionAppArray.splice(userIndex, 1);
        console.log("User already exists so remove from array");
      } else {
        // User does not exist, add them to the array
        this.usersToAddToInspectionAppArray.push(theUser);
        console.log("Add user to array as the user does not exist in it");
      }
    },

    importTheseUsersToInspectionApp() {
      if (this.usersToAddToInspectionAppArray.length === 0) {
        alert("Please select the users!");

        return;
      }

      if (
        confirm("Are sure you want import these users to the inpsection app?")
      ) {
        const formData = {
          users: this.usersToAddToInspectionAppArray,
        };

        TemplateService.importUsersToInspectionNormalUsersTable(formData)
          .then((response) => {
            this.closeInspectionAddUsersModal();

            toast("Users imported!", {
              position: "bottom-right",
              autoClose: 2000,
            });

            console.log(response.data);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },

    fetchAllEmployeesForInsectionApp() {
      TemplateService.getAllEmployeesForTheInspectionApp()
        .then((response) => {

          this.employeesUsingInspectionApp = response.data.employees;
          this.team_size_limit = response.data.team_size_limit
          this.used_seats_amt = response.data.used_seats_amt
          this.seats_available_amt = response.data.seats_available_amt

          console.log(response.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    saveChanges() {
      const formData = {
        template: this.template,
        templatePages: this.templatePages,
      };

      TemplateService.saveWholeTemplateProject(formData)
        .then((response) => {
          console.log(response.data);
          console.log("formData", formData);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // This code will be executed after a 5000ms debounce delay
    // Add your logic here, such as making an API call or updating the data
    debouncedSaveChanges: debounce(function () {
      this.saveChanges();
      console.log("changes saved");
    }, 4000),

    addNewUserBox() {
      

      if(this.newInspectionUsersArray.length !== this.seats_available_amt){

        this.newInspectionUsersArray.push({
          email: "",
          is_email_valid: "no",
          error_message: "",
          first_name: "",
          last_name: "",
          password: "password123",
          role_type: "",
          teams: [],
          sites: [],
          show_error: 0,
        });

      }else{
        alert("Seat limit reached! Please contact account manager for upgrade.")
      }

     


    },

    deleteANewUserFromArray(index) {
      this.newInspectionUsersArray.splice(index, 1);
    },

    populateNewInspectionUsersArray() {
      this.currentAddNewUserStep = "add_user_details";
      this.newInspectionUsersArray = [];
      this.addNewUserBox();
    },

     goToSelectTeamAndSite() {

      if (this.checkUserDetailsInputsAreVaild() && this.checkEmailValidationResults()) {
        this.currentAddNewUserStep = "select_team_and_site";

        this.populateTeamsForEachUser()
        this.populateSitesForEachUser()
      }


    },

    checkUserDetailsInputsAreVaild() {
      this.resetErrors();

      let isValid = true; // Assume valid by default

      this.newInspectionUsersArray.forEach((user) => {
        if (
          !user.email?.trim() ||
          !user.email.includes('@') || 
          !user.first_name?.trim() ||
          !user.last_name?.trim() ||
          !user.password?.trim()
        ) {
          user.show_error = 1;
          isValid = false; // Set to false if any field is invalid
        }
      });

      return isValid;
    },


    checkEmailValidationResults(){

      let isValid = true; // Assume valid by default

      this.newInspectionUsersArray.forEach((user) => {

        if(user.is_email_valid === 'no'){

          user.show_error = 1;
          isValid = false; // Set to false if any field is invalid
       
        }

      });

      return isValid;

    },



    resetErrors() {
      this.newInspectionUsersArray.forEach((user) => {
        user.show_error = 0;
      });
    },

    goToAddNewUserDetails() {
      this.currentAddNewUserStep = "add_user_details";
    },

    goToAddNewUserFinalStep() {
      this.currentAddNewUserStep = "complete_add_users";
    },

    goToAddNewUsersResultsComplete() {
      this.currentAddNewUserStep = "added_users_results";
    },

    openNewGroupModal() {
      this.showNewGroupModal = true;
    },

    closeNewGroupModal() {
      this.showNewGroupModal = false;
    },

    openEditGroupModal() {
      this.showEditGroupModal = true;
    },

    closeEditGroupModal() {
      this.showEditGroupModal = false;
    },

    storeNewInspectionTeam() {
      TemplateService.storeNewGroup(this.newGroupFormData)
        .then((response) => {
          this.closeNewGroupModal();
          this.newGroupFormData.name = "";
          this.newGroupFormData.desc = "";

          this.fetchAllGroups();

          toast("New Group Created!", {
            position: "bottom-right",
            autoClose: 1000,
          });

          console.log(response.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    updateInspectionTeam() {
      const formData = {
        current_group: this.currentGroup,
      };

      // console.log(formData)

      TemplateService.updateInspectionGroup(formData)
        .then((response) => {
          this.closeEditGroupModal();

          toast("Updated!", {
            position: "bottom-right",
            autoClose: 1000,
          });

          console.log(response.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    setCurrentGroup(group) {
      this.currentGroup = group;
      this.openEditGroupModal();
    },

    limitText(body) {
      if (body.length > 70) {
        return body.substring(0, 70) + "...";
      }

      return body;
    },

    fetchAllGroups() {
      this.allGroups = []; //reset

      TemplateService.getAllGroups()
        .then((response) => {

          this.allGroups = response.data.groups;
          this.team_size_limit = response.data.team_size_limit
          this.used_seats_amt = response.data.used_seats_amt
          this.seats_available_amt = response.data.seats_available_amt

          console.log(response.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    openEditNewUserToGroupModal() {
      this.showEditNewUserToGroupModal = true;
    },

    closeEditNewUserToGroupModal() {
      this.showEditNewUserToGroupModal = false;
    },

    setCurrentNewUser(newUser) {
      this.currentNewUser = newUser;

      this.openEditNewUserToGroupModal();
    },

    setCurrentNewUserForSites(newUser) {
      this.currentNewUser = newUser;
      this.openEditNewUserSiteModal();
    },


    populateTeamsForEachUser() {

      this.newInspectionUsersArray.forEach((newUser) => {

        // Use a Set to track existing team IDs to prevent duplicates
        const existingTeamIds = new Set(newUser.teams.map(team => team.id));
    
        this.allGroups.forEach((group) => {

          // Only add if the team ID is not already in the existing teams
          if (!existingTeamIds.has(group.id)) {

            newUser.teams.push({
              id: group.id,
              name: group.name,
              is_checked: 0,
            });
            // Add the new ID to the set to prevent future duplicates
            existingTeamIds.add(group.id);
          }
        });
      });
    },

    populateSitesForEachUser() {

      this.newInspectionUsersArray.forEach((newUser) => {

        // Use a Set to track existing team IDs to prevent duplicates
        const existingSiteIds = new Set(newUser.sites.map(site => site.id));
    
        this.sites.forEach((site) => {

          // Only add if the team ID is not already in the existing teams
          if (!existingSiteIds.has(site.id)) {

            newUser.sites.push({
              id: site.id,
              name: site.name,
              is_checked: 0,
            });
            // Add the new ID to the set to prevent future duplicates
            existingSiteIds.add(site.id);
          }
        });
      });
    },

    updateCurrentGroupList(group) {
      //if the status id true/1 switch to false
      if (group.is_checked) {
        group.is_checked = 0;
      } else {
        group.is_checked = 1;
      }
    },

    updateCurrentSiteList(site) {
      //if the status id true/1 switch to false
      if (site.is_checked) {
        site.is_checked = 0;
      } else {
        site.is_checked = 1;
      }
    },


    openEditNewUserSiteModal(){
      this.showEditNewUserSiteModal = true
    },

    closeEditNewUserSiteModal(){
      this.showEditNewUserSiteModal = false
    },


    finallyAddNewUsersComplete() {

      
      for (const user of this.newInspectionUsersArray) {

        if (user.role_type === '') {
          alert('Please make sure a role is selected for each user!');
          return false;
        }
    
        const hasSelectedTeam = user.teams.some(team => team.is_checked === 1);

        if (!hasSelectedTeam) {
          alert("Please make sure a group is selected for each user!");
          return false;
        }
    
        const hasSelectedSite = user.sites.some(site => site.is_checked === 1);

        if (!hasSelectedSite) {
          alert("Please make sure a site is selected for each user!");
          return false;
        }
      }

      this.isAddingNewUsers = true

      const formData = {
        newUsers: this.newInspectionUsersArray
      }


      TemplateService.finallyAddNewUsersToDB(formData)
        .then((response) => {


            //deleay a bit
            setTimeout(() => {

              this.goToAddNewUsersResultsComplete()
              this.isAddingNewUsers = false
              console.log(response.data)

            }, 3000);

      
         
          
        }).catch((err) => {

          this.isAddingNewUsers = false


          console.log(err)
          
        });
    
   


    },


    checkIfEmailIsVaildAndDoNotExistsInDB: debounce(function (newUser) {

      console.log('trigger the check email:', newUser.email)

      const formData = {
        email: newUser.email
      }

      TemplateService.checkIfEmailIsVaildAndDoNotExistsInDB(formData)
        .then((response) => {

          let message = response.data.message
          let info = response.data.info

          if(message === 'email_is_valid'){

            newUser.is_email_valid = 'yes'
            newUser.show_error = 0
            newUser.error_message = ""
          
            console.log('EMAIL IS VALID')

          }

          if(message === 'not_valid'){

            newUser.error_message = info
            newUser.is_email_valid = 'no'
            newUser.show_error = 1

            console.log('EMAIL IS NOT VALID')

          }

          // console.log(response.data)
          
        }).catch((err) => {

          console.log(err)
          
        });


    }, 1000),



    openAddUserToGroupModal(){
      this.groupsToAddUserTo = []
      this.showAddUserToGroupModal = true
    },

    closeAddUserToGroupModal(){
      this.showAddUserToGroupModal = false
    }, 

    


    setCurrentUser(user){
      this.currentUser = user
    },

    removeGroupsThisUserIsAlreadyAddedTo() {

      this.groupIdsToExclude = []

      this.currentUser.inspection_teams.forEach((group) => {

          this.groupIdsToExclude.push({
              id: group.template_inspection_team_id,
          })

      })

      this.filteredGroups = this.allGroups.filter(group => {

        const isNotExcluded = !this.groupIdsToExclude.some(excluded => 
            excluded.id === group.id
        );

        if (isNotExcluded) {
            group.is_checked = 0; // Add is_selected property to each filtered group
            return true;
        }
        return false;

    })
      
        // this.filteredGroups = this.allGroups.filter(group => 

        //     !this.groupIdsToExclude.some(excluded => 
        //         excluded.id === group.id
        //     )

        // )
    },

    setCurrentUserAndOpenGroupModal(user){

      this.setCurrentUser(user)
      this.removeGroupsThisUserIsAlreadyAddedTo()
      this.openAddUserToGroupModal()

    },


    addUserToGroup(group){

    
      if (group.is_checked) {
        
        //remove from array
        group.is_checked = 0;

        this.groupsToAddUserTo.forEach((grp, index) => {

          //find the team_id in groupsToAddUserTo that matched grp.id and remove it
          if (grp.team_id === group.id) {
              this.groupsToAddUserTo.splice(index, 1);
          }
      });



      } else {
        //add to array
        group.is_checked = 1;


        this.groupsToAddUserTo.push({
          team_id: group.id
        });


      }


    },

    saveAddUserToNewGroup(){

      this.isLoading = true

      const formData = {
        user_id: this.currentUser.id,
        teams: this.groupsToAddUserTo
      }

      TemplateService.addUserToNewGroup(formData)
        .then((response) => {

          this.fetchAllEmployeesForInsectionApp()
          this.fetchAllGroups()
          this.closeAddUserToGroupModal()

          toast("Updated!", {
            position: "bottom-right",
            autoClose: 1000,
          });

          this.isLoading = false

          console.log(response.data)
          
        }).catch((err) => {

          this.isLoading = false

          console.log(err)
          
        });



    },


    openAddUserToSiteModal(){
      this.showAddUserToSiteModal = true
      this.sitesToAddUserTo = []
    },

    closeAddUserToSiteModal(){
      this.showAddUserToSiteModal = false
    },

    removeSitesThisUserIsAlreadyAddedTo() {

      this.siteIdsToExclude = []

      this.currentUser.inspection_sites.forEach((site) => {

          this.siteIdsToExclude.push({
              id: site.site_id,
          })

      })

      this.filteredSites = this.sites.filter(site => {

        const isNotExcluded = !this.siteIdsToExclude.some(excluded => 
            excluded.id === site.id
        );

        if (isNotExcluded) {
            site.is_checked = 0; // Add is_selected property to each filtered site
            return true;
        }
        return false;

    })
      
     
    },


    setCurrentUserAndOpenSiteModal(user){

      this.setCurrentUser(user)
      this.removeSitesThisUserIsAlreadyAddedTo()
      this.openAddUserToSiteModal()

    },


    addUserToSite(site){

    
      if (site.is_checked) {
        
        //remove from array
        site.is_checked = 0;

        this.sitesToAddUserTo.forEach((st, index) => {

          if (st.site_id === site.id) {
              this.sitesToAddUserTo.splice(index, 1);
          }
      });



      } else {
        //add to array
        site.is_checked = 1;


        this.sitesToAddUserTo.push({
          site_id: site.id
        });


      }


    },
    

    saveAddUserToNewSite(){

      this.isLoading = true

      const formData = {
        user_id: this.currentUser.id,
        sites: this.sitesToAddUserTo
      }

      // console.log(formData)

      TemplateService.addUserToSite(formData)
        .then((response) => {

          this.fetchAllEmployeesForInsectionApp()
          this.fetchAllSites()
          this.closeAddUserToSiteModal()

          toast("Updated!", {
            position: "bottom-right",
            autoClose: 1000,
          });

          this.isLoading = false

          console.log(response.data)
          
        }).catch((err) => {

          this.isLoading = false

          console.log(err)
          
        });



    },


    openDeactivateUserModal(){
      this.showDeactivateUserModal = true
    },

    closeDeactivateUserModal(){
      this.showDeactivateUserModal = false
    },


    setCurrentUserAndOpenDeactivationModal(user){

      this.setCurrentUser(user)
      this.openDeactivateUserModal()

    },


    deactivateThisUser(){

      const formData = {
        user_id: this.currentUser.id
      }

      TemplateService.deactivateUser(formData)
        .then((response) => {

          this.reFetchAllForDataForViewAllUsers()
          this.closeDeactivateUserModal()

          toast("Updated!", {
            position: "bottom-right",
            autoClose: 1000,
          });


          console.log(response.data)
          
        }).catch((err) => {

          console.log(err)
          
        });

      // console.log(formData)

    },

    reFetchAllForDataForViewAllUsers(){

      this.fetchAllEmployeesForInsectionApp()
      this.fetchAllGroups()
      this.fetchAllSites()

    },


    openReActivateUserModal(){
      this.showReActivateUserModal = true
    },

    closeReActivateUserModal(){
      this.showReActivateUserModal = false
    },


    openSetUserAndOpenReActivateUserModal(user){
      this.setCurrentUser(user)
      this.openReActivateUserModal()
    },


    reactivateThisUser(){

      this.isLoading = true

      const formData = {
        user_id: this.currentUser.id
      }

      TemplateService.reActivateUser(formData)
        .then((response) => {

          this.reFetchAllForDataForViewAllUsers()
          this.closeReActivateUserModal()

          this.isLoading = false

          console.log(response.data)
          
        }).catch((err) => {

          this.isLoading = false

          console.log(err)
          
        });

    },



    fetchAGroup(uuid){


      this.inspectionGroupUsers = []
      this.usersNotInThisGroup = []

      TemplateService.getAGroup(uuid)
        .then((response) => {

          this.inspectionGroup = response.data.group
          this.inspectionGroupUsers = response.data.groupUsers
          this.usersNotInThisGroup = response.data.usersNotInThisGroup

          console.log(response.data)
          
        }).catch((err) => {

          console.log(err)
          
        });

    },


    openRemoveUserFromGroupModal(){
      this.showRemoveUserFromGroupModal = true
    },

    closeRemoveUserFromGroupModal(){
      this.showRemoveUserFromGroupModal = false
    },

    setCurrentUserAndOpenRemoveUserGroupModal(user){
      this.setCurrentUser(user)
      this.openRemoveUserFromGroupModal()
    },

    removeThisUserFromGroup(uuid){


      this.isLoading = true

      const formData = {
        user_id: this.currentUser.id,
        group_id: this.inspectionGroup.id
      }

      // console.log(formData)

      TemplateService.removeDeleteUserFromThisGroup(formData)
        .then((response) => {

          this.isLoading = false
          this.closeRemoveUserFromGroupModal()
          this.fetchAGroup(uuid)

          console.log(response.data)
          
        }).catch((err) => {

          this.isLoading = false
          console.log(err)
          
        });

    },


    openAddUsersToAGroupModal(){
      this.showAddUsersToAGroupModal = true
    },

    closeAddUsersToAGroupModal(){
      this.showAddUsersToAGroupModal = false
    },

    getAllUsersAndOpenAddUsersToAGroupModal(){

      this.usersToAGroup = []

      //open modal
      this.openAddUsersToAGroupModal()

    },

    addThisUserToAGroup(groupUser) {

      let theUser = groupUser.user;

      // Find the index of the user in the array
      const userIndex = this.usersToAGroup.findIndex(
        (user) => user.id === theUser.id
      );

      if (userIndex !== -1) {

        // User exists, remove them from the array
        this.usersToAGroup.splice(userIndex, 1);
        groupUser.is_checked = 0

        console.log("User already exists so remove from array");
      } else {

        // User does not exist, add them to the array
        this.usersToAGroup.push(theUser);
        groupUser.is_checked = 1
        console.log("Add user to array as the user does not exist in it");
      }


    },


    addTheseUsersToAGroup(){

      this.isLoading = true


      if(this.usersToAGroup.length === 0){
        alert('Select at user')
        return
      }


      const formData = {
        group_id: this.inspectionGroup.id,
        users: this.usersToAGroup
      }


      TemplateService.addUsersToAGroup(formData)
        .then((response) => {


          this.fetchAGroup(this.inspectionGroup.uuid)
          this.closeAddUsersToAGroupModal()

          toast("Users added to group!", {
            position: "bottom-right",
            autoClose: 1000,
          });

          this.isLoading = false

          console.log(response.data)
          
        }).catch((err) => {

          this.isLoading = false
          console.log(err)
          
          
        });

      console.log(formData)


    },




    fetchASiteDetails(uuid){ 

      // console.log('uuid', uuid)

      const formData = {
        uuid: uuid
      }

      TemplateService.fetchASiteDetailService(formData)
        .then((response) => {

          this.currentSite = response.data.site
          this.template_site_users = response.data.template_site_users
          this.usersNotInThisSite = response.data.usersNotInThisSite

          console.log('response.data', response.data)
          
        }).catch((err) => {

          console.log(err)

        });

    },


    openAddUsersToSiteModalInSiteDetails(){ 
      this.usersToAddToASite = []
      this.showAddUsersToSiteModalInSiteDetails = true
    },


    closeAddUsersToSiteModalInSiteDetails(){
      this.showAddUsersToSiteModalInSiteDetails = false
    },


    addThisUserToASite(siteUser) {

      console.log(siteUser)

      let theUser = siteUser.user;

      // Find the index of the user in the array
      const userIndex = this.usersToAddToASite.findIndex(
        (user) => user.id === theUser.id
      );

      if (userIndex !== -1) {

        // User exists, remove them from the array
        this.usersToAddToASite.splice(userIndex, 1);
        siteUser.is_checked = 0

        console.log("User already exists so remove from array");

      } else {

        // User does not exist, add them to the array
        this.usersToAddToASite.push(theUser);
        siteUser.is_checked = 1
        console.log("Add user to array as the user does not exist in it");
      }


    },



    addTheseUsersToSite(){ 


      this.isLoading = true


      if(this.usersToAddToASite.length === 0){
        alert('Select at user')
        return
      }


      const formData = {
        site_id: this.currentSite.id,
        users: this.usersToAddToASite
      }

      TemplateService.addUsersToASite(formData)
        .then((response) => {

          this.fetchASiteDetails(this.currentSite.uuid)
          this.closeAddUsersToSiteModalInSiteDetails()
          this.isLoading = false


          console.log(response.data)
          
        }).catch((err) => {

          this.isLoading = false

          console.log(err)
          
        });






    },



    openRemoveUserInSiteDetails(){
      this.showRemoveUserInSiteDetails = true
    },

    closeRemoveUserInSiteDetails(){
      this.showRemoveUserInSiteDetails = false
    },

    setUserAndOpenRemoveUserInSiteDetails(user){
      this.currentUser = user
      this.openRemoveUserInSiteDetails()

    },

    removeThisUserFromSite(){

      this.isLoading = true

      const formData = {
        user_id: this.currentUser.id,
        site_id: this.currentSite.id
      }


      TemplateService.removeUserFromASiteService(formData)
        .then((response) => {

          this.isLoading = false
          this.fetchASiteDetails(this.currentSite.uuid)
          this.closeRemoveUserInSiteDetails()

          console.log(response.data)
          
        }).catch((err) => {

          this.isLoading = false

          console.log(err)
          
        });

      

 

    },




    fetchAInspectionUserDetail(template_inspection_normal_user_uuid){

      const formData = {
        template_inspection_normal_user_uuid: template_inspection_normal_user_uuid
      }

      TemplateService.fetchAUserDetailService(formData)
        .then((response) => {


          this.the_user = response.data.user
          this.the_template_inspection_normal_user = response.data.template_inspection_normal_user
          this.the_groups = response.data.groups
          this.the_sites = response.data.sites

          console.log(response.data)
          
        }).catch((err) => {

          console.log(err)
          
        });

    },



    firstNameAndLastNameLetters(){

      let fname = this.the_user.fname
      let lname = this.the_user.lname

        // Get first letter of fname, or empty string if fname is undefined/null
      const firstInitial = fname ? fname.charAt(0).toUpperCase() : ''
      
      // Get first letter of lname, or empty string if lname is undefined/null
      const lastInitial = lname ? lname.charAt(0).toUpperCase() : ''
      
      // Combine the initials
      return firstInitial + lastInitial

    },

    theUserRoleType(){

      let role_type = this.the_template_inspection_normal_user.role_type

      let role_name = ''

      if(role_type === 'inspector'){

        role_name = 'Inspector'

      }

      if(role_type === 'sub_admin'){

        role_name = 'Sub Admin'

      }

      return role_name;

    },


    goToAGroup(uuid){

      this.router.push({
        name: "ViewAlnspectionTeam",
        params: { uuid: uuid },
      });

    },

    goToASite(uuid){

      this.router.push({
        name: "ViewASite",
        params: { uuid: uuid },
      });

    },


    openSelectGroupsModal(){
      this.showSelectGroupsModal = true
    },


    closeSelectGroupsModal(){
      this.showSelectGroupsModal = false
    },



    addGroupToAction(group){

    
      if (group.is_checked) {
        
        //remove from array
        group.is_checked = 0;

        this.groupsAssignedAction.forEach((grp, index) => {

          if (grp.id === group.id) {
              this.groupsAssignedAction.splice(index, 1);
          }
      });



      } else {
        //add to array
        group.is_checked = 1;


        this.groupsAssignedAction.push({
          id: group.id,
          name: group.name
        });


      }


    },
  
    
    openSelectUsersModal(){
      this.showSelectUsersModal = true
    },

    closeSelectUsersModal(){
      this.showSelectUsersModal = false
    },

    addUserToAction(inspectionUser){

    
      if (inspectionUser.is_checked) {
        
        //remove from array
        inspectionUser.is_checked = 0;

        this.usersAssignedAction.forEach((insUser, index) => {

          if (insUser.id === inspectionUser.id) {
              this.usersAssignedAction.splice(index, 1);
          }
      });


      } else {
        //add to array
        inspectionUser.is_checked = 1;


        this.usersAssignedAction.push({
          id: inspectionUser.id,
          name: inspectionUser.user.name
        });


      }


    },



    openSelectLabelsModal(){
      this.showSelectLabelsModal = true
    },

    closeSelectLabelsModal(){
      this.showSelectLabelsModal = false
    }, 


    addLabelToAction(label){

    
      if (label.is_checked) {
        
        //remove from array
        label.is_checked = 0;

        this.labelsAssignedToAction.forEach((lab, index) => {

          if (lab.id === label.id) {
              this.labelsAssignedToAction.splice(index, 1);
          }
      });


      } else {
        //add to array
        label.is_checked = 1;


        this.labelsAssignedToAction.push({
          id: label.id,
          name: label.name
        });


      }


    },


    openSelectRepeatOptionsModal(){

      this.showSelectRepeatOptionsModal = true

    },

    closeSelectRepeatOptionsModal(){
      this.showSelectRepeatOptionsModal = false
    }, 

    doesRequiredActionFormDataFieldsHasError() {

      if (this.newActionFormData.title == '') {
        alert('Title is required for the action');
        return true;
      }
      
      if (this.newActionFormData.due_date == '' || this.newActionFormData.due_time == '') {
        alert('Please select a due date and time');
        return true;
      }
      
      if (this.newActionFormData.assignees.groups.length == 0 && 
          this.newActionFormData.assignees.users.length == 0) {
        alert('Please select at least a group or a user for this action to be assigned.');
        return true;
      }
      
      return false;
    },


    chooseRepeatOption(recurringOption){

      if(recurringOption.type === 'custom'){

        this.openCustomRepeatModal()

      }


      if(recurringOption.type !== 'custom'){

        //reset all options is_checked to 0
        this.recurringOptions.forEach((repeat) => {
          repeat.is_checked = 0
        });

        //populate the newly selected option
        this.selectedRepeatOption.type = recurringOption.type

        //set is_check to 1
        recurringOption.is_checked = 1

      }

    
      

    },

    resetRepeatOption(){

      //reset all options is_checked to 0
      this.recurringOptions.forEach((repeat) => {

        if(repeat.type === 'does_not_repeat'){
          repeat.is_checked = 1
        }else{
          repeat.is_checked = 0
        }
        
      });

    },

    populateDueDateAndTimeCurrentTime() {

      // get today's date and current time
      const now = moment();
        
      // add 7 days while preserving current time
      const dueDate = now.add(7, 'days');
        
      // format the date and time
      const formattedDate = dueDate.format('MMM Do YYYY [at] h:mm A');
      this.selectedRepeatOption.readable_format = formattedDate
      this.selectedRepeatOption.day_name = dueDate.format('dddd');
      this.selectedRepeatOption.the_date = dueDate.format('MMMM Do');
      


      // populate HTML date input (YYYY-MM-DD format)
      this.newActionFormData.due_date = dueDate.format('YYYY-MM-DD');
      
      // populate HTML time input (HH:mm format)
      this.newActionFormData.due_time = dueDate.format('HH:mm');

        
      console.log(formattedDate);

    },


    onChangeRePopulateDueDateAndTimeFields() {

        // Combine the date and time strings and create a moment object
        const combinedDateTime = moment(
            `${this.newActionFormData.due_date} ${this.newActionFormData.due_time}`, 
            'YYYY-MM-DD HH:mm'
        );
        
        // format the date and time for display
        const formattedDate = combinedDateTime.format('MMM Do YYYY [at] h:mm A');
        this.selectedRepeatOption.readable_format = formattedDate
        this.selectedRepeatOption.day_name = combinedDateTime.format('dddd'); 
        this.selectedRepeatOption.the_date = combinedDateTime.format('MMMM Do'); 
        
        console.log(formattedDate);

    },


    openCustomRepeatModal(){
      this.showCustomRepeatModal = true
    },

    closeCustomRepeatModal(){
      this.showCustomRepeatModal = false
    }, 

    setSelectedCustomRepeatOption(){

      //check custom modes

      if(this.customRepeatFormData.custom_option_selected_mode === 'day_interval_with_never_end'){

            
        if(this.customRepeatFormData.repeat_amt == ''){

          alert('Please enter a repeat amount')
          return;
        }


        

      }

      if(this.customRepeatFormData.custom_option_selected_mode === 'day_interval_with_end_date'){

            
        if(this.customRepeatFormData.ends_date === ''){

          alert('Please select an end date')
          return;

        }


        

      }


      if(this.customRepeatFormData.custom_option_selected_mode === 'week_interval_with_never_end'){

            
        if(this.customRepeatFormData.repeat_amt == ''){

          alert('Please enter a repeat amount')
          return;
        }
        

      }

      if(this.customRepeatFormData.custom_option_selected_mode === 'week_interval_with_end_date'){

            
        if(this.customRepeatFormData.ends_date == ''){

          alert('Please enter end date')
          return;
        }
        

      }


      
      if(this.customRepeatFormData.custom_option_selected_mode === 'month_interval_with_never_end'){

            
        if(this.customRepeatFormData.repeat_amt == ''){

          alert('Please enter a repeat amount')
          return;
        }
        

      }

      if(this.customRepeatFormData.custom_option_selected_mode === 'month_interval_with_end_date'){

            
        if(this.customRepeatFormData.ends_date == ''){

          alert('Please enter end date')
          return;
        }
        

      }



      if(this.customRepeatFormData.custom_option_selected_mode === 'annual_interval_with_never_end'){

            
        if(this.customRepeatFormData.repeat_amt == ''){

          alert('Please enter a repeat amount')
          return;
        }
        

      }

      if(this.customRepeatFormData.custom_option_selected_mode === 'annual_interval_with_end_date'){

            
        if(this.customRepeatFormData.ends_date === ''){

          alert('Please select end date')
          return;

        }


        

      }






      this.selectedRepeatOption.type = 'custom'

      this.recurringOptions.forEach((repeat) => {

        if(repeat.type === 'custom'){
          repeat.is_checked = 1
        }else{
          repeat.is_checked = 0
        }
        
      });


      this.generateReadableFormatForCustomRepeatOption()
      this.closeCustomRepeatModal()
      this.closeSelectRepeatOptionsModal()
      

    },

    setModeForDayIntervalWithNeverEnd(){
      this.customRepeatFormData.custom_option_selected_mode = 'day_interval_with_never_end'
    },

    setModeForDayIntervalWithEndDate(){
      this.customRepeatFormData.custom_option_selected_mode = 'day_interval_with_end_date'
    },

    setModeForYearIntervalWithNeverEnd(){
      this.customRepeatFormData.custom_option_selected_mode = 'annual_interval_with_never_end'
    },

    setModeForYearIntervalWithEndDate(){
      this.customRepeatFormData.custom_option_selected_mode = 'annual_interval_with_end_date'
    },

    setModeForWeekIntervalWithNeverEnd(){
      this.customRepeatFormData.custom_option_selected_mode = 'week_interval_with_never_end'
    },

    setModeForWeekIntervalWithEndDate(){
      this.customRepeatFormData.custom_option_selected_mode = 'week_interval_with_end_date'
    },

    setModeForMonthIntervalWithNeverEnd(){
      this.customRepeatFormData.custom_option_selected_mode = 'month_interval_with_never_end'
    },

    setModeForMonthIntervalWithEndDate(){
      this.customRepeatFormData.custom_option_selected_mode = 'month_interval_with_end_date'
    },

    setMonthOption(string){
      this.customRepeatFormData.selected_month_option = string
    },

    onChangeSetMode(){


      if(this.customRepeatFormData.repeat_frequency === 'day'){

        this.customRepeatFormData.custom_option_selected_mode = 'day_interval_with_never_end'

      }

      if(this.customRepeatFormData.repeat_frequency === 'week'){

        this.customRepeatFormData.custom_option_selected_mode = 'week_interval_with_never_end'

      }

      if(this.customRepeatFormData.repeat_frequency === 'month'){

        this.customRepeatFormData.custom_option_selected_mode = 'month_interval_with_never_end'
        this.customRepeatFormData.selected_month_option = 'day_number_in_month_option'

      }

      if(this.customRepeatFormData.repeat_frequency === 'year'){

        this.customRepeatFormData.custom_option_selected_mode = 'annual_interval_with_never_end'

      }
     

    },


    addSelectedDay(day){



      if (day.is_checked) {
        
        //remove from array
        day.is_checked = 0;

        this.customRepeatFormData.selected_days_of_week.forEach((dy, index) => {

          if (dy.id === day.id) {
              this.customRepeatFormData.selected_days_of_week.splice(index, 1);
          }
      });



      } else {
        //add to array
        day.is_checked = 1;


        this.customRepeatFormData.selected_days_of_week.push({
          id: day.id,
          is_checked: day.is_checked,
          full_name: day.full_name
        });


      }

    },


    generateReadableFormatForCustomRepeatOption(){

      if(this.customRepeatFormData.custom_option_selected_mode === 'day_interval_with_never_end'){

        this.customRepeatFormData.readable_format = "Every " + this.customRepeatFormData.repeat_amt + " days"

      }

      if(this.customRepeatFormData.custom_option_selected_mode === 'day_interval_with_end_date'){

        this.customRepeatFormData.readable_format = "Every " + this.customRepeatFormData.repeat_amt + " days until " + 
              this.formatDate(this.customRepeatFormData.ends_date)

      }



      if(this.customRepeatFormData.custom_option_selected_mode === 'week_interval_with_never_end'){


        // Extract day names from selected days
        const dayNames = this.customRepeatFormData.selected_days_of_week.map(day => day.full_name);

        // Format days with appropriate commas and "and"
        let daysFormatted = "";
        if(dayNames.length === 1){
            daysFormatted = " " + dayNames[0];
        } else if(dayNames.length === 2){
            daysFormatted = " " + dayNames[0] + " and " + dayNames[1];
        } else {
            // For 3+ days, use commas and "and" before the last item
            const allButLast = dayNames.slice(0, -1).join(", ");
            const lastDay = dayNames[dayNames.length - 1];
            daysFormatted = " " + allButLast + ", and " + lastDay;
        }
        


        if(this.customRepeatFormData.selected_days_of_week.length > 0){

          this.customRepeatFormData.readable_format = "Every " + this.customRepeatFormData.repeat_amt + " Weeks on " + daysFormatted

        }else{

          this.customRepeatFormData.readable_format = "Every " + this.customRepeatFormData.repeat_amt + " Week"

        }


      
      }

      if(this.customRepeatFormData.custom_option_selected_mode === 'week_interval_with_end_date'){

         // Extract day names from selected days
         const dayNames = this.customRepeatFormData.selected_days_of_week.map(day => day.full_name);

         // Format days with appropriate commas and "and"
         let daysFormatted = "";
         if(dayNames.length === 1){
             daysFormatted = " " + dayNames[0];
         } else if(dayNames.length === 2){
             daysFormatted = " " + dayNames[0] + " and " + dayNames[1];
         } else {
             // For 3+ days, use commas and "and" before the last item
             const allButLast = dayNames.slice(0, -1).join(", ");
             const lastDay = dayNames[dayNames.length - 1];
             daysFormatted = " " + allButLast + ", and " + lastDay;
         }
         
 
 
         if(this.customRepeatFormData.selected_days_of_week.length > 0){
 
           this.customRepeatFormData.readable_format = "Every " + this.customRepeatFormData.repeat_amt + " Weeks on " + daysFormatted + "until "  + 
           this.formatDate(this.customRepeatFormData.ends_date)
 
         }else{
 
           this.customRepeatFormData.readable_format = "Every " + this.customRepeatFormData.repeat_amt + " Week"  + " until " +
                 this.formatDate(this.customRepeatFormData.ends_date)
 
         }

   
      
      }


      if(this.customRepeatFormData.custom_option_selected_mode === 'month_interval_with_never_end'){

        if(this.customRepeatFormData.selected_month_option === 'day_number_in_month_option'){

          this.customRepeatFormData.readable_format = "Every " + this.customRepeatFormData.repeat_amt + " month on day " + this.customRepeatFormData.selected_day_number 

        }

        if(this.customRepeatFormData.selected_month_option === 'day_occurence_in_month_option'){

          this.customRepeatFormData.readable_format = "Every " + this.customRepeatFormData.repeat_amt + " month on the " +
            this.customRepeatFormData.selected_occurence_in_month + " " + this.customRepeatFormData.selected_day_type_in_month

        }

        
      
      }

      if(this.customRepeatFormData.custom_option_selected_mode === 'month_interval_with_end_date'){

        if(this.customRepeatFormData.selected_month_option === 'day_number_in_month_option'){

          this.customRepeatFormData.readable_format = "Every " + this.customRepeatFormData.repeat_amt + " month on day " + this.customRepeatFormData.selected_day_number +
              " until " + this.formatDate(this.customRepeatFormData.ends_date)

        }

        if(this.customRepeatFormData.selected_month_option === 'day_occurence_in_month_option'){

          this.customRepeatFormData.readable_format = "Every " + this.customRepeatFormData.repeat_amt + " month on the " +
            this.customRepeatFormData.selected_occurence_in_month + " " + this.customRepeatFormData.selected_day_type_in_month + " until " + 
            this.formatDate(this.customRepeatFormData.ends_date)

        }
      
      }

      
      if(this.customRepeatFormData.custom_option_selected_mode === 'annual_interval_with_never_end'){

        this.customRepeatFormData.readable_format = "Every " + this.customRepeatFormData.repeat_amt + " Year"
      
      }



        if(this.customRepeatFormData.custom_option_selected_mode === 'annual_interval_with_end_date'){

          this.customRepeatFormData.readable_format = "Every " + this.customRepeatFormData.repeat_amt + " Year until " + 
                this.formatDate(this.customRepeatFormData.ends_date)
  
        }


        



    },

    createNewAction(){

      this.isLoading = true

      //populate the newActionFormData
      this.newActionFormData.assignees.groups = this.groupsAssignedAction
      this.newActionFormData.assignees.users = this.usersAssignedAction
      this.newActionFormData.labels = this.labelsAssignedToAction
      this.newActionFormData.repeat_type = this.selectedRepeatOption.type
      this.newActionFormData.repeat_type_data = this.customRepeatFormData

      //only for weekly_on_day_name and monthly_on_first_day_name
      if(this.newActionFormData.repeat_type === 'weekly_on_day_name' || this.newActionFormData.repeat_type === 'monthly_on_first_day_name'){

        this.newActionFormData.other_data = this.selectedRepeatOption

      }

      

      if (this.doesRequiredActionFormDataFieldsHasError()) {
        this.isLoading = false
        return; //stop here if error exists
      }


      const formData = {
        new_action: this.newActionFormData
      }

      console.log('new action formdata sent: ', formData)

      TemplateService.storeNewAction(formData)
        .then((response) => {

        

          this.closeNewActionModal()
          this.fetchAllActions()
          this.resetNewActionForm()

          toast("Action created!", {
            position: "bottom-right",
            autoClose: 1000,
          });


          console.log('response from backend: ', response.data)

          this.isLoading = false
          
        }).catch((err) => {

          this.isLoading = false

          console.log(err)
          
        });


   
      // this.isLoading = false
    

    },


    resetNewActionForm(){

      this.newActionFormData.title = ''
      this.newActionFormData.desc = ''
      this.newActionFormData.priority_type = 'low_priority'
      this.newActionFormData.due_date = ''
      this.newActionFormData.due_time = ''
      this.newActionFormData.assignees.users = []
      this.newActionFormData.assignees.groups = []
      this.newActionFormData.repeat_type = 'does_not_repeat'
      this.newActionFormData.repeat_type_data = null
      this.newActionFormData.site_id = null
      this.newActionFormData.asset_id = null
      this.newActionFormData.labels = []
      this.newActionFormData.other_data = null

      this.groupsAssignedAction = []
      this.usersAssignedAction = []

      this.selectedRepeatOption.type = 'does_not_repeat'
      this.selectedRepeatOption.readable_format = ''
      this.selectedRepeatOption.day_name = ''
      this.selectedRepeatOption.the_date = ''
      


    },


    setCurrentAction(action){

      this.currentAction = action

      this.selectedRepeatOption.type = action.repeat_type

      console.log('currentAction: ', this.currentAction)
    },

    openEditDetailsModal(action){

      this.setCurrentAction(action)
      this.showEditDetailsModal = true

    },

    closesEditDetailsModal(){
      this.showEditDetailsModal = false
      this.currentAction = null
      this.selectedRepeatOption.type = 'does_not_repeat'
    }, 


    setEditActionTab(tab){
      this.selectedCurrentActionTabType = tab.type
    },


    openActionUploadMediaModal(){
      this.showActionUploadMediaModal = true
    },

    closeActionUploadMediaModal(){
      this.showActionUploadMediaModal = false
    }, 


    formatDateAndTime(date){

      return moment(date).format("Do MMM YYYY h:mm A");


    }


    
      



















  },
});
