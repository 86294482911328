<template>
    <div>
        <div class="mainWrapper">
            <MainSideBar />

            <div class="rightContentWrapper">
                <div class="topNav">
                    <TopBarVue />
                </div>

                <div class="innerContainer">

                    <div class="theHeaderWrapper">
                        <div>Actions</div>

                        <div>

                            <button @click="templateStore.goToActionSettings" class="settingsBtn" role="button">
                                <i class="bi bi-gear"></i> Settings
                            </button>

                            <button @click="templateStore.openNewActionModal" class="button-6" role="button">
                                New Action +
                            </button>

                        </div>


                    </div>


                    <div class="searchBarWrapper">
                        <input type="text" class="form-control theBar" placeholder="Search actions..." />
                    </div>



                    <div class="actionsList">

                        <div v-for="(action, index) in templateStore.actions" :key="index" class="actionItem"
                            @click="templateStore.openEditDetailsModal(action)">

                            <div class="topSection">

                                <div class="checkArea">

                                    <input class="myCheckbox" type="checkbox">

                                </div>

                                <div class="actionNameArea">

                                    <div style="margin-right: 10px;">
                                        <b>Action</b>
                                    </div>

                                    <div style=" font-size: 15px;">
                                        <span class="badge rounded-pill bg-danger">To do</span>
                                    </div>

                                    <div style=" font-size: 13px;">

                                    </div>

                                </div>


                            </div>

                            <div class="middleSection">

                                <div style="font-size: 16px; ">
                                    {{ action.title }}
                                </div>

                            </div>

                            <div class="bottomSection">

                                <div class="bottomLeft">



                                    <div>
                                        <span v-if="action.priority === 'low_priority'" style="color: blue;"><b>Low
                                                Priority</b></span>
                                        <span v-if="action.priority === 'medium_priority'" style="color: red;"><b>Medium
                                                Priority</b></span>
                                        <span v-if="action.priority === 'high_priority'" style="color: red;"><b>High
                                                Priority</b></span>
                                    </div>

                                    <div>
                                        <i class="bi bi-calendar3"></i> Due {{ templateStore.formatDate(action.due_date)
                                        }}
                                    </div>




                                    <div class="theAssignedBox" v-if="action.groups">

                                        <div v-if="action.groups.length === 1">
                                            {{ action.groups.length }} group
                                        </div>

                                        <div v-else>
                                            {{ action.groups.length }} groups
                                        </div>




                                    </div>

                                    <div class="theAssignedBox" v-if="action.users">

                                        <div v-if="action.users.length === 1">
                                            {{ action.users.length }} user
                                        </div>

                                        <div v-else>
                                            {{ action.users.length }} users
                                        </div>




                                    </div>





                                    <div>
                                        <i class="bi bi-geo-alt-fill"></i> Miami
                                    </div>

                                </div>

                                <div class="bottomRight">

                                    <div>
                                        Updated 2 mins ago
                                    </div>


                                </div>

                            </div>

                        </div>


                    </div>




                </div>
            </div>
        </div>



        <div v-if="templateStore.showNewActionModal" class="modal-overlay">

            <div class="modal-box" style="width: 550px">

                <div class="model-box-header">
                    <div style="font-size: 17px">
                        <b>New Action</b>
                    </div>

                    <div>
                        <i @click="templateStore.closeNewActionModal" class="bi bi-x-lg"
                            style="font-size: 20px; cursor: pointer"></i>
                    </div>
                </div>

                <div class="modal-content"
                    style="text-align: left; max-height: 600px; overflow-y: auto; padding: 20px;">


                    <form @submit.prevent="templateStore.createNewAction">

                        <div class="mb-3">
                            <label class="form-label"><b>Title</b></label>
                            <input v-model="templateStore.newActionFormData.title" type="text"
                                class="form-control theInputStyle">
                        </div>

                        <div class="mb-3">

                            <div class="form-floating">
                                <textarea v-model="templateStore.newActionFormData.desc"
                                    class="form-control theTextareaStyle"></textarea>
                                <label>Add description</label>
                            </div>

                        </div>


                        <div class="mb-3">

                            <label class="form-label"><b>Priority</b></label>

                            <select v-model="templateStore.newActionFormData.priority_type" class="form-select">


                                <option v-for="(priority, index) in templateStore.priorityLevels" :key="index"
                                    :value="priority.type">
                                    {{ priority.name }}
                                </option>

                            </select>

                        </div>

                        <div class="mb-3">

                            <label class="form-label"><b>Due Date:</b>
                                <div style="font-size: 12px;">{{ templateStore.selectedRepeatOption.readable_format }}
                                </div>
                            </label>

                            <input v-model="templateStore.newActionFormData.due_date" type="date"
                                class="form-control theInputStyle" style="margin-bottom: 5px;"
                                @change="templateStore.onChangeRePopulateDueDateAndTimeFields">

                            <input v-model="templateStore.newActionFormData.due_time" type="time"
                                class="form-control theInputStyle"
                                @change="templateStore.onChangeRePopulateDueDateAndTimeFields">


                        </div>

                        <div class="mb-3">

                            <label class="form-label"><b>Assignees</b></label>

                            <div class="assignessBox">

                                <div @click="templateStore.openSelectGroupsModal" class="theGroupsBox">

                                    <div class="theUserHead">

                                        <b>Groups</b>

                                    </div>

                                    <div class="theUsersListCont">


                                        <div v-if="templateStore.groupsAssignedAction.length > 0">

                                            <div v-for="(selectedGroup, index) in templateStore.groupsAssignedAction"
                                                :key="index" class="theUserPill">
                                                {{ selectedGroup.name }}
                                            </div>

                                        </div>

                                        <div v-else style="padding: 10px; font-size: 13px;">
                                            No groups selected
                                        </div>


                                    </div>


                                </div>

                                <div @click="templateStore.openSelectUsersModal" class="theUsersBox">

                                    <div class="theUserHead">

                                        <b>Users</b>

                                    </div>

                                    <div class="theUsersListCont">

                                        <div v-if="templateStore.usersAssignedAction.length > 0">

                                            <div v-for="(selectedUser, index) in templateStore.usersAssignedAction"
                                                :key="index" class="theUserPill">
                                                {{ selectedUser.name }}
                                            </div>

                                        </div>

                                        <div v-else style="padding: 10px; font-size: 13px;">
                                            No users selected
                                        </div>


                                    </div>


                                </div>




                            </div>




                        </div>


                        <div class="mb-3">

                            <label class="form-label">Repeats</label>


                            <div @click="templateStore.openSelectRepeatOptionsModal" class="selectSupBox">



                                <div v-if="templateStore.selectedRepeatOption">

                                    <span v-if="templateStore.selectedRepeatOption.type === 'does_not_repeat'"
                                        class="badge rounded-pill theSupPill">
                                        <i class="bi bi-calendar-check theRepeatPill"></i>
                                        Does not repeat
                                    </span>

                                    <span v-if="templateStore.selectedRepeatOption.type === 'daily'"
                                        class="badge rounded-pill theSupPill">
                                        <i class="bi bi-calendar-check theRepeatPill"></i>
                                        Daily
                                    </span>

                                    <span v-if="templateStore.selectedRepeatOption.type === 'weekly_on_day_name'"
                                        class="badge rounded-pill theSupPill">
                                        <i class="bi bi-calendar-check theRepeatPill"></i>
                                        Weekly on {{ templateStore.selectedRepeatOption.day_name }}
                                    </span>

                                    <span v-if="templateStore.selectedRepeatOption.type === 'monthly_on_first_day_name'"
                                        class="badge rounded-pill theSupPill">
                                        <i class="bi bi-calendar-check theRepeatPill"></i>
                                        Monthly on the first {{ templateStore.selectedRepeatOption.day_name }}
                                    </span>

                                    <span v-if="templateStore.selectedRepeatOption.type === 'annually_this_date'"
                                        class="badge rounded-pill theSupPill">
                                        <i class="bi bi-calendar-check theRepeatPill"></i>
                                        Annually on {{ templateStore.selectedRepeatOption.the_date }}
                                    </span>

                                    <span v-if="templateStore.selectedRepeatOption.type === 'every_weekday_mon_to_fri'"
                                        class="badge rounded-pill theSupPill">
                                        <i class="bi bi-calendar-check theRepeatPill"></i>
                                        Every weekday (Monday - Friday)
                                    </span>


                                    <span v-if="templateStore.selectedRepeatOption.type === 'custom'"
                                        class="badge rounded-pill theSupPill">
                                        <i class="bi bi-calendar-check theRepeatPill"></i>


                                        {{ templateStore.customRepeatFormData.readable_format }}

                                    </span>

                                </div>



                            </div>




                        </div>



                        <div class="mb-3">

                            <label class="form-label">Site</label>

                            <select v-model="templateStore.newActionFormData.site_id" class="form-select">


                                <option v-for="(site, index) in templateStore.sites" :key="index" :value="site.id">
                                    {{ site.name }}
                                </option>

                            </select>

                        </div>


                        <div class="mb-3">

                            <label class="form-label">Asset</label>

                            <select v-model="templateStore.newActionFormData.asset_id" class="form-select">


                                <option v-for="(asset, index) in templateStore.assets" :key="index" :value="asset.id">
                                    {{ asset.name }}
                                </option>

                            </select>

                        </div>

                        <div class="mb-3">

                            <label class="form-label">Labels</label>


                            <div @click="templateStore.openSelectLabelsModal" class="selectSupBox">


                                <div v-if="templateStore.labelsAssignedToAction.length > 0">

                                    <span v-for="(selectedLabel, index) in templateStore.labelsAssignedToAction"
                                        :key="index" class="badge rounded-pill theSupPill">
                                        <i class="bi bi-check-lg"></i>
                                        {{ selectedLabel.name }}
                                    </span>

                                </div>

                                <div v-else style="padding: 10px; font-size: 13px;">

                                    No labels added

                                </div>



                            </div>



                            <!-- <select class="form-select">

                                <option selected value="">Add labels</option>

                                <option v-for="(label, index) in templateStore.labels" :key="index"
                                    :value="label.id">
                                    {{ label.name }}
                                </option>

                            </select>  -->

                        </div>


                        <button v-if="templateStore.isLoading" class="btn btn-dark"
                            style="width: 100%; margin-top: 20px;"><b>Creating...</b></button>

                        <button v-else type="submit" class="btn btn-dark"
                            style="width: 100%; margin-top: 20px;"><b>Create</b></button>


                    </form>






                </div>
            </div>
        </div>


        <div v-if="templateStore.showSelectGroupsModal" class="modal-overlay" style="background-color: transparent;">

            <div class="modal-box" style="width: 700px">

                <div class="model-box-header">
                    <div style="font-size: 17px">
                        <b>Select Groups</b>
                    </div>

                    <div>
                        <i @click="templateStore.closeSelectGroupsModal" class="bi bi-x-lg"
                            style="font-size: 20px; cursor: pointer"></i>
                    </div>
                </div>


                <div v-if="templateStore.allGroups.length > 0">

                    <div class="modal-search-box" style="padding: 12px;">

                        <input v-model="searchGroupName" type="text" class="form-control titleInput"
                            placeholder="Search..." />

                    </div>

                    <div class="modal-content">



                        <div class="innerBox">


                            <div class="talkItem" v-for="(group, index) in filteredGroupNames" :key="index"
                                @click="templateStore.addGroupToAction(group)">



                                <div class="talkLeft">

                                    <div class="talkTitle">
                                        {{ group.name }}
                                    </div>




                                </div>

                                <div class="talkRight">





                                    <div v-if="group.is_checked" class="form-check form-switch">
                                        <input class="form-check-input theInputSwitch" type="checkbox"
                                            id="flexSwitchCheckDefault" checked>
                                    </div>

                                    <div v-else class="form-check form-switch">
                                        <input class="form-check-input theInputSwitch" type="checkbox"
                                            id="flexSwitchCheckDefault">
                                    </div>



                                </div>




                            </div>







                        </div>









                    </div>

                </div>

                <div v-else>
                    <b>No groups created yet.</b>
                </div>



            </div>

        </div>


        <div v-if="templateStore.showSelectUsersModal" class="modal-overlay" style="background-color: transparent;">

            <div class="modal-box" style="width: 700px">

                <div class="model-box-header">
                    <div style="font-size: 17px">
                        <b>Select Users</b>
                    </div>

                    <div>
                        <i @click="templateStore.closeSelectUsersModal" class="bi bi-x-lg"
                            style="font-size: 20px; cursor: pointer"></i>
                    </div>
                </div>


                <div v-if="templateStore.allInspectionUsers.length > 0">

                    <div class="modal-search-box" style="padding: 12px;">

                        <input v-model="searchUserName" type="text" class="form-control titleInput"
                            placeholder="Search..." />

                    </div>

                    <div class="modal-content">

                        <!-- 
                        {{ templateStore.usersAssignedAction }} -->


                        <div class="innerBox">


                            <div class="talkItem" v-for="(inspectionUser, index) in filteredUsers" :key="index"
                                @click="templateStore.addUserToAction(inspectionUser)">



                                <div class="talkLeft">

                                    <div class="talkTitle">

                                        <div v-if="inspectionUser.user">

                                            {{ inspectionUser.user.name }}

                                        </div>

                                    </div>




                                </div>

                                <div class="talkRight">





                                    <div v-if="inspectionUser.is_checked" class="form-check form-switch">
                                        <input class="form-check-input theInputSwitch" type="checkbox"
                                            id="flexSwitchCheckDefault" checked>
                                    </div>

                                    <div v-else class="form-check form-switch">
                                        <input class="form-check-input theInputSwitch" type="checkbox"
                                            id="flexSwitchCheckDefault">
                                    </div>



                                </div>




                            </div>







                        </div>









                    </div>

                </div>

                <div v-else>
                    <b>No users added yet.</b>
                </div>



            </div>

        </div>

        <div v-if="templateStore.showSelectLabelsModal" class="modal-overlay" style="background-color: transparent;">

            <div class="modal-box" style="width: 700px">

                <div class="model-box-header">
                    <div style="font-size: 17px">
                        <b>Select Labels</b>
                    </div>

                    <div>
                        <i @click="templateStore.closeSelectLabelsModal" class="bi bi-x-lg"
                            style="font-size: 20px; cursor: pointer"></i>
                    </div>
                </div>


                <div v-if="templateStore.labels.length > 0">

                    <div class="modal-search-box" style="padding: 12px;">

                        <input v-model="searchLabels" type="text" class="form-control titleInput"
                            placeholder="Search..." />

                    </div>

                    <div class="modal-content">




                        <div class="innerBox">


                            <div class="talkItem" v-for="(label, index) in filteredLabels" :key="index"
                                @click="templateStore.addLabelToAction(label)">



                                <div class="talkLeft">

                                    <div class="talkTitle">

                                        {{ label.name }}

                                    </div>




                                </div>

                                <div class="talkRight">





                                    <div v-if="label.is_checked" class="form-check form-switch">
                                        <input class="form-check-input theInputSwitch" type="checkbox"
                                            id="flexSwitchCheckDefault" checked>
                                    </div>

                                    <div v-else class="form-check form-switch">
                                        <input class="form-check-input theInputSwitch" type="checkbox"
                                            id="flexSwitchCheckDefault">
                                    </div>



                                </div>




                            </div>







                        </div>









                    </div>

                </div>

                <div v-else>
                    <b>No labels added yet.</b>
                </div>



            </div>

        </div>

        <div v-if="templateStore.showSelectRepeatOptionsModal" class="modal-overlay"
            style="background-color: rgba(0, 0, 0, 0.4);">

            <div class="modal-box" style="width: 700px;">

                <div class="model-box-header">
                    <div style="font-size: 17px">
                        <b>Reccurring Options</b>
                    </div>

                    <div>
                        <i @click="templateStore.closeSelectRepeatOptionsModal" class="bi bi-x-lg"
                            style="font-size: 20px; cursor: pointer"></i>
                    </div>
                </div>


                <!-- <p style="font-size: 11px;">{{ templateStore.recurringOptions }}</p> -->

                <!-- <br> -->

                <!-- <p style="font-size: 11px;">{{ templateStore.selectedRepeatOption }}</p> -->


                <div class="modal-content">




                    <div class="innerBox">


                        <div class="talkItem" v-for="(recurringOption, index) in templateStore.recurringOptions"
                            @click="templateStore.chooseRepeatOption(recurringOption)" :key="index">



                            <div class="talkLeft">




                                <div class="talkTitle" v-if="templateStore.selectedRepeatOption">

                                    {{ recurringOption.name }}


                                    <span v-if="recurringOption.type === 'weekly_on_day_name'">

                                        {{ templateStore.selectedRepeatOption.day_name }}

                                    </span>

                                    <span v-if="recurringOption.type === 'monthly_on_first_day_name'">

                                        {{ templateStore.selectedRepeatOption.day_name }}

                                    </span>

                                    <span v-if="recurringOption.type === 'annually_this_date'">

                                        {{ templateStore.selectedRepeatOption.the_date }}

                                    </span>



                                </div>




                            </div>

                            <div class="talkRight">





                                <div v-if="recurringOption.is_checked" class="form-check form-switch">
                                    <input class="form-check-input theInputSwitch" type="checkbox"
                                        id="flexSwitchCheckDefault" checked>
                                </div>

                                <div v-else class="form-check form-switch">
                                    <input class="form-check-input theInputSwitch" type="checkbox"
                                        id="flexSwitchCheckDefault">
                                </div>



                            </div>




                        </div>







                    </div>









                </div>





            </div>

        </div>

        <div v-if="templateStore.showCustomRepeatModal" class="modal-overlay"
            style="background-color: rgba(0, 0, 0, 0.4);">

            <div class="modal-box" style="width: 500px;">

                <div class="model-box-header">
                    <div style="font-size: 17px">
                        <b>Custom Options</b>
                    </div>

                    <div>
                        <i @click="templateStore.closeCustomRepeatModal" class="bi bi-x-lg"
                            style="font-size: 20px; cursor: pointer"></i>
                    </div>
                </div>

                <p style="font-size: 12px;"><b>repeat_frequency</b> {{
                                templateStore.customRepeatFormData.repeat_frequency }}</p>

                <p style="font-size: 12px;"> <b>custom_option_selected_mode</b> {{
                                templateStore.customRepeatFormData.custom_option_selected_mode }}</p>

                <p style="font-size: 12px;"><b>repeat_amt</b> {{ templateStore.customRepeatFormData.repeat_amt }}</p>



                <div class="modal-content">


                    <div class="repeatWrapper">


                        <div class="repeatEveryBox">

                            <div style="text-align: left; margin-bottom: 10px;">
                                <b>Repeats every</b>
                            </div>

                            <div class="theRepeatInputArea">

                                <div style="width: 30%;">
                                    <input v-model="templateStore.customRepeatFormData.repeat_amt" class="form-control"
                                        type="number" name="" id="">
                                </div>

                                <div style="width: 70%;">

                                    <select v-model="templateStore.customRepeatFormData.repeat_frequency"
                                        class="form-select" @change="templateStore.onChangeSetMode">

                                        <option v-for="(option, index) in templateStore.customRepeatOptions"
                                            :key="index" :value="option.type">
                                            {{ option.name }}
                                        </option>

                                    </select>

                                </div>

                            </div>


                            <div v-show="templateStore.customRepeatFormData.repeat_frequency === 'week'"
                                class="theWeekDaysBox">


                                <!-- <p style="font-size: 11px;">{{ templateStore.customRepeatFormData.selected_days_of_week }}</p> -->

                                <br>

                                <!-- <p style="font-size: 11px;">{{ templateStore.customRepeatFormData.week_days }}</p> -->

                                <div v-for="(day, index) in templateStore.customRepeatFormData.week_days" :key="index"
                                    class="dayItem">

                                    <div>
                                        {{ day.short_name }}
                                    </div>

                                    <div>
                                        <div v-if="day.is_checked">

                                            <input @click="templateStore.addSelectedDay(day)" class="dayCheckBox"
                                                type="checkbox" checked>

                                        </div>

                                        <div v-else>

                                            <input @click="templateStore.addSelectedDay(day)" class="dayCheckBox"
                                                type="checkbox">

                                        </div>

                                    </div>

                                </div>



                            </div>


                            <div v-show="templateStore.customRepeatFormData.repeat_frequency === 'month'"
                                class="theMonthBox">



                                <div class="theDayInMonthOptionBox"
                                    :class="{ theDayInMonthOptionBoxSelected: templateStore.customRepeatFormData.selected_month_option === 'day_number_in_month_option' }">

                                    <div @click="templateStore.setMonthOption('day_number_in_month_option')"
                                        style="width: 20%; display: flex; align-items: center; justify-content: center;">


                                        <div
                                            v-if="templateStore.customRepeatFormData.selected_month_option === 'day_number_in_month_option'">

                                            <div class="form-check form-switch">
                                                <input class="form-check-input theInputSwitch" type="checkbox"
                                                    style="background-color: black;" id="flexSwitchCheckDefault"
                                                    checked>
                                            </div>

                                        </div>

                                        <div v-else>

                                            <div class="form-check form-switch">
                                                <input class="form-check-input theInputSwitch" type="checkbox"
                                                    id="flexSwitchCheckDefault">
                                            </div>

                                        </div>



                                    </div>

                                    <div style="width: 80%;">



                                        <select v-model="templateStore.customRepeatFormData.selected_day_number"
                                            class="form-select">

                                            <option v-for="(index) in 31" :key="index" :value="index">
                                                Day {{ index }}
                                            </option>

                                        </select>



                                    </div>




                                </div>



                                <div class="theDayInMonthOptionBox"
                                    :class="{ theDayInMonthOptionBoxSelected: templateStore.customRepeatFormData.selected_month_option === 'day_occurence_in_month_option' }"
                                    style="margin-top: 15px;">

                                    <div @click="templateStore.setMonthOption('day_occurence_in_month_option')"
                                        style="width: 20%; display: flex; align-items: center; justify-content: center;">


                                        <div
                                            v-if="templateStore.customRepeatFormData.selected_month_option === 'day_occurence_in_month_option'">

                                            <div class="form-check form-switch">
                                                <input class="form-check-input theInputSwitch" type="checkbox"
                                                    style="background-color: black;" id="flexSwitchCheckDefault"
                                                    checked>
                                            </div>

                                        </div>

                                        <div v-else>

                                            <div class="form-check form-switch">
                                                <input class="form-check-input theInputSwitch" type="checkbox"
                                                    id="flexSwitchCheckDefault">
                                            </div>

                                        </div>

                                    </div>

                                    <div style="width: 40%;">



                                        <select v-model="templateStore.customRepeatFormData.selected_occurence_in_month"
                                            class="form-select">

                                            <option
                                                v-for="(option, index) in templateStore.customRepeatFormData.monthly_occurrence_options"
                                                :key="index" :value="option.type">
                                                {{ option.name }}
                                            </option>

                                        </select>



                                    </div>

                                    <div style=" width: 40%;">


                                        <select v-model="templateStore.customRepeatFormData.selected_day_type_in_month"
                                            class="form-select">

                                            <option v-for="(day, index) in templateStore.customRepeatFormData.week_days"
                                                :key="index" :value="day.type">
                                                {{ day.full_name }}
                                            </option>

                                        </select>


                                    </div>


                                </div>


                            </div>



                        </div>



                        <div class="endsOnBox" v-if="templateStore.customRepeatFormData.repeat_frequency === 'day'">

                            <div style="text-align: left; margin-bottom: 10px;">
                                <b>Ends</b>
                            </div>

                            <div style="width: 100%; margin-bottom: 20px;">

                                <div class="theNeverItem"
                                    :class="{ selectedEndItem: templateStore.customRepeatFormData.custom_option_selected_mode === 'day_interval_with_never_end' }"
                                    @click="templateStore.setModeForDayIntervalWithNeverEnd">

                                    Never

                                </div>


                            </div>

                            <div style="width: 100%;">

                                <div class="theEndonDateItem"
                                    :class="{ selectedEndItem: templateStore.customRepeatFormData.custom_option_selected_mode === 'day_interval_with_end_date' }"
                                    @click="templateStore.setModeForDayIntervalWithEndDate">

                                    Select End Date


                                    <div v-show="templateStore.customRepeatFormData.custom_option_selected_mode === 'day_interval_with_end_date'"
                                        style="margin-top: 14px;">

                                        <input v-model="templateStore.customRepeatFormData.ends_date"
                                            class="form-control" type="date">

                                    </div>


                                </div>

                            </div>

                        </div>

                        <div v-if="templateStore.customRepeatFormData.repeat_frequency === 'week'">

                            <div style="text-align: left; margin-bottom: 10px;">
                                <b>Ends</b>
                            </div>

                            <div style="width: 100%; margin-bottom: 20px;">

                                <div class="theNeverItem"
                                    :class="{ selectedEndItem: templateStore.customRepeatFormData.custom_option_selected_mode === 'week_interval_with_never_end' }"
                                    @click="templateStore.setModeForWeekIntervalWithNeverEnd">

                                    Never

                                </div>


                            </div>

                            <div style="width: 100%;">

                                <div class="theEndonDateItem"
                                    :class="{ selectedEndItem: templateStore.customRepeatFormData.custom_option_selected_mode === 'week_interval_with_end_date' }"
                                    @click="templateStore.setModeForWeekIntervalWithEndDate">

                                    Select End Date


                                    <div v-show="templateStore.customRepeatFormData.custom_option_selected_mode === 'week_interval_with_end_date'"
                                        style="margin-top: 14px;">

                                        <input v-model="templateStore.customRepeatFormData.ends_date"
                                            class="form-control" type="date">

                                    </div>


                                </div>

                            </div>

                        </div>


                        <div v-if="templateStore.customRepeatFormData.repeat_frequency === 'month'">

                            {{ templateStore.customRepeatFormData.selected_month_option }}

                            <div style="text-align: left; margin-bottom: 10px;">
                                <b>Ends</b>
                            </div>

                            <div style="width: 100%; margin-bottom: 20px;">

                                <div class="theNeverItem"
                                    :class="{ selectedEndItem: templateStore.customRepeatFormData.custom_option_selected_mode === 'month_interval_with_never_end' }"
                                    @click="templateStore.setModeForMonthIntervalWithNeverEnd">

                                    Never

                                </div>


                            </div>

                            <div style="width: 100%;">

                                <div class="theEndonDateItem"
                                    :class="{ selectedEndItem: templateStore.customRepeatFormData.custom_option_selected_mode === 'month_interval_with_end_date' }"
                                    @click="templateStore.setModeForMonthIntervalWithEndDate">

                                    Select End Date


                                    <div v-show="templateStore.customRepeatFormData.custom_option_selected_mode === 'month_interval_with_end_date'"
                                        style="margin-top: 14px;">

                                        <input v-model="templateStore.customRepeatFormData.ends_date"
                                            class="form-control" type="date">

                                    </div>


                                </div>

                            </div>

                        </div>

                        <div v-if="templateStore.customRepeatFormData.repeat_frequency === 'year'">

                            <div style="text-align: left; margin-bottom: 10px;">
                                <b>Ends</b>
                            </div>

                            <div style="width: 100%; margin-bottom: 20px;">

                                <div class="theNeverItem"
                                    :class="{ selectedEndItem: templateStore.customRepeatFormData.custom_option_selected_mode === 'annual_interval_with_never_end' }"
                                    @click="templateStore.setModeForYearIntervalWithNeverEnd">

                                    Never

                                </div>


                            </div>

                            <div style="width: 100%;">

                                <div class="theEndonDateItem"
                                    :class="{ selectedEndItem: templateStore.customRepeatFormData.custom_option_selected_mode === 'annual_interval_with_end_date' }"
                                    @click="templateStore.setModeForYearIntervalWithEndDate">

                                    Select End Date


                                    <div v-show="templateStore.customRepeatFormData.custom_option_selected_mode === 'annual_interval_with_end_date'"
                                        style="margin-top: 14px;">

                                        <input v-model="templateStore.customRepeatFormData.ends_date"
                                            class="form-control" type="date">

                                    </div>


                                </div>

                            </div>

                        </div>




                        <div style="width: 100%; display: flex; justify-content: flex-end; margin-top: 30px;">

                            <button @click="templateStore.closeCustomRepeatModal" class="btn btn-light"
                                style="margin-right: 15px;">Cancel</button>

                            <button @click="templateStore.setSelectedCustomRepeatOption"
                                class="btn btn-dark">Done</button>

                        </div>


                    </div>


                </div>





            </div>

        </div>

        <div v-if="templateStore.showEditDetailsModal" class="modal-overlay">

            <div class="modal-box" style="width: 550px">

                <div class="model-box-header">
                    <div style="font-size: 17px">
                        <b>Edit Action</b>
                    </div>

                    <div>
                        <i @click="templateStore.closesEditDetailsModal" class="bi bi-x-lg"
                            style="font-size: 20px; cursor: pointer"></i>
                    </div>
                </div>

                <div class="theActionTabArea">



                    <div v-for="(tab, index) in templateStore.actionTabOptions" :key="index" class="labelItem"
                        @click="templateStore.setEditActionTab(tab)"
                        :class="{ selectedLabelItem: templateStore.selectedCurrentActionTabType === tab.type }">
                        {{ tab.name }}
                    </div>

                </div>



                <div v-show="templateStore.selectedCurrentActionTabType === 'details'" class="modal-content"
                    style="text-align: left; max-height: 600px; overflow-y: auto; padding: 20px;">


                    <div v-if="templateStore.currentAction.inspection_user && templateStore.currentAction.inspection_user.user"
                        class="topNoteBox">



                        Created by {{ templateStore.currentAction.inspection_user.user.name }} on {{
                                templateStore.formatDateAndTime(templateStore.currentAction.created_at) }}


                    </div>




                    <form>

                        <div class="mb-3">
                            <label class="form-label"><b>Title</b></label>
                            <input v-model="templateStore.currentAction.title" type="text"
                                class="form-control theInputStyle">
                        </div>

                        <div class="mb-3">

                            <div class="form-floating">
                                <textarea v-model="templateStore.currentAction.desc"
                                    class="form-control theTextareaStyle"></textarea>
                                <label>Description</label>
                            </div>

                        </div>


                        <div class="mb-3">

                            <label class="form-label"><b>Priority</b></label>

                            <select v-model="templateStore.currentAction.priority" class="form-select">


                                <option v-for="(priority, index) in templateStore.priorityLevels" :key="index"
                                    :value="priority.type">
                                    {{ priority.name }}
                                </option>

                            </select>

                        </div>

                        <div class="mb-3">

                            <label class="form-label"><b>Due Date:</b>
                                <div style="font-size: 12px;">readable_format here
                                </div>
                            </label>

                            <input v-model="templateStore.currentAction.due_date" type="date"
                                class="form-control theInputStyle" style="margin-bottom: 5px;">

                            <input v-model="templateStore.currentAction.due_time" type="time"
                                class="form-control theInputStyle">


                        </div>

                        <div class="mb-3">

                            <label class="form-label"><b>Assignees</b></label>

                            <div class="assignessBox">

                                <div class="theGroupsBox">

                                    <div class="theUserHead">

                                        <b>Groups</b>

                                    </div>

                                    <div class="theUsersListCont">



                                        <div v-if="templateStore.currentAction.groups.length > 0">

                                            <div v-for="(group, index) in templateStore.currentAction.groups"
                                                :key="index" class="theUserPill">

                                                <div v-if="group.inspection_group">
                                                    {{ group.inspection_group.name }}
                                                </div>


                                            </div>

                                        </div>

                                        <div v-else style="padding: 10px; font-size: 13px;">
                                            No groups selected
                                        </div>


                                    </div>


                                </div>

                                <div class="theUsersBox">

                                    <div class="theUserHead">

                                        <b>Users</b>

                                    </div>

                                    <div class="theUsersListCont">



                                        <div
                                            v-if="templateStore.currentAction.users && templateStore.currentAction.users.length > 0">

                                            <div v-for="(user, index) in templateStore.currentAction.users" :key="index"
                                                class="theUserPill">

                                                <div v-if="user.inspection_user && user.inspection_user.user">

                                                    {{ user.inspection_user.user.name }}

                                                </div>

                                            </div>

                                        </div>

                                        <div v-else style="padding: 10px; font-size: 13px;">
                                            No users selected
                                        </div>


                                    </div>


                                </div>




                            </div>




                        </div>


                        <div class="mb-3">

                            <label class="form-label">Repeats</label>


                            <div class="selectSupBox">



                                <div v-if="templateStore.selectedRepeatOption">

                                    <span v-if="templateStore.selectedRepeatOption.type === 'does_not_repeat'"
                                        class="badge rounded-pill theSupPill">
                                        <i class="bi bi-calendar-check theRepeatPill"></i>
                                        Does not repeat
                                    </span>

                                    <span v-if="templateStore.selectedRepeatOption.type === 'daily'"
                                        class="badge rounded-pill theSupPill">
                                        <i class="bi bi-calendar-check theRepeatPill"></i>
                                        Daily
                                    </span>

                                    <span v-if="templateStore.selectedRepeatOption.type === 'weekly_on_day_name'"
                                        class="badge rounded-pill theSupPill">
                                        <i class="bi bi-calendar-check theRepeatPill"></i>

                                        <!-- Weekly on {{ templateStore.selectedRepeatOption.day_name }} -->

                                    </span>

                                    <span v-if="templateStore.selectedRepeatOption.type === 'monthly_on_first_day_name'"
                                        class="badge rounded-pill theSupPill">
                                        <i class="bi bi-calendar-check theRepeatPill"></i>

                                        <!-- Monthly on the first {{ templateStore.selectedRepeatOption.day_name }} -->

                                    </span>

                                    <span v-if="templateStore.selectedRepeatOption.type === 'annually_this_date'"
                                        class="badge rounded-pill theSupPill">
                                        <i class="bi bi-calendar-check theRepeatPill"></i>

                                        <!-- Annually on {{ templateStore.selectedRepeatOption.the_date }} -->
                                    </span>

                                    <span v-if="templateStore.selectedRepeatOption.type === 'every_weekday_mon_to_fri'"
                                        class="badge rounded-pill theSupPill">
                                        <i class="bi bi-calendar-check theRepeatPill"></i>
                                        <!-- Every weekday (Monday - Friday) -->
                                    </span>


                                    <span v-if="templateStore.selectedRepeatOption.type === 'custom'"
                                        class="badge rounded-pill theSupPill">
                                        <i class="bi bi-calendar-check theRepeatPill"></i>


                                        <!-- {{ templateStore.customRepeatFormData.readable_format }} -->

                                    </span>

                                </div>



                            </div>




                        </div>



                        <div class="mb-3">

                            <label class="form-label">Site</label>

                            <select v-model="templateStore.currentAction.site_id" class="form-select">


                                <option v-for="(site, index) in templateStore.sites" :key="index" :value="site.id">
                                    {{ site.name }}
                                </option>

                            </select>

                        </div>


                        <div class="mb-3">

                            <label class="form-label">Asset</label>

                            <select v-model="templateStore.currentAction.asset_id" class="form-select">


                                <option v-for="(asset, index) in templateStore.assets" :key="index" :value="asset.id">
                                    {{ asset.name }}
                                </option>

                            </select>

                        </div>

                        <div class="mb-3">

                            <label class="form-label">Labels</label>


                            <div class="selectSupBox">


                                <div v-if="templateStore.currentAction.labels && templateStore.currentAction.labels.length > 0">



                                    <div class="badge rounded-pill theSupPill" v-for="(label, index) in templateStore.currentAction.labels"
                                        :key="index">


                                        <span v-if="label.label_detail && label.label_detail.name">

                                            <i class="bi bi-check-lg"></i>

                                            {{ label.label_detail.name }}

                                        </span>

                                    </div>

                                    
                                </div>

                                <div v-else style="padding: 10px; font-size: 13px;">

                                    No labels added

                                </div>



                            </div>



                            <!-- <select class="form-select">

<option selected value="">Add labels</option>

<option v-for="(label, index) in templateStore.labels" :key="index"
:value="label.id">
{{ label.name }}
</option>

</select>  -->

                        </div>



                        <button class="btn btn-dark" style="width: 100%; margin-top: 20px;">Save</button>



                    </form>




                </div>

                <div v-show="templateStore.selectedCurrentActionTabType === 'activity'" class="modal-content"
                    style="text-align: left;">

                    <div class="theChatAreaWrapper">


                        <div class="chatTimelineArea">


                            <div class="chatItemCont">


                                <div class="youBox">

                                    You

                                </div>

                                <div class="normalChatBubbleCont">


                                    <div class="innerChatBubble">

                                        Lorem, ipsum dolor sit amet consectetur adipisicing elit. Eligendi eum eos
                                        quaerat, quo odit delectus qui culpa! Pariatur repellat commodi modi consectetur
                                        labore officiis laboriosam esse sit est. Placeat, sequi.

                                    </div>


                                </div>

                                <div class="dateArea">
                                    May 21st, 2025 at 9:30am
                                </div>


                            </div>


                            <div class="chatItemCont">


                                <div class="textOnlyBox">
                                    <b>You</b> updated the priority to High.
                                </div>

                                <div class="dateArea">
                                    May 21st, 2025 at 9:30am
                                </div>




                            </div>


                            <div class="chatItemCont">


                                <div class="textOnlyBox">
                                    <b>You</b> updated the assignee to
                                </div>

                                <div class="assigneeListBox">

                                    <div class="theListCont">

                                        <li v-for="index in 10" :key="index">
                                            Electricians
                                        </li>

                                    </div>

                                </div>

                                <div class="dateArea">
                                    May 21st, 2025 at 9:30am
                                </div>




                            </div>


                            <div class="chatItemCont">


                                <div class="textOnlyBox">
                                    <b>You</b> uploaded image
                                </div>

                                <div class="actionImgBox">


                                    <div class="imageBox">


                                        <img src="https://cep-experts.ca/wp-content/uploads/2020/03/ladder-scaled.jpg">


                                    </div>

                                </div>

                                <div class="dateArea">
                                    May 21st, 2025 at 9:30am
                                </div>




                            </div>

                            <div class="chatItemCont">


                                <div class="textOnlyBox">
                                    <b>You</b> uploaded video
                                </div>

                                <div class="actionVideoBox">


                                    <div class="videoBox">


                                        <video controls>
                                            <source
                                                src="https://www.learningcontainer.com/wp-content/uploads/2020/05/sample-mp4-file.mp4"
                                                type="video/mp4">
                                            Your browser does not support HTML video.
                                        </video>


                                    </div>

                                </div>

                                <div class="dateArea">
                                    May 21st, 2025 at 9:30am
                                </div>




                            </div>

                            <div class="chatItemCont">


                                <div class="textOnlyBox">
                                    <b>You</b> uploaded pdf
                                </div>

                                <div class="actionPDFBox">


                                    <div class="pdfBox">

                                        repudiandae sit impedit illum laborum totam eos dignissimos exercitationem, quo
                                        autem quos recusandae ab magni.pdf

                                    </div>




                                </div>

                                <div class="dateArea">
                                    May 21st, 2025 at 9:30am
                                </div>




                            </div>



                        </div>


                    </div>


                    <div class="theButtonsBox">


                        <div class="uploadArea" @click="templateStore.openActionUploadMediaModal">
                            <i class="bi bi-cloud-upload" style="font-size: 20px;"></i>
                        </div>

                        <div class="textboxArea">

                            <textarea placeholder="Add update..." class="form-control theTextInputBox"></textarea>

                        </div>

                        <div class="sendBtnArea">
                            <i class="bi bi-arrow-right-circle-fill" style="font-size: 20px;"></i>
                        </div>

                    </div>



                </div>







            </div>
        </div>



        <div v-if="templateStore.showActionUploadMediaModal" class="modal-overlay">

            <div class="modal-box" style="width: 700px">

                <div class="model-box-header">
                    <div style="font-size: 17px">
                        <b>Add media</b>
                    </div>

                    <div>
                        <i @click="templateStore.closeActionUploadMediaModal" class="bi bi-x-lg"
                            style="font-size: 20px; cursor: pointer"></i>
                    </div>
                </div>


                <div class="modal-content">


                    <div class="actionMediaBox">


                        <div>
                            <i class="bi bi-cloud-upload" style="font-size: 40px;"></i>
                        </div>


                        <div>
                            <b>Click to upload a image, video or PDF file.</b>
                        </div>

                        <div style="font-size: 13px; margin-top: 15px">
                            Image file less than 10MB, PDF file less than 25MB, and video up to 4 minutes are supported.
                        </div>


                    </div>






                </div>




            </div>

        </div>




    </div>
</template>

<script setup>
import MainSideBar from "@/components/Inspections/MainSideBar";
import TopBarVue from "@/components/TopBar.vue";
import { onMounted, ref, computed } from 'vue';
import { useTemplateStore } from "@/stores/TemplateStore";


const templateStore = useTemplateStore();

const searchGroupName = ref('')
const searchUserName = ref('')
const searchLabels = ref('')



const filteredGroupNames = computed(() => {
    return templateStore.allGroups.filter(group =>
        group.name.toLowerCase().includes(searchGroupName.value.toLowerCase())
    ) || [];
});


const filteredUsers = computed(() => {
    return templateStore.allInspectionUsers.filter(inspectionUser =>
        inspectionUser?.user?.name
            ? inspectionUser.user.name.toLowerCase().includes(searchUserName.value.toLowerCase())
            : false
    );
});

const filteredLabels = computed(() => {
    return templateStore.labels.filter(label =>
        label.name.toLowerCase().includes(searchLabels.value.toLowerCase())
    ) || [];
});



onMounted(() => {


    templateStore.fetchAllActions()


})



</script>

<style scoped>
.mainWrapper {
    /* background-color: aliceblue; */
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.rightContentWrapper {
    /* overflow-y: auto; */
    width: 83%;
    display: flex;
    flex-direction: column;
}

.contentContainer {
    /* overflow-y: auto; */
    background-color: rgb(208, 28, 55);
    /* margin: 30px; */
    margin-top: 100px;
}

.rightColumns {
    /* display: flex;
  flex-direction: column; */
}

.topNav {
    background-color: blue;
}

.innerContainer {
    /* margin-top: 200px; */
    /* background-color: blueviolet; */
    overflow-y: auto;
    padding-top: 30px;
    padding-left: 25px;
    padding-right: 25px;
    padding-bottom: 100px;
}

.theHeaderWrapper {
    display: flex;
    justify-content: space-between;
    text-align: left;
    font-size: 20px;
    font-weight: bold;
    /* background-color: aqua; */
}

.searchBarWrapper {
    margin-top: 15px;
    width: 100%;
    padding-bottom: 20px;
    /* background-color: rgb(22, 166, 37); */
}

.theBar {
    width: 350px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 10px;
}

.button-6 {
    align-items: center;
    /* background-color: #FFFFFF; */
    background: linear-gradient(90deg, #e3ffe7 0%, #d9e7ff 100%);
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 0.25rem;
    box-shadow: rgba(0, 0, 0, 0.02) 0 1px 3px 0;
    box-sizing: border-box;
    color: rgba(0, 0, 0, 0.85);
    cursor: pointer;
    display: inline-flex;
    font-family: system-ui, -apple-system, system-ui, "Helvetica Neue", Helvetica,
        Arial, sans-serif;
    font-size: 16px;
    font-weight: 600;
    justify-content: center;
    line-height: 1.25;
    margin: 0;
    min-height: 3rem;
    padding: calc(0.875rem - 1px) calc(1.5rem - 1px);
    position: relative;
    text-decoration: none;
    transition: all 250ms;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: baseline;
    width: auto;
}

.button-6:hover,
.button-6:focus {
    border-color: rgba(0, 0, 0, 0.15);
    box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
    color: rgba(0, 0, 0, 0.65);
}

.button-6:hover {
    transform: translateY(-1px);
}

.button-6:active {
    background-color: #f0f0f1;
    border-color: rgba(0, 0, 0, 0.15);
    box-shadow: rgba(0, 0, 0, 0.06) 0 2px 4px;
    color: rgba(0, 0, 0, 0.65);
    transform: translateY(0);
}

.tableWrapper {
    margin-top: 20px;
    /* background-color: aqua; */
    width: 100%;
}

.my-custom-table tbody tr td {
    padding-top: 20px;
    /* Adjust the value as needed */
    padding-bottom: 20px;
    /* Adjust the value as needed */
}

.my-custom-table th {
    padding-bottom: 20px;
}

.my-custom-table th,
.my-custom-table td {
    text-align: left;
    /* Aligns text to the left */
    padding-left: 20px;
    padding-right: 20px;
}

.campName {
    text-decoration: none;
    color: inherit;
}

.campName:hover {
    color: rgb(10, 159, 92);
    cursor: pointer;
    text-decoration: none;
}

.iconsRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.theActionIcon {
    font-weight: bolder;
    font-size: 18px;
    color: rgb(5, 2, 2);
    cursor: pointer;
}

.theActionIcon:hover {
    cursor: pointer;
    color: rgb(30, 158, 30);
    scale: 1.3;
    transition: 1s;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    /* Semi-transparent black background */
    display: flex;
    justify-content: center;
    /* Center horizontally */
    align-items: center;
    /* Center vertically */
    z-index: 999;
    /* Ensure the modal is on top */
}

/* Styles for the modal box */
.modal-box {
    background-color: white;
    width: 1100px;
    padding: 20px;
    border-radius: 2px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.modal-content {
    text-align: center;
}

.model-box-header {
    display: flex;
    justify-content: space-between;
    /* background-color: antiquewhite; */
    height: 40px;
    margin-bottom: 15px;
}

.form-control {
    background-color: rgb(243, 246, 246);
}

.createBTN {
    width: 100%;
    margin-top: 20px;
}

.noTeamBox {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 500px;
    width: 100%;
}

.noMatchBox {
    background-color: red;
    width: 100%;
}

.loadMoreBtn {
    background-color: black;
    border-radius: 10px;
}

.inviteInput {
    width: 500px;
    font-size: 13px;
    cursor: pointer;
}

.tabsWrapper {
    display: flex;
    width: 100%;
    /* background-color: #a9baba; */
}


.tabItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    background-color: #fdfdfd;
    padding: 30px;
    border: 1.5px solid #c3d4cb;
    cursor: pointer;
    margin: 10px;
    border-radius: 10px;
}

.tabItem:hover {

    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    background-color: #fdfdfd;
    padding: 30px;
    border: 1.5px solid #c3d4cb;
    cursor: pointer;
    margin: 10px;
    border-radius: 10px;
    background: linear-gradient(90deg, #e3ffe7 0%, #d9e7ff 100%);

}

.tabItemSelected {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    background: linear-gradient(90deg, #e3ffe7 0%, #d9e7ff 100%);
    padding: 30px;
    border: 1.5px solid #c3d4cb;
    cursor: pointer;
    margin: 10px;
    border-radius: 10px;
}



.iconDrop {
    cursor: pointer;
}

.button-13 {
    background-color: #fff;
    border: 1px solid #d5d9d9;
    border-radius: 8px;
    box-shadow: rgba(213, 217, 217, 0.5) 0 2px 5px 0;
    box-sizing: border-box;
    color: #0f1111;
    cursor: pointer;
    display: inline-block;
    font-family: "Amazon Ember", sans-serif;
    font-size: 13px;
    line-height: 29px;
    padding: 0 10px 0 11px;
    position: relative;
    text-align: center;
    text-decoration: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: middle;
    min-width: 100px;
}

.button-13:hover {
    background: linear-gradient(90deg, #e3ffe7 0%, #d9e7ff 100%);
}

.emptyTableBox {
    margin-top: 100px;
}

.actionsList {
    width: 100%;
    /* background-color: gray; */
}


.actionItem {
    display: flex;
    flex-direction: column;
    width: 100%;
    cursor: pointer;
    border-radius: 10px;
    margin-bottom: 20px;
    box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;
    box-sizing: border-box;
    border: 0.8px solid transparent;
    /* Pre-allocate space for border */
}

.actionItem:hover {
    border: 0.8px solid rgb(39, 42, 39);
}

.topSection {
    display: flex;
    width: 100%;
    background-color: rgb(247, 244, 244);
    border-radius: 10px;

}

.checkArea {
    display: flex;
    justify-content: flex-start;
    padding: 10px;
    width: 3%;
    /* background-color: #d5d9d9; */
}




.actionNameArea {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 97%;
    text-align: left;
    padding: 10px;
    /* background-color: #95e8e8; */
}

.middleSection {
    display: flex;
    width: 100%;
    background-color: rgb(255, 255, 255);
    padding: 10px;
}

.bottomSection {
    display: flex;
    width: 100%;
    /* background-color: rgb(219, 237, 204); */
}

.bottomLeft {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    width: 50%;
    font-size: 14px;


}

.bottomRight {
    display: flex;
    justify-content: flex-end;
    padding: 10px;
    width: 50%;
    font-size: 13px;
    /* background: rgb(245, 151, 151); */

}

.settingsBtn {
    background-color: white;
    padding: 10px;
    font-size: 13px;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    margin-right: 15px;
    border: none;
    height: 100%;
    cursor: pointer;
}

.settingsBtn:hover {
    background-color: rgb(238, 244, 234);
}

.theInputStyle {
    border: 1px solid rgb(186, 186, 186);
}

.theTextareaStyle {
    border: 1px solid rgb(186, 186, 186);
    resize: none;
    height: 90px !important;

}


.assignessBox {
    display: flex;
    width: 100%;
    /* background-color: gray; */
    gap: 10px;
}

.theUsersBox {
    display: flex;
    flex-direction: column;
    width: 50%;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
    border-radius: 10px;
    padding: 10px;
}


.theUserHead {
    margin-bottom: 10px;
    /* background-color: yellow; */
}

.theUsersListCont {
    background-color: #f7f7f7;
    max-height: 70px;
    overflow-y: auto;
    border-radius: 10px;

}

.theGroupsBox {
    display: flex;
    flex-direction: column;
    width: 50%;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
    border-radius: 10px;
    padding: 10px;

}


.theUserPill {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-size: 13px;
    font-weight: bold;
    color: white;
    background-color: #47785b;
    border-radius: 10px;
    padding: 5px;
    margin-bottom: 5px;
}

.selectSupBox {
    display: flex;
    /* width: 180px; */
    width: 100%;
    border-radius: 14px;
    padding: 2px;
    /* max-height: 60px; */
    overflow-y: auto;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    cursor: pointer;
    background-color: #f2f2f2;
    overflow-x: auto;
}


.theSupPill {
    margin: 7px;
    background-color: white;
    color: #0f1111;
}

.innerBox {
    /* background-color: red; */
    overflow-y: auto;
    width: 100%;
    height: 500px;
    padding: 12px;
}


.talkItem {
    display: flex;
    flex-direction: row;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 10px;
    margin-bottom: 25px;
    padding-top: 10px;
    padding-bottom: 10px;
    cursor: pointer;
}

.talkItem:hover {
    background-color: rgb(249, 253, 252);
}

.talkLeft {
    display: flex;
    flex-direction: column;
    padding: 5px;
    width: 80%;
    /* background-color: #aadada; */
}

.talkRight {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20%;
    /* padding: 10px; */
    /* background-color: #3ddbdb; */
}

.talkTitle {
    /* background-color: #d9e7ff; */
    text-align: left;
    font-weight: bold;
    padding: 6px;
}


.groupActionBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-top: 20px;
    /* background-color: gray; */
}

.theRepeatPill {
    margin-right: 4px;
}

.repeatWrapper {
    width: 100%;
    /* background-color: rgb(204, 204, 204); */
}

.repeatEveryBox {
    display: flex;
    flex-direction: column;
    width: 100%;
    /* background-color: yellow; */
    margin-bottom: 20px
}

.endsOnBox {
    width: 100%;
    margin-top: 35px;
    /* background-color: rgb(114, 210, 120) */
}

.theRepeatInputArea {
    display: flex;
    width: 100%;
    /* background-color: purple; */
    gap: 10px;

}


.theNeverItem {
    width: 100%;
    border-radius: 10px;
    padding: 10px;
    font-weight: bold;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    cursor: pointer;

}


.theEndonDateItem {
    width: 100%;
    border-radius: 10px;
    padding: 10px;
    font-weight: bold;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    cursor: pointer;
}

.selectedEndItem {
    width: 100%;
    border-radius: 10px;
    padding: 10px;
    background: linear-gradient(90deg, #e3ffe7 0%, #d9e7ff 100%);
    font-weight: bold;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    cursor: pointer;
}

.theWeekDaysBox {
    display: flex;
    justify-content: space-between;
    width: 100%;
    /* background-color: green; */
    margin-top: 20px;
}

.dayItem {
    display: flex;
    flex-direction: column;
    background-color: white;
    padding: 10px;

}

.dayCheckBox {
    cursor: pointer;
}



.theMonthBox {
    width: 100%;
    /* background-color: green; */
    margin-top: 20px;
}


.theMonthDayItem {
    display: flex;
    width: 100%;
    border-radius: 10px;
    padding: 10px;
    font-weight: bold;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    cursor: pointer;
}

.selectedtheMonthDayItem {
    display: flex;
    width: 100%;
    border-radius: 10px;
    padding: 10px;
    background: linear-gradient(90deg, #e3ffe7 0%, #d9e7ff 100%);
    font-weight: bold;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    cursor: pointer;

}

.theSelectBtn {
    width: 40%;
    display: flex;
    align-items: center;
    background-color: white;
    justify-content: center;
    border-radius: 10px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}


.theDayInMonthOptionBox {
    display: flex;
    align-self: center;
    gap: 10px;
    width: 100%;
    border-radius: 10px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    padding: 10px;

}

.theDayInMonthOptionBoxSelected {
    display: flex;
    align-self: center;
    gap: 10px;
    width: 100%;
    border-radius: 10px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    padding: 10px;
    background: linear-gradient(90deg, #e3ffe7 0%, #d9e7ff 100%);

}


.theAssignedBox {
    display: flex;
    /* background-color: rgb(206, 202, 202); */

}


.theActionTabArea {
    display: flex;
    width: 100%;
    /* background-color: yellow; */
    margin-bottom: 20px;
    cursor: pointer;

}


.theButtonsBox {
    display: flex;
    width: 100%;
    /* background-color: rgb(196, 195, 195); */
    padding-top: 20px;
    padding-bottom: 20px;
    gap: 10px;
}


.saveBtn {
    background: linear-gradient(90deg, #e3ffe7 0%, #d9e7ff 100%);
    width: 100%;
    font-weight: bold;
}

.labelItem {
    width: 50%;
    color: black;
    padding: 10px;
    box-sizing: border-box;
    border: 0.5px solid #bdc8bd;
}

.selectedLabelItem {
    width: 50%;
    background: linear-gradient(90deg, #e3ffe7 0%, #d9e7ff 100%);
    color: black;
    padding: 10px;
    font-weight: bold;
    box-sizing: border-box;
    border: 0.5px solid #bdc8bd;
}

.theChatAreaWrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    /* background-color: rgb(208, 232, 208); */
    border: 1px solid #bdc8bd;
    border-radius: 10px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}


.toggleArea {
    width: 100%;
    /* background-color: purple; */
    /* padding: 10px; */
}

.chatTimelineArea {
    width: 100%;
    height: 400px;
    overflow-y: auto;
    padding: 10px;
}

.chatItemCont {
    display: flex;
    flex-direction: column;
    width: 100%;
    /* background-color: yellow; */
    margin-bottom: 30px;

}


.normalChatBubbleCont {
    display: flex;
    justify-content: flex-end;
    width: 100%;
}

.youBox {
    text-align: right;
    font-size: 13px;
}

.innerChatBubble {
    border-radius: 10px;
    background-color: #196a19;
    color: white;
    padding: 10px;
    max-width: 80%;
    margin-top: 3px;
    margin-bottom: 3px;
}


.dateArea {
    text-align: right;
    font-size: 13px;
}

.textOnlyBox {
    text-align: right;
    margin-bottom: 3px;
}


.assigneeListBox {
    display: flex;
    justify-content: flex-end;
    /* background-color: gray; */
    width: 100%;

}

.theListCont {
    display: flex;
    flex-direction: column;
    padding: 10px;
    width: 50%;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    border-radius: 5px;
    margin-bottom: 3px;

}


.actionImgBox {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-bottom: 3px;

}

.imageBox {
    width: 100px;
    height: 100px;
    border-radius: 10px;
    overflow: hidden;
}

.imageBox img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.actionVideoBox {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-bottom: 3px;
}

.videoBox {
    display: flex;
    justify-content: flex-end;
    width: 80%;
    /* background-color: red; */
}


.actionPDFBox {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-bottom: 3px;
    /* background-color: #bdc8bd */
}

.pdfBox {
    display: flex;
    justify-content: flex-end;
    max-width: 80%;
    padding: 10px;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    cursor: pointer;
    color: #0a6a28;
}

.pdfBox:hover {
    text-decoration: underline;
    color: rgb(12, 137, 51);
}


.uploadArea {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 10%;
    border-radius: 10px;
    box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;
    /* padding: 10px; */
    cursor: pointer;
}

.uploadArea:hover {
    background: linear-gradient(90deg, #e3ffe7 0%, #d9e7ff 100%);
}

.textboxArea {
    width: 80%;
    /* background-color: rgb(116, 161, 239); */
}

.sendBtnArea {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 10%;
    border-radius: 10px;
    box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;
    /* padding: 10px; */
    background: linear-gradient(90deg, #e3ffe7 0%, #d9e7ff 100%);
    cursor: pointer;
}

.sendBtnArea:hover {
    background: rgb(216, 255, 242);
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}


.theTextInputBox {
    height: 50px;
    resize: none;
    font-size: 14px;
}


.actionMediaBox {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: rgb(235, 232, 232);
    padding: 10px;
    border-radius: 10px;
}


.topNoteBox {
    width: 100%;
    /* background-color: gray; */
    margin-bottom: 25px;
    font-size: 12px;
}
</style>