import apiClient from "./MyClient";

export default {

  
  getAllTemplates() { 
    const authStore = localStorage.getItem('authStore')
    apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + JSON.parse(authStore).token
    
    return apiClient.get("/api/all/templates"); 
  },

  createNewTemplateFromScratch() { 
    const authStore = localStorage.getItem('authStore')
    apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + JSON.parse(authStore).token
    
    return apiClient.post("/api/new/template"); 
  },


  getTemplateForEditor(randomString) { 

    const authStore = localStorage.getItem('authStore')
    apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + JSON.parse(authStore).token
    
    return apiClient.get("/api/template/details/editor/" + randomString); 
  },

  addNewSection(formData) { 

    const authStore = localStorage.getItem('authStore')
    apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + JSON.parse(authStore).token
    
    return apiClient.post("/api/template/new/section/", formData); 
  },

  addNewQuestion(formData) { 

    const authStore = localStorage.getItem('authStore')
    apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + JSON.parse(authStore).token
    
    return apiClient.post("/api/template/new/question", formData); 
  },

  addNewQuestionToASection(formData){

    const authStore = localStorage.getItem('authStore')
    apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + JSON.parse(authStore).token
    
    return apiClient.post("/api/template/new/question/to/section", formData); 

  },

  deleteAQuestion(formData){

    const authStore = localStorage.getItem('authStore')
    apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + JSON.parse(authStore).token
    
    return apiClient.post("/api/template/delete/question", formData);  

  },

  deleteAQuestionFromASection(formData){

    const authStore = localStorage.getItem('authStore')
    apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + JSON.parse(authStore).token
    
    return apiClient.post("/api/template/delete/section/question", formData);  

  },

  deleteASection(formData){

    const authStore = localStorage.getItem('authStore')
    apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + JSON.parse(authStore).token
    
    return apiClient.post("/api/template/delete/section", formData);  

  },

  addANewPage(formData){

    const authStore = localStorage.getItem('authStore')
    apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + JSON.parse(authStore).token
    
    return apiClient.post("/api/template/new/page/", formData);  

  },


  deleteThisPage(formData){

    const authStore = localStorage.getItem('authStore')
    apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + JSON.parse(authStore).token
    
    return apiClient.post("/api/template/delete/page/", formData);  

  },


  uploadPreviewFiles(formData){

    const authStore = localStorage.getItem('authStore')
    apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + JSON.parse(authStore).token

    //for file uploads IMPORTANT
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    
    return apiClient.post("/api/template/preview/files/upload/", formData, config);   

  },

  uploadInstructionAttachmentFile(formData){

    const authStore = localStorage.getItem('authStore')
    apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + JSON.parse(authStore).token

    //for file uploads IMPORTANT
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    
    return apiClient.post("/api/template/instruction/file/upload/", formData, config);   

  },

  uploadReplacementInstructionAttachmentFile(formData){

    const authStore = localStorage.getItem('authStore')
    apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + JSON.parse(authStore).token

    //for file uploads IMPORTANT
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    
    return apiClient.post("/api/template/instruction/file/replace/upload/", formData, config);   

  },



  deleteAttachmentFile(formData){

    const authStore = localStorage.getItem('authStore')
    apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + JSON.parse(authStore).token
    
    return apiClient.post("/api/template/instruction/file/delete/", formData);  

  },


  addANewOptionToDropdownToElement(formData){

    const authStore = localStorage.getItem('authStore')
    apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + JSON.parse(authStore).token
    
    return apiClient.post("/api/template/new/dropdown/option/", formData);  

  },

  

  deleteADropdownOption(formData){

    const authStore = localStorage.getItem('authStore')
    apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + JSON.parse(authStore).token
    
    return apiClient.post("/api/template/new/dropdown/delete/option", formData);  

  },


  addANewChoice(formData){

    const authStore = localStorage.getItem('authStore')
    apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + JSON.parse(authStore).token
    
    return apiClient.post("/api/template/new/choice/", formData);  

  },

  sectionAddNewChoice(formData){

    const authStore = localStorage.getItem('authStore')
    apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + JSON.parse(authStore).token
    
    return apiClient.post("/api/template/section/new/choice/", formData);  

  },

  deleteAChoice(formData){

    const authStore = localStorage.getItem('authStore')
    apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + JSON.parse(authStore).token
    
    return apiClient.post("/api/template/delete/choice/", formData);  

  },

  saveChoiceOrDropdownOption(formData){

    const authStore = localStorage.getItem('authStore')
    apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + JSON.parse(authStore).token
    
    return apiClient.post("/api/template/new/saved-option/", formData);  

  },

  updateSavedOptions(formData){

    const authStore = localStorage.getItem('authStore')
    apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + JSON.parse(authStore).token
    
    return apiClient.post("/api/template/update/saved-option/", formData);  

  },


  getAllSavedOptions(){

    const authStore = localStorage.getItem('authStore')
    apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + JSON.parse(authStore).token
    
    return apiClient.get("/api/template/all/saved-option/");  

  },


  addNewSavedOptionElement(formData){

    const authStore = localStorage.getItem('authStore')
    apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + JSON.parse(authStore).token
    
    return apiClient.post("/api/template/add/new/saved-option-element/", formData);  


  },


  deleteThisSavedOptionElement(formData){

    const authStore = localStorage.getItem('authStore')
    apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + JSON.parse(authStore).token
    
    return apiClient.post("/api/template/delete/saved-option-element/", formData);  


  },

  replaceWithSavedOptionElement(formData){

    const authStore = localStorage.getItem('authStore')
    apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + JSON.parse(authStore).token
    
    return apiClient.post("/api/template/copy/saved-option/", formData);  


  },

  sectionReplaceWithSavedOptionElement(formData){ 

    const authStore = localStorage.getItem('authStore')
    apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + JSON.parse(authStore).token
    
    return apiClient.post("/api/template/section/copy/saved-option/", formData);   


  },

  deleteSavedOption(formData){

    const authStore = localStorage.getItem('authStore')
    apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + JSON.parse(authStore).token
    
    return apiClient.post("/api/template/delete/saved-option/", formData);  


  },


  replaceWithSavedChoices(formData){

    const authStore = localStorage.getItem('authStore')
    apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + JSON.parse(authStore).token
    
    return apiClient.post("/api/template/copy/saved-choices/", formData);  

  },

  sectionReplaceWithSavedChoices(formData){

    const authStore = localStorage.getItem('authStore')
    apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + JSON.parse(authStore).token
    
    return apiClient.post("/api/template/section/copy/saved-choices/", formData);  

  },


  sectionElementAddNewOptionToDropdown(formData){ 

    const authStore = localStorage.getItem('authStore')
    apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + JSON.parse(authStore).token
    
    return apiClient.post("/api/template/section/new/dropdown/option/", formData);  

  },


  getLiveTemplate(randomString){ 

    const authStore = localStorage.getItem('authStore')
    apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + JSON.parse(authStore).token
    
    return apiClient.get("/api/live/template/" + randomString);  

  },


  saveFormInputValue(formData){

    const authStore = localStorage.getItem('authStore')
    apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + JSON.parse(authStore).token
    
    return apiClient.post("/api/live/save/form/input/", formData);  

  },

  uploadFilesToDOForAElement(formData){

    const authStore = localStorage.getItem('authStore')
    apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + JSON.parse(authStore).token

    //for file uploads IMPORTANT
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    
    return apiClient.post("/api/live/upload/files/", formData, config);  

  },

  deleteAMediaFileFromGallery(formData){

    const authStore = localStorage.getItem('authStore')
    apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + JSON.parse(authStore).token

    
    return apiClient.post("/api/live/delete/media-file/", formData);  

  },

  startANewInspection(formData){

    const authStore = localStorage.getItem('authStore')
    apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + JSON.parse(authStore).token

    
    return apiClient.post("/api/start/new/unique-template/", formData);  

  },

  saveFormInputDropdownOption(formData){

    const authStore = localStorage.getItem('authStore')
    apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + JSON.parse(authStore).token

    
    return apiClient.post("/api/live/save/dropdown-option/", formData);  

  },

  saveFormInputMultipleChoice(formData){

    const authStore = localStorage.getItem('authStore')
    apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + JSON.parse(authStore).token

    
    return apiClient.post("/api/live/save/multi-choice", formData);  

  },

  saveFormInputSingleChoice(formData){

    const authStore = localStorage.getItem('authStore')
    apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + JSON.parse(authStore).token

    
    return apiClient.post("/api/live/save/single-choice", formData);  

  },

  uploadSignatureImageToDO(formData){

    const authStore = localStorage.getItem('authStore')
    apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + JSON.parse(authStore).token

    
    return apiClient.post("/api/live/upload/signature", formData);  

  },

  deleteASignatureFile(formData){

    const authStore = localStorage.getItem('authStore')
    apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + JSON.parse(authStore).token

    
    return apiClient.post("/api/live/delete/signature", formData);  

  },


  saveNote(formData){

    const authStore = localStorage.getItem('authStore')
    apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + JSON.parse(authStore).token

    
    return apiClient.post("/api/live/note/save", formData);  

  },


  deleteNote(formData){

    const authStore = localStorage.getItem('authStore')
    apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + JSON.parse(authStore).token

    
    return apiClient.post("/api/live/note/delete", formData);  

  },


  saveAutomation(formData){

    const authStore = localStorage.getItem('authStore')
    apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + JSON.parse(authStore).token

    
    return apiClient.post("/api/template/automation/save", formData);  

  },

  saveAutomationForPageSection(formData){

    const authStore = localStorage.getItem('authStore')
    apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + JSON.parse(authStore).token

    
    return apiClient.post("/api/template/automation/page-section/save", formData);  

  },


  updateAutomation(formData){

    const authStore = localStorage.getItem('authStore')
    apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + JSON.parse(authStore).token

    
    return apiClient.post("/api/template/automation/update", formData);  

  },

  deleteAutomation(formData){

    const authStore = localStorage.getItem('authStore')
    apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + JSON.parse(authStore).token

    
    return apiClient.post("/api/template/automation/delete", formData);  

  },

  deleteWhenElementChangesDeleteAutomations(formData){

    const authStore = localStorage.getItem('authStore')
    apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + JSON.parse(authStore).token

    
    return apiClient.post("/api/template/element/automation/remove-when-changed", formData);  

  },

  newActionFromLiveForm(formData){

    const authStore = localStorage.getItem('authStore')
    apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + JSON.parse(authStore).token

    
    return apiClient.post("/api/live/create/action", formData);   

  },

  saveNewSite(formData){

    const authStore = localStorage.getItem('authStore')
    apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + JSON.parse(authStore).token

    
    return apiClient.post("/api/new/site", formData);   

  },

  getAllSites(){

    const authStore = localStorage.getItem('authStore')
    apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + JSON.parse(authStore).token

    
    return apiClient.get("/api/sites");   

  },

  updateSite(formData){

    const authStore = localStorage.getItem('authStore')
    apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + JSON.parse(authStore).token

    
    return apiClient.post("/api/update/site", formData);   

  },

  deleteSite(formData){

    const authStore = localStorage.getItem('authStore')
    apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + JSON.parse(authStore).token

    
    return apiClient.post("/api/delete/site", formData);   

  },

  getAllAssets(){

    const authStore = localStorage.getItem('authStore')
    apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + JSON.parse(authStore).token

    return apiClient.get("/api/assets");   

  },

  addNewAsset(formData){

    const authStore = localStorage.getItem('authStore')
    apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + JSON.parse(authStore).token

    //for file uploads IMPORTANT
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };

    
    return apiClient.post("/api/new/asset", formData, config);   

  },

  deleteAsset(formData){

    const authStore = localStorage.getItem('authStore')
    apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + JSON.parse(authStore).token

    
    return apiClient.post("/api/delete/asset", formData);   

  },

  getAsset(uuid){

    const authStore = localStorage.getItem('authStore')
    apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + JSON.parse(authStore).token

    
    return apiClient.get("/api/asset/" + uuid);   

  },

  uploadAssetFilesToDO(formData){

    const authStore = localStorage.getItem('authStore')
    apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + JSON.parse(authStore).token

    //for file uploads IMPORTANT
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    
    return apiClient.post("/api/upload/asset/files/", formData, config);   

  },

  updateAsset(formData){

    const authStore = localStorage.getItem('authStore')
    apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + JSON.parse(authStore).token
    
    return apiClient.post("/api/update/asset/", formData);  

  },

  deleteAssetMediaFile(formData){

    const authStore = localStorage.getItem('authStore')
    apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + JSON.parse(authStore).token
    
    return apiClient.post("/api/delete/asset/mediafile/", formData);  

  },

  getAllLabels(){

    const authStore = localStorage.getItem('authStore')
    apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + JSON.parse(authStore).token

    
    return apiClient.get("/api/labels/");   

  },

  updateLabel(formData){

    const authStore = localStorage.getItem('authStore')
    apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + JSON.parse(authStore).token
    
    return apiClient.post("/api/update/label", formData);  

  },

  deleteLabel(formData){

    const authStore = localStorage.getItem('authStore')
    apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + JSON.parse(authStore).token
    
    return apiClient.post("/api/delete/label", formData);  

  },


  addNewLabel(formData){

    const authStore = localStorage.getItem('authStore')
    apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + JSON.parse(authStore).token
    
    return apiClient.post("/api/new/label/", formData);  

  },

  getAllActions(){

    const authStore = localStorage.getItem('authStore')
    apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + JSON.parse(authStore).token
    
    return apiClient.get("/api/actions/");  

  },

  storeNewAction(formData){

    const authStore = localStorage.getItem('authStore')
    apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + JSON.parse(authStore).token
    
    return apiClient.post("/api/template/store/new-action", formData);  

  },

  getAllEmployeesForImport(){ 

    const authStore = localStorage.getItem('authStore')
    apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + JSON.parse(authStore).token
    
    return apiClient.get("/api/inspections/import/all-employees/");  

  },


  importUsersToInspectionNormalUsersTable(formData){ 

    const authStore = localStorage.getItem('authStore')
    apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + JSON.parse(authStore).token
    
    return apiClient.post("/api/inspections/import/selected-users", formData);  

  },


  getAllEmployeesForTheInspectionApp(){ 

    const authStore = localStorage.getItem('authStore')
    apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + JSON.parse(authStore).token
    
    return apiClient.get("/api/inspections/employees");  

  },

  saveWholeTemplateProject(formData){

    const authStore = localStorage.getItem('authStore')
    apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + JSON.parse(authStore).token
    
    return apiClient.post("/api/template/save/project/", formData);  

  },


  storeNewGroup(formData){

    const authStore = localStorage.getItem('authStore')
    apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + JSON.parse(authStore).token
    
    return apiClient.post("/api/template/inspection/new-team", formData);  


  },

  updateInspectionGroup(formData){

    const authStore = localStorage.getItem('authStore')
    apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + JSON.parse(authStore).token
    
    return apiClient.post("/api/template/update/team", formData);  


  },

  getAllGroups(){

    const authStore = localStorage.getItem('authStore')
    apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + JSON.parse(authStore).token
    
    return apiClient.get("/api/template/inspection/all-teams");  


  },


  finallyAddNewUsersToDB(formData){

    const authStore = localStorage.getItem('authStore')
    apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + JSON.parse(authStore).token
    
    return apiClient.post("/api/template/add-new-users", formData);  


  },


  checkIfEmailIsVaildAndDoNotExistsInDB(formData){

    const authStore = localStorage.getItem('authStore')
    apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + JSON.parse(authStore).token
    
    return apiClient.post("/api/template/validate-email", formData);  

  },


  addUserToNewGroup(formData){

    const authStore = localStorage.getItem('authStore')
    apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + JSON.parse(authStore).token
    
    return apiClient.post("/api/template/add-user-to-group", formData);  

  },

  addUserToSite(formData){

    const authStore = localStorage.getItem('authStore')
    apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + JSON.parse(authStore).token
    
    return apiClient.post("/api/template/add-user-to-site", formData);  

  },

  deactivateUser(formData){

    const authStore = localStorage.getItem('authStore')
    apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + JSON.parse(authStore).token
    
    return apiClient.post("/api/template/deactivate-user", formData);  

  },

  reActivateUser(formData){

    const authStore = localStorage.getItem('authStore')
    apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + JSON.parse(authStore).token
    
    return apiClient.post("/api/template/reactivate-user", formData);  

  },


  getAGroup(uuid){

    const authStore = localStorage.getItem('authStore')
    apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + JSON.parse(authStore).token
    
    return apiClient.get("/api/template/get-group/" + uuid);  


  },



  removeDeleteUserFromThisGroup(formData){

    const authStore = localStorage.getItem('authStore')
    apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + JSON.parse(authStore).token
    
    return apiClient.post("/api/template/group/remove-user", formData);  

  },

  addUsersToAGroup(formData){

    const authStore = localStorage.getItem('authStore')
    apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + JSON.parse(authStore).token
    
    return apiClient.post("/api/template/group/add-users", formData);  

  },


  fetchASiteDetailService(formData){

    const authStore = localStorage.getItem('authStore')
    apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + JSON.parse(authStore).token
    
    return apiClient.post("/api/template/site/details", formData);  

  },


  addUsersToASite(formData){

    const authStore = localStorage.getItem('authStore')
    apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + JSON.parse(authStore).token
    
    return apiClient.post("/api/template/site/add-users", formData);  

  },


  removeUserFromASiteService(formData){

    const authStore = localStorage.getItem('authStore')
    apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + JSON.parse(authStore).token
    
    return apiClient.post("/api/template/site/remove-user", formData);  

  },
  

  fetchAUserDetailService(formData){

    const authStore = localStorage.getItem('authStore')
    apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + JSON.parse(authStore).token
    
    return apiClient.post("/api/template/inspection/user-detail", formData);  

  },


  
  






};
